import { categoryProductService } from "./CategoryProductService";
import { makeAutoObservable } from "mobx";
import React from "react";
import axios, { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import { utils } from "../../../common/utils/Utils";

import { createSearchParams, NavigateFunction } from "react-router-dom";
import { number } from "yup";
import StorageService from "../../../common/service/StorageService";
interface StaticParamCategoryProduct {
    ids: [] | null;
    offset: string | null;
    page: number | 0;
    parent_id: string | null;
    per_page: number;
    search: string | undefined;
    sort: string | null;
    status: string | null;
}

class CategoryProductStore {
    isModalOpen = false;
    isLoading = false;
    isLoadingSave = false;
    isCreate = false;

    initialValues: { name: string; description: string; parent_id: string } = {
        name: "",
        description: "",
        parent_id: "",
    };
    metadata: any = {
        total: "",
        page: 0,
        total_page: "",
        size: "",
    };
    dataListCategoryProduct: any[] = [];
    dataListCategoryProductAll: any[] = [];

    staticDataCategoryProduct: {
        id: number | null;
        name: string;
        description: string;
        parent_id: string;
        preview: string;
        icon: string;
        featured: boolean;
    } = {
        id: null,
        name: "",
        description: "",
        parent_id: "",
        preview: "",
        icon: "",
        featured: true,
    };

    staticParamCategoryProduct: StaticParamCategoryProduct = {
        ids: [],
        offset: "",
        page: 0,
        parent_id: "",
        per_page: 20,
        search: "",
        sort: "id",
        status: "",
    };

    dataCategoryPro: any[] = []

    initializeParams() {
        const query = new URLSearchParams(window.location.search); // Using window.location instead of useLocation()
        this.staticParamCategoryProduct.page = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
        this.staticParamCategoryProduct.search = query.get("keyword")
            ? String(query.get("keyword"))
            : "";
    }

    async fetchListCategoryProduct(run?:boolean) {
        let { ids, offset, page, parent_id, per_page, search, sort, status } =
            this.staticParamCategoryProduct;
        const paramsCateogoriesProduct = {
            ids: ids,
            offset: offset,
            page: page + 1,
            parent_id: parent_id,
            per_page: per_page,
            search: search,
            sort: sort,
            status: status,
        };
        if(this.dataListCategoryProduct.length < 1 || run){
            this.isLoading = true;
        }
        const result = await categoryProductService.fetchCateogoriesProduct(
            paramsCateogoriesProduct
        );
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            if(result.body.data !== this.dataListCategoryProduct){
                this.metadata = result.body.metadata;
                this.dataListCategoryProduct = result.body.data;
                if(parent_id && result.body.data.length > 0){
                    this.dataCategoryPro =  result.body.data
                    StorageService.saveArayLS("arrProPos", result.body.data)
                }
            }
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchListCategoryProductAll() {
        const resultCategoryProductAll =
            await categoryProductService.fetchCateogoriesProductAll();
        if (resultCategoryProductAll.status === HttpStatusCode.Ok) {
            this.dataListCategoryProductAll =
                resultCategoryProductAll.body.data;
        } else {
            toastUtils.error(resultCategoryProductAll.body.message, "");
        }
    }
    setPage(page: number, search: string = "") {
        this.staticParamCategoryProduct.page = page;
        this.staticParamCategoryProduct.search = search;
        this.fetchListCategoryProduct();
    }

    handleSearch = (e: React.FormEvent, navigate: NavigateFunction) => {
        e.preventDefault();
        const searchKeyword = (e.target as HTMLFormElement).keyword.value;
        const params: any = {};
        if (searchKeyword !== "") params.keyword = searchKeyword;
        navigate({
            search: createSearchParams(params).toString(),
        });
        this.staticParamCategoryProduct.page = 0;
        this.fetchListCategoryProduct();
    };

    handlePageClick = (
        data: { selected: number },
        navigate: NavigateFunction
    ) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (this.staticParamCategoryProduct.search !== "")
            params.keyword = this.staticParamCategoryProduct.search;
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });

        this.staticParamCategoryProduct.page = selectedPage;
        this.fetchListCategoryProduct();
    };

    changeFile = (pathFile: string) => {
        this.staticDataCategoryProduct.preview = pathFile;
    };

    changeFileIcon = (pathFile: string) => {
        this.staticDataCategoryProduct.icon = pathFile;
    };

    handleClosePreview = () => {
        categoryProductStore.staticDataCategoryProduct.preview = "";
    };
    handleCloseIcon = () => {
        categoryProductStore.staticDataCategoryProduct.icon = "";
    };
    handleFileIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileIcon = e.target.files?.[0];
        if (fileIcon) {
            const iconUrl = URL.createObjectURL(fileIcon);
            categoryProductStore.staticDataCategoryProduct.icon = iconUrl;
        }
    };

    async fetchCreateNewCategory(resetForm: () => void ) {
        if(this.isLoadingSave === false){
            this.isLoadingSave = true;
            let { name, description, parent_id, preview, icon, featured } =
                this.staticDataCategoryProduct;
            const paramsCreate = {
                name: name,
                description: description,
                parent_id: parent_id,
                preview: preview,
                icon: icon,
                featured: featured,
            };
            const resultCreate = await categoryProductService.fetchCreateNewCategory(paramsCreate);
            this.isLoadingSave = false;
            if (resultCreate.status == HttpStatusCode.Ok) {
                this.setIsModalOpen(false);
                this.staticParamCategoryProduct.page = 0;
                resetForm();
                this.staticParamCategoryProduct.search = "";
                this.staticDataCategoryProduct.preview = "";
                this.staticDataCategoryProduct.icon = "";
                this.dataListCategoryProduct.unshift(resultCreate.body.data); // Thêm data mới vào đầu mảng
                if (this.staticParamCategoryProduct.per_page < this.dataListCategoryProduct.length){
                    this.dataListCategoryProduct.pop(); // Xóa phần tử cuối cùng ra khỏi mảng
                }
                toastUtils.success("Thêm mới thành công", "");
            } else {
                if (resultCreate.body.message.category_product) {
                    toastUtils.error("Danh mục này đã tồn tại", "");
                } else {
                    toastUtils.error("Đã có lỗi xảy ra", "");
                }
            }
        }
    }
    async fetchGetCategoryProduct(id: number,category_id?: string) {
        const params = {
            id: id,
            category_id: category_id
        };
        const result = await categoryProductService.fetchGetCategoryProduct(
            params
        );
        if (result.status == HttpStatusCode.Ok) {
            this.staticDataCategoryProduct.id = result.body.id;
            this.staticDataCategoryProduct.preview = result.body.preview
                ? result.body.preview.content
                : "";
            this.staticDataCategoryProduct.icon = result.body.icon
                ? result.body.icon.content
                : "";
            this.staticDataCategoryProduct.name = result.body.name;
            this.staticDataCategoryProduct.parent_id = result.body.category_id
                ? result.body.category_id
                : "";
            this.staticDataCategoryProduct.description = result.body.description
                ? result.body.description.content
                : "";
            this.staticDataCategoryProduct.featured = result.body.featured;
            this.initialValues = {
                name: this.staticDataCategoryProduct.name || "",
                description: this.staticDataCategoryProduct.description || "",
                parent_id: this.staticDataCategoryProduct.parent_id || "",
            };
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
            // toastUtils.error(result.body.message,'');
        }
    }
    async fetchCategoryProductParent() {
        this.isLoading = true
        const result = await categoryProductService.fetchCateogoriesParent();
        this.isLoading = false
        if (result.status === HttpStatusCode.Ok) {
            this.dataCategoryPro = result.body
        }else{
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchUpadateCategoryProduct() {
        if(this.isLoadingSave === false){
            this.isLoadingSave = true;
            let { name, description, parent_id, preview, icon, featured } =
                this.staticDataCategoryProduct;
            const params = {
                name: name,
                description: description,
                parent_id: parent_id,
                preview: preview,
                icon: icon,
                featured: featured,
            };

            const result = await categoryProductService.fetchUpadateCategoryProduct(
                params,
                this.staticDataCategoryProduct.id
            );
            this.isLoadingSave = false;
            if (result.status == HttpStatusCode.Ok) {
                this.dataListCategoryProduct = this.dataListCategoryProduct.map((item:any) => {
                    if(item.id === this.staticDataCategoryProduct.id){
                        item.name = name
                        item.slug = utils.convertToSlug(item.name)
                        item.description = description
                        item.meta = preview
                    }
                    return item;
                })

                this.setIsModalOpen(false);
                this.staticParamCategoryProduct.page = 0;
                this.staticParamCategoryProduct.search = "";
                this.staticDataCategoryProduct.preview = "";
                this.staticDataCategoryProduct.icon = "";
                toastUtils.success("Cập nhật thành công", "");
            } else {
                if (result.statusCode == 422) {
                    if (result.body.message.category_product) {
                        toastUtils.error("Danh mục này đã tồn tại", "");
                    } else {
                        toastUtils.error("Đã có lỗi xảy ra", "");
                    }
                }
                {
                    toastUtils.error("Đã có lỗi xảy ra", "");
                }
            }
        }
    }

    async fetchDeleteCategoryProduct(id: number) {
        const params = {
            id: id,
        };
        const resultCreate =
            await categoryProductService.fetchDeleteCategoryProduct(params);
        if (resultCreate.status == HttpStatusCode.Ok) {
            toastUtils.success("Xóa thành công", "");
            this.dataListCategoryProduct = this.dataListCategoryProduct.filter((item:any) => item.id !== id)
            if(this.dataListCategoryProduct.length === 0){
                this.fetchListCategoryProduct();
            }
        } else {
            toastUtils.error(resultCreate.body.message, "");
        }
    }

    setIsModalOpen = (isOpen: boolean) => {
        this.isModalOpen = isOpen;
    };
    cshowModal = () => {
        this.setIsModalOpen(true);
    };

    handleOk = () => {
        this.setIsModalOpen(false);
    };

    handleClose = () => {
        this.staticDataCategoryProduct.id = null;
        this.staticDataCategoryProduct.preview = "";
        this.staticDataCategoryProduct.icon = "";
        this.staticDataCategoryProduct.name = "";
        this.staticDataCategoryProduct.parent_id = "";
        this.staticDataCategoryProduct.description = "";
        this.initialValues = {
            name: "",
            description: "",
            parent_id: "",
        };
    };

    handleCancel = () => {
        this.setIsModalOpen(false);
    };
    constructor() {
        makeAutoObservable(this);
    }
}

export const categoryProductStore = new CategoryProductStore();
