import React, { useEffect } from "react";
import "./ProductInPosControl.scss";
import { observer } from "mobx-react";
import ModalListCustomer from "../modal/modalListCustomer/ModalListCustomer";
import {
    IdataFormAddCart,
    productInPosCartStore,
} from "../../ProductInPosCartStore";
import { utils } from "../../../../../common/utils/Utils";
import StorageService from "../../../../../common/service/StorageService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toJS } from "mobx";
import { toastUtils } from "../../../../../common/utils/Toastutils";
import {transferTableStore} from "../modal/transferTable/TransferTableStore";
import TransferTable from "../modal/transferTable/TransferTable";
import ModalOption from "../modal/modalOption/ModalOption";
import {productInPostStore} from "../../ProductInPostStore";

export const dataNumber = [
    { id: 1, content: "1" },
    { id: 2, content: "2" },
    { id: 3, content: "3" },
    { id: "sl", content: "SL" },
    { id: 4, content: "4" },
    { id: 5, content: "5" },
    { id: 6, content: "6" },
    { id: "ck", content: "%CK" },
    { id: 7, content: "7" },
    { id: 8, content: "8" },
    { id: 9, content: "9" },
    { id: "price", content: "Giá" },
    { id: "del1", content: "Xóa" },
    { id: 0, content: "0" },
    { id: "dots", content: "." },
    { id: "del2", content: "", icon: "/assets/icon/deletePayment.svg" },
];

export const dataStatusTable = {
    notBooked: "1", // Bàn chưa được đặt(Chưa có món)
    booked: "2", // Bàn đã được đặt(chưa có món)
    released: "3", // Bàn đã có món ăn
}

const ProductInPosControl = () => {

    const navigate = useNavigate();
    const { postId } = useParams();
    const { diningTableId } = useParams();
    productInPosCartStore.diningTablesId = diningTableId;

    // const handleOpenModal = () => {
    //     const diningTableSession = StorageService.getLocalStore("dining_table_session");
    //
    //     // Log the diningTableSession to verify the retrieved value
    //     console.log("dining_table_session:", diningTableSession);
    //
    //     // Check if diningTableSession is undefined or null
    //     if (!diningTableSession) {
    //         toastUtils.error('Vui lòng thêm món hoặc đặt bàn!', '');
    //         return;
    //     } else {
    //         transferTableStore.openModal();
    //     }
    // };


    useEffect(() => {
        if (
            productInPosCartStore.dataListProInCart.length > 0 &&
            !productInPosCartStore.flagActiveVariant
        ) {
            productInPosCartStore.activeVariantProduct =
                productInPosCartStore?.dataListProInCart[0].variant.id;
        }
    }, [productInPosCartStore?.dataListProInCart]);

    useEffect(() => {
        const storedCart = StorageService.getObjectStore(
            `customerPos${postId}`
        );
        if (storedCart) {
            productInPosCartStore.dataCustomer = storedCart;
        }
    }, [postId]);

    return (
        <div className="ProductInPosControl">
            <div>
                <div className="ProductInPosControl-body">
                    {productInPosCartStore.dataListProInCart?.items?.length > 0 ? (
                        productInPosCartStore.dataListProInCart?.items?.map(
                            (item: IdataFormAddCart) => (
                                <div
                                    className={`ProductInPosControl-product ${
                                        productInPosCartStore.activeVariantProduct ===
                                        item.variant.id
                                            ? "active"
                                            : ""
                                    }`}
                                    style={{
                                        borderLeft: item.tmp_order_status === "2" ? "4px solid #029334" : "none",
                                    }}
                                    onClick={() => {
                                        productInPosCartStore.activeVariantProduct =
                                            item.variant.id;
                                        productInPosCartStore.row_id = item.row_id;
                                        // productInPosCartStore.diningTableSession = item.dining_tables_session;
                                    }}
                                    key={item.sku}
                                >
                                    <div className="boxProduct-infor">
                                        <h2
                                            style={{
                                                color: item.tmp_order_status === "2" ? "#029334" : "#061020"
                                            }}
                                        >
                                            {item.productName}{" "}
                                            {item.variant.name
                                                ? `- ${item.variant.name}`
                                                : ""}
                                        </h2>
                                        <p
                                            style={{
                                                color: item.tmp_order_status === "2" ? "#029334" : "#061020"
                                            }}
                                        >
                                            {item.quantity} {item.unit} x{" "}
                                            {utils.formatCurrency(
                                                Number(item.price)
                                            )}
                                            / {item.unit}
                                        </p>
                                        {item.discount > 0 ||
                                        String(item.discount).includes(".") ? (
                                            <p>
                                                Chiết khấu {item.discount}% với
                                                một sản phẩm
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="boxProduct-total"
                                        style={{
                                            color: item.tmp_order_status === "2" ? "#029334" : "#061020"
                                        }}
                                    >
                                        {utils.formatCurrency(
                                            item.discount > 0
                                                ? ((item.price as number) *
                                                      item.quantity *
                                                      (100 - item.discount)) /
                                                      100
                                                : (item.price as number) *
                                                      item.quantity
                                        )}
                                    </div>
                                </div>
                            )
                        )
                    ) : (
                        <div className="emptyCart">
                            <img src="/assets/images/emptyCart.svg" alt="" />
                            <p>Bắt đầu thêm sản phẩm</p>
                        </div>
                    )}
                </div>
            </div>
            {productInPosCartStore.dataListProInCart.dining_table_status === "1" ? (
                <div
                    className="ProductInPosControl-booking"
                    style={{
                        display: "flex",
                    }}
                    onClick={() => {
                        if (productInPosCartStore.isLoadingBtn === false) {
                            productInPosCartStore.startBookingTable();
                        }
                    }}
                >
                    <span>{productInPosCartStore.isLoadingBtn ? "Vui lòng đợi..." : "Đặt bàn"}</span>
                </div>
            ) : productInPosCartStore.dataListProInCart.dining_table_status === "2" ? (
                <div
                    className="ProductInPosControl-booking"
                    style={{
                        display: "flex",
                    }}
                    onClick={() => {
                            if (productInPosCartStore.isLoadingBtn === false) {
                                productInPosCartStore.endBookingTable()
                            }
                        }
                    }
                >
                    <span>{productInPosCartStore.isLoadingBtn ? "Vui lòng đợi..." : "Giải phóng bàn"}</span>
                </div>
            ) : ""}
            <div className="ProductInPosControl-footer">
                {productInPosCartStore.dataListProInCart?.items?.length > 0 && (
                    <div className="total-price ">
                        <p>(Giá đã bao gồm VAT)</p>{" "}
                        <h3>
                            {" "}
                            Tổng:{" "}
                            {utils.formatCurrency(
                                productInPosCartStore.CaculatorTotal(
                                    productInPosCartStore.dataListProInCart.items
                                )
                            )}
                        </h3>
                    </div>
                )}
                <div className="action">
                    <div
                        className="action-item"
                        onClick={() => {
                            productInPostStore.isOpenModalListCustomer = true;
                        }}
                    >
                        <img
                            src="/assets/icon/customer.svg"
                            width={14}
                            height={14}
                            alt=""
                        />
                        <div
                            className="text-truncate"
                            style={{ maxWidth: "100px" }}
                        >
                            {productInPosCartStore.dataCustomer?.name
                                ? productInPosCartStore.dataCustomer?.name
                                : "Khách hàng"}
                        </div>
                    </div>
                    {/* <div className="action-item" onClick={()=> navigate(`/pos/${postId}/hoan-tien`)}>
            <img src="/assets/icon/refund.svg" alt="" />
            Hoàn tiền
          </div> */}
                    {/* <div
            className="action-item"
            onClick={() => {
              StorageService.removeLocalStore(`cartPos${postId}`);
              productInPosCartStore.dataListProInCart = [];
            }}
          >
            <img src="/assets/icon/deleteAll.svg" alt="" />
            Xóa tất cả
          </div> */}
                    <div className="action-item">
                        <img
                            src="/assets/icon/note_fnb.svg"
                            width={14}
                            height={14}
                            alt="ghi chu"
                        />
                        Ghi chú món
                    </div>
                    <div
                        className="action-item"
                        onClick={() => transferTableStore.openModal()} // Gọi openModal() với arrow function để bind đúng context
                    >
                        <img
                            src="/assets/icon/switch-table.svg"
                            width={14}
                            height={14}
                            alt="chuyen ban"
                        />
                        Chuyển bàn
                    </div>
                    {transferTableStore.isModalOpen && <TransferTable />}
                </div>
                <div className="action" style={{marginTop: "2px"}}>
                    <div className="action-item">
                        {!productInPostStore.dataInfoTable.dining_table_session ? "" : (
                            <div className="quantity-customer">{productInPostStore.dataInfoTable?.dining_table_session?.quantity_people_reality}</div>
                        )}
                        <div
                            className="text-truncate"
                            style={{maxWidth: "100px"}}
                        >
                            Khách
                        </div>
                    </div>
                    <div className="action-item"
                         onClick={() => productInPostStore.openModal()}
                         // onClick={productInPostStore.openModal}
                        >
                        Thêm...
                    </div>
                {productInPostStore.isModalVisible && <ModalOption />}
            </div>
                <div className="caculator">
                    <div className="caculator-number">
                        {dataNumber.map((item) => (
                            <div
                                className={`caculator-number__Minbox ${
                                    productInPosCartStore.productControl
                                        .type === item.id
                                        ? "active"
                                        : ""
                                }`}
                                onClick={() => {
                                    if (
                                        item.id === "sl" ||
                                        item.id === "ck" ||
                                        item.id === "price"
                                    ) {
                                        // Cập nhật cả type và keycap cho các nút đặc biệt
                                        productInPosCartStore.ChangeValueProductInfo(
                                            item.id, // Cập nhật type
                                            item.id, // Cập nhật keycap
                                            productInPosCartStore.activeVariantProduct
                                        );
                                    } else {
                                        // Chỉ cập nhật keycap cho các nút khác
                                        productInPosCartStore.ChangeValueProductInfo(
                                            productInPosCartStore.productControl
                                                .type, // Giữ nguyên type
                                            item.id, // Cập nhật keycap
                                            productInPosCartStore.activeVariantProduct
                                        );
                                    }
                                }}
                                key={item.id}
                            >
                                {item.content}
                                {item.icon && <img src={item.icon} alt="img" />}
                            </div>
                        ))}
                    </div>
                    {/* <div
            onClick={() => {
              productInPosCartStore.dataListProInCart.length > 0
                ? navigate(`/pos/${postId}/thanh-toan`)
                : toastUtils.warning("Bạn chưa chọn sản phẩm nào");
            }}
            className="caculator-payment"
          >
            <img src="/assets/icon/arrowPayment.svg" alt="arr" />
            Thanh Toán
          </div> */}
                    <div className="caculator-action">
                        <div
                            onClick={() => {
                                // productInPosCartStore.dataListProInCart.items.length >
                                // 0
                                //     ? navigate(`/pos/${postId}/thanh-toan`)
                                //     : toastUtils.warning(
                                //           "Bạn chưa chọn sản phẩm nào"
                                //       );
                                if (productInPosCartStore.dataListProInCart?.items.length > 0) {
                                    if (productInPosCartStore.dataListProInCart?.items.every((item:any) => item.tmp_order_status === "2")) {
                                        toastUtils.warning("Vui lòng order trước", "");
                                    } else {
                                        navigate(`/pos/${postId}/thanh-toan`)
                                    }
                                } else {
                                    toastUtils.warning("Bạn chưa chọn món nào", "");
                                }
                            }}
                            className="caculator-payment"
                        >
                            <img
                                src="/assets/icon/arrowPayment.svg"
                                alt="arr"
                            />
                            Thanh Toán
                        </div>
                        <div
                            className="caculator-order d-flex align-items-center justify-content-center flex-column"
                            style={{
                                background: productInPosCartStore.dataListProInCart?.items?.length > 0 ? "#0e72ed" : "#E7E9ED",
                            }}
                            onClick={() => {
                                // Nếu tmp_order_status = 1 (tất cả món đã được order) return 
                                if (productInPosCartStore.dataListProInCart?.items?.every((status:any) => status === "1")) {
                                    return;
                                }

                                productInPosCartStore.saveOrderCart()
                            }}
                        >
                            <img src="/assets/icon/cooking.svg" alt="" />
                            Order
                        </div>
                    </div>
                </div>
            </div>
            <ModalListCustomer />
        </div>
    );
};

export default observer(ProductInPosControl)
