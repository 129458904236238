import { getRequest, postRequest } from "../../../common/helpers/RequestHelper";

class SpacesFloorRoomTableService {
    apiListFloors = "api/v1/pos/fnb/list_floor_room";
    apiGetFirstFloors = "api/v1/pos/fnb/floor";
    apiGetTableOfFloor = "api/v1/pos/fnb/get_table_of_floor";
    apiGetTableOfRoom = "api/v1/pos/fnb/get_table_of_room";
    apiCreateTable = "api/v1/dining_tables";
    apiCreateFloorRoom = "api/v1/floor_room";
    apiListLocation = "api/v1/location/list";
    apiListRoom = "api/v1/floor_room";

    public fetchListLocation(): Promise<any> {
        return getRequest(this.apiListLocation);
    }

    public fetchListFloors(listFloorQuery: any): Promise<any> {
        return getRequest(this.apiListFloors, listFloorQuery);
    }

    public fetchGetFirstFloors(params: any): Promise<any> {
        return getRequest(this.apiGetFirstFloors, params);
    }

    public fetchGetListRoom(params: any): Promise<any> {
        return getRequest(this.apiListRoom, params);
    }

    public fetchGetTableOfFloors(params: any) {
        return getRequest(this.apiGetTableOfFloor, params);
    }

    public fetchGetTableOfRooms(params: any) {
        return getRequest(this.apiGetTableOfRoom, params);
    }

    public fetchCreateFloorRoom(params: any): Promise<any> {
        return postRequest(this.apiCreateFloorRoom, params);
    }

    public fetchCreateTable(params: any): Promise<any> {
        return postRequest(this.apiCreateTable, params);
    }
}

export const spacesFloorRoomTableService = new SpacesFloorRoomTableService();
