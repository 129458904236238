import {makeAutoObservable, toJS} from "mobx";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../common/utils/Toastutils";
import {inventoryService} from "./InventoryService";

class InventoryStore {
    listLocation: any[] = [];
    isLoading: boolean = false; // A value status showing if the data is being fetched.
    // listBanner: string[] = [];
    // listInventoryProduct: any[] = []; // A list is used to stored the Inventory for all Products
    listBrand: any[] = []; //List to get all the brands after fetching
    listCategory: any[] = []; // A List is used to get all the Product Categories after fetching (we put these)
    // isCollapsible: boolean = false; //collapsible of collapse antd
    // brandOptionValue: number[] = []; // An Array to stored all the Brands Option which is CHOSEN in the Filter Tab
    // categoryOptionValue: number[] = []; // An Array to stored all the Categories Option which is CHOSEN in the Filter Tab
    // productTypeOptionValue: number = -1; // A value is used to stored the product type option. -1 means nothing is chosen. 0 means "Sản phẩm đơn giản" - Origin. 1 means "Sản phẩm biến thể" - Variants
    // isFiltering: boolean = false; //Check if the Filtering Option is ON
    inventoryAmountList: number[] = []; //Dummy List. Be used to store input when increase/decrease amount
    // listProduct: any[] = []; // A list is used to store all the Products
    listVariant: any[] = []; // A list is used to store all the Variants
    // listFilteredProduct: any[] = []; // A list is used to store all the Products after filtered
    // isFetchingFilter: boolean = false;
    selectedCategory: number = -1;
    selectedBrand: number = -1;
    selectedProductType: number = -1;

    staticParamVariant: {
        is_variant: string|null
        locked: boolean|null
        order_by: string|null
        page: number
        parent_id: number|null
        search: string|null
        size: number|null
        status: string|null
        store_id: string|null
        brand_id: string|null
    } = {
        is_variant: "",
        locked: null,
        order_by: "",
        page: 0,
        parent_id: null,
        search: "",
        size: null,
        status: "",
        store_id:"",
        brand_id: ""
    }

    metadata: { total: number, page: number, total_page: number, size: number } = {
        total: 0,
        page: 0,
        total_page: 0,
        size: 0
    }


    staticParamInventoryProduct: {
        page: number,
        search: string,
        per_page: number,

    } = {
        page: 0,
        search: "",
        per_page: 10,
    };
    // staticParamBrand: { search: string } = {
    //     search: "",
    // };
    // staticParamCategory: { search: string } = {
    //     search: "",
    // };


    staticDataVariant: {
        id: string,
        name: string,
        store_id: string,
        location_id: number,
    } = {
        id: '',
        name: '',
        store_id: '1', // Xử lí tạm thời, đây vẫn là bug
        location_id: 1,

    }

    // staticParamProduct: { search: string } = {
    //     search: "",
    // };

    constructor() {
        makeAutoObservable(this);
        this.initParam();
        this.initializeParams()
    }
    initializeParams() {
        const query = new URLSearchParams(window.location.search);
        this.staticParamVariant.search = query.get("search")
            ? String(query.get("search"))
            : "";
        this.staticParamVariant.store_id = query.get("store_id")
            ? String(query.get("store_id"))
            : "";
        this.staticParamVariant.parent_id = query.get("parent_id")
            ? Number(query.get("parent_id"))
            : null;
        this.staticParamVariant.brand_id = query.get("brand_id")
            ? String(query.get("brand_id"))
            : "";
    }

    initParam() {
        const query = new URLSearchParams(window.location.search);
        this.staticParamInventoryProduct.search = query.get("search")
            ? String(query.get("search"))
            : "";
        this.staticParamInventoryProduct.page = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
    }

    async getLocation(run?: boolean) {
        if (this.listLocation.length < 1 || run) {
            const result = await inventoryService.fetchLocation();
            if (result.status === HttpStatusCode.Ok) {
                this.listLocation = result.body.data;
                // console.log("ListLocation", this.listLocation);
            } else {
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
            this.isLoading = false;
        }
    }

    // async getInventoryAll(run?: boolean) {
    //     if(this.listInventoryProduct.length < 1 || run){
    //         this.isLoading = true;
    //     }
    //     const result = await inventoryService.fetchAllInventoryProduct();
    //     if (result.status === HttpStatusCode.Ok) {
    //         this.listInventoryProduct = result.body.data;
    //         this.metadata = result.body.metadata;
    //         this.inventoryAmountList = this.listInventoryProduct.map(
    //             (item: any) => item.quantity
    //         );
    //     } else {
    //         toastUtils.error("Đã có lỗi xảy ra!", "");
    //     }
    //     this.isLoading = false;
    // }

    // async getInventoryViaParam(run?: boolean) {
    //     if (this.listInventoryProduct.length < 1 || run) {
    //         this.isLoading = true;
    //     }
    //     let {search, page, per_page} = this.staticParamInventoryProduct;
    //     const params = {
    //         search: search,
    //         per_page: per_page,
    //         page: page + 1,
    //     };
    //     const result = await inventoryService.fetchInventoryProduct(params);
    //     if (result.status === HttpStatusCode.Ok) {
    //         this.listInventoryProduct = result.body.data;
    //         this.inventoryAmountList = this.listInventoryProduct.map(
    //             (item: any) => item.quantity
    //         );
    //         this.metadata = result.body.metadata;
    //     } else {
    //         toastUtils.error("Đã có lỗi xảy ra!", "");
    //     }
    //     this.isLoading = false;
    // }

    async handleSaveAmount(id: any, value: number) {
        // Tìm item trong listVariant dựa trên id
        const inventoryItem = this.listVariant.find(
            (item) => Number(item.id) === Number(id)
        );

        if (!inventoryItem) {
            toastUtils.error("Không tìm thấy sản phẩm phù hợp!", "");
            return;
        }

        const updatedInventoryProduct = {
            id: String(inventoryItem.inventory_id),
            quantity: value,
        };

        if (Number(inventoryItem.quantity) !== value) {
            const result = await inventoryService.fetchModifyInventoryProduct(
                updatedInventoryProduct
            );
            if (result.status === HttpStatusCode.Ok) {
                toastUtils.success("Sửa thành công!", "");
                const updateDataInventory = this.listVariant.map((item: any) =>
                    item.id === id
                        ? {
                            ...item,
                            quantity_inventory: value,
                        }
                        : item
                );
                this.listVariant = updateDataInventory;
                // this.getAllVariant();
            } else {
                toastUtils.error("Đã có lỗi xảy ra!", "");
            }
        } else {
            // Chỉ cập nhật khi cần thiết
            return -1;
        }
    }



    // async handleSaveAmount(id: any, value: number) {
    //     // Lấy inventory_id từ listVariant dựa trên id
    //     const inventoryItem = this.listVariant.find(
    //         (item) => Number(item.id) === Number(id)
    //     );
    //
    //     if (!inventoryItem) {
    //         // Nếu không tìm thấy item phù hợp, thoát ra và thông báo lỗi (hoặc xử lý khác)
    //         toastUtils.error("Không tìm thấy sản phẩm phù hợp!", "");
    //         return;
    //     }
    //
    //     const updatedInventoryProduct = {
    //         inventory_id: String(inventoryItem.inventory_id), // Đổi id thành inventory_id
    //         quantity: value,
    //     };
    //
    //     // Chỉ thực hiện cập nhật nếu quantity có thay đổi
    //     if (Number(inventoryItem.quantity) !== value) {
    //         const result = await inventoryService.fetchModifyInventoryProduct(
    //             updatedInventoryProduct
    //         );
    //         if (result.status === HttpStatusCode.Ok) {
    //             // this.getInventoryAll();
    //             toastUtils.success("Sửa thành công!", "");
    //         } else {
    //             toastUtils.error("Đã có lỗi xảy ra!", "");
    //         }
    //     } else {
    //         // Chỉ cập nhật khi cần thiết
    //         return -1;
    //     }
    // }



    // async fetchSideData() {
    //     this.isLoading = true;
    //     await this.getAllBrand();
    //     await this.getAllCategory();
    //     await this.getAllVariant();
    //     this.isCollapsible = true;
    // }

    // Fetching Side data
    // async getAllProduct(run?: boolean) {
    //     if (this.listProduct.length < 1 || run) {
    //         this.isLoading = true;
    //     }
    //     const result = await inventoryService.fetchAllProduct();
    //     if (result.status === HttpStatusCode.Ok) {
    //         this.listProduct = result.body.data;
    //     } else {
    //         toastUtils.error("Đã có lỗi xảy ra!", "");
    //     }
    // }

    async getAllVariant(run?: boolean) {
        if (this.listVariant.length < 1 || run) {
            this.isLoading = true;
        }
        let {
            is_variant,
            locked,
            order_by,
            page,
            parent_id,
            search,
            size,
            status,
            brand_id,
        } = this.staticParamVariant;

        const paramVariant = {
            is_variant: is_variant,
            locked: locked,
            order_by: order_by,
            page: page + 1,
            parent_id: parent_id,
            search: search,
            size: size,
            status: status,
            brand_id: brand_id,
        };

        const {store_id} = this.staticDataVariant;

        const result = await inventoryService.fetchAllVariants(store_id, paramVariant);

        if (result.status === HttpStatusCode.Ok) {
            this.metadata = result.body.metadata;
            this.listVariant = result.body.data;
            this.inventoryAmountList = this.listVariant.map(
                (item: any) => item.quantity_inventory
            );
            // console.log("ListVariant", toJS(this.listVariant));
        } else {
            if(result.body.message?.search === "the_search_must_not_be_greater_than_255_characters"){
                toastUtils.warning("Không được nhập quá 255 ký tự!");
                return;
            }
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }

        this.isLoading = false;
    }

    async getAllBrand(run?: boolean) {
        if (this.listBrand.length < 1 || run) {
            this.isLoading = true;
        }
        const result = await inventoryService.fetchAllBrand();
        if (result.status === HttpStatusCode.Ok) {
            this.listBrand = result.body.data;
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }

    async getAllCategory(run?: boolean) {
        if (this.listCategory.length < 1 || run) {
            this.isLoading = true;
        }
        const result = await inventoryService.fetchAllCategories();
        if (result.status === HttpStatusCode.Ok) {
            this.listCategory = result.body.data;
            // console.log("ListCategory", toJS(this.listCategory));
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }

    // Function
    // async checkFiltering() {
    //     //Check if any Filter Options is chosen. At the same time, it also checks the Remove Filter Button Status. If user is filtering, the button is on.
    //     this.listFilteredProduct = [];
    //     if (
    //         this.brandOptionValue.length === 0 &&
    //         this.productTypeOptionValue === -1 &&
    //         this.categoryOptionValue.length === 0
    //     ) {
    //         this.isFiltering = false;
    //     } else {
    //         this.isFetchingFilter = true;
    //         this.isFiltering = true;
    //     }
    //     this.isFetchingFilter = false;
    // }


    // handleRemoveFilter() {
    //     //Remove filter options in filter tabs
    //     this.brandOptionValue = [];
    //     this.productTypeOptionValue = -1;
    //     this.categoryOptionValue = [];
    //     this.isFiltering = false;
    // }

    // handleCheckboxBrand(check: boolean, value: number) {
    //     //Handle Click on Checkbox in Brand Option Filter
    //     if (check === true) {
    //         this.brandOptionValue.push(value);
    //     } else {
    //         this.brandOptionValue = this.brandOptionValue.filter(
    //             (id: number) => id !== value
    //         );
    //     }
    // }

    // handleUniqueArrayWithID(array: any[]) {
    //     //Handle Filtered Product After Fetching when Choosing option from FitlerTab
    //     var dummyIDProduct: number[] = [];
    //     for (var index in array) {
    //         var id = Number(array[index].id);
    //         if (!dummyIDProduct.includes(id)) {
    //             dummyIDProduct.push(id);
    //         } else {
    //             array.splice(Number(index), 1);
    //         }
    //     }
    //     return array;
    // }
    //
    // handleCheckboxCategory(check: boolean, value: number) {
    //     //Handle Click on Checkbox in Category Option Filter
    //     if (check === true) {
    //         this.categoryOptionValue.push(value);
    //     } else {
    //         this.categoryOptionValue = this.categoryOptionValue.filter(
    //             (id: number) => id !== value
    //         );
    //     }
    // }

    hanldeMinusingBtn(position: number) {
        if (this.inventoryAmountList[position] > 0) {
            this.inventoryAmountList[position] -= 1;
        } else {
            this.inventoryAmountList[position] = 0;
            toastUtils.error("Giá trị tồn kho không thể nhỏ hơn 0!", "");
        }
    }

    handleAddingBtn(position: number) {
        this.inventoryAmountList[position] += 1;
        // this.listVariant[position].quantity_inventory = this.inventoryAmountList[position];
    }

    handleInputAmount(value: any, position: number) {
        value = Number(value);
        if (value < 0) {
            toastUtils.error("Giá trị tồn kho không thể nhỏ hơn 0!", "");
        } else if(value > 999999){
            value = 999999
        } else {
            this.inventoryAmountList[position] = value;
        }
    }

    // async handleSearching() {
    //     toastUtils.warning(
    //         "Chức năng tìm kiếm đang được sửa. Vui lòng quay lại sau",
    //         ""
    //     );
    // }

    async handleCategorySelect(value: any) {
        value = Number(value)
        if (this.selectedCategory === value) {
            this.selectedCategory = -1;
            this.staticParamVariant.parent_id = null;
        } else {
            this.selectedCategory = value;
            this.staticParamVariant.parent_id  = value;
        }

        this.getAllVariant();
    }

    async handleBrandSelect(value: any) {
        value = Number(value)
        if (this.selectedBrand === value) {
            this.selectedBrand = -1;
            this.staticParamVariant.brand_id = null;
        } else {
            this.selectedBrand = value;
            this.staticParamVariant.brand_id  = value;
        }

        this.getAllVariant();
    }

    async handleProductTypeSelect(value: any) {
        value = Number(value)
        if (this.selectedProductType === value) {
            this.selectedProductType = -1;
            this.staticParamVariant.is_variant = null;
        } else {
            this.selectedProductType = value;
            this.staticParamVariant.is_variant = value;
        }
        this.getAllVariant();
    }
}

export const inventoryStore = new InventoryStore();
