import React, { useEffect } from "react";
import { Modal, Input, Select, Button } from "antd";
import "./styles/CombineTable.css";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import StorageService from "../../../../../../common/service/StorageService";
import { showDelete } from "../../../../../../shared/components/showDelete";
import { combineTableStore } from "./CombineTableStore";

const CombineTable = () => {
    const { diningTableId, postId } = useParams<{ diningTableId: string; postId: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        combineTableStore.setDiningTableId(diningTableId!);
        combineTableStore.fetchAllDiningTablesList(true);
    }, [diningTableId]);

    const handleCancel = () => {
        combineTableStore.closeModal();
    };

    const handleTransfer = () => {
        if (combineTableStore.selectedTableId) {
            showDelete.showDeleteConfirm(
                `Bạn chắc chắn muốn chuyển sang bàn này?`,
                "Hành động này không thể hoàn tác.",
                async () => {
                    // await combineTableStore.switchTable();
                    navigate(`/pos/${postId}/san-pham/${combineTableStore.selectedTableId}`);
                }
            );
        }
    };

    const name = StorageService.getLocalStore("name") ?? ""; // If name is null, replace with empty string

    return (
        <Modal
            title={
                <div className="header d-flex align-items-center justify-content-between">
                    Gộp bàn
                </div>
            }
            open={combineTableStore.isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel} className="cancel-button">
                    Huỷ bỏ
                </Button>,
                <Button key="transfer" type="primary" onClick={handleTransfer} className="transfer-button">
                    Gộp bàn
                </Button>
            ]}
            closeIcon={
                <img
                    src="/assets/icon/icon_closeWhite.svg"
                    width={25}
                    height={25}
                    alt="Close"
                    onClick={handleCancel}
                />
            }
            className="transfer-table-modal"
            wrapClassName="CombineTable"
        >
            <div className="line"></div>
            <div className="transfer-table-content">
                <div className="transfer-table-content__input">
                    <div className="transfer-title">Bàn hiện tại</div>
                    <Input defaultValue={name} className="custom-input" disabled />
                    <div className="transfer-title">Chuyển tới bàn trống</div>
                    <Select
                        className="custom-select"
                        placeholder="Vui lòng chọn"
                        onChange={(value) => combineTableStore.setSelectedTableId(value)}
                    >
                        {combineTableStore.listAllDiningTables.map((table) => (
                            <Select.Option key={table.id} value={table.id}>
                                {table.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="line2"></div>
        </Modal>
    );
};

export default observer(CombineTable);
