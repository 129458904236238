import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Loading from '../loading/Loading';
import UploadImage from '../uploadImage/UploadImage';
import { uploadImageStore } from '../uploadImage/UploadImageStore';
import { filesStore } from '../../../pages/settings/files/FilesStore';
import { useLocation } from 'react-router-dom';
import { toJS } from 'mobx';
interface IconUploadProps {
    text?: string | null;
    description?: string | null;
    file: string | null | undefined;
    onIconRemove: () => void;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChooseImg?: (url: string)=> void,
    onResetState?: () => void,
    code?: number| null
}
const UploadFile: React.FC<IconUploadProps> = ({ text,description,file, onIconRemove, onFileChange, onChooseImg, code , onResetState}) => {
    const location = useLocation()
    useEffect(() => {
        onResetState && onResetState()
    }, [location.pathname]);
  
    return (
        <div className="upload-form">
            {text && <label>{text}</label>}
            {file ? (
                <div className="upload" title="Thêm file">
                    <a href={file} target="_blank" rel="noopener noreferrer">
                        <img className="image-upload" src={file} alt="Preview" />
                    </a>
                    <div onClick={()=>{
                        onIconRemove()
                    }} className="close_image">
                        <img src="/assets/icon/minus-circle.svg" className="icon-action" alt="" title="Xóa" />
                    </div>
                </div>
            ) : (
                <div className="upload">
                    {description && <p>{description}</p>}
                    <div onClick={()=>{
                        uploadImageStore.isMultipleChoice = false ; 
                        uploadImageStore.codeClick = code as number
                        uploadImageStore.typeModal = "single"
                        filesStore.staticDataFiles.path = ""
                        filesStore.staticDataFiles.page = 0
                        filesStore.fetchMediaList(true);
                        }}
                        className='uploadChild'
                        >
                            <div>
                                <img className="icon" style={{cursor: "pointer"}} width="38" height="38" src="/assets/icon/icon_upload.svg" alt="icon_upload" />
                                {text && <span className="text-upload">Chọn {text}</span>}
                            </div>
                    </div>
                </div>
            )}
            {uploadImageStore.typeModal === "single"  && uploadImageStore.codeClick &&
                <UploadImage multipleChoice={uploadImageStore.isMultipleChoice} code={code} onChooseImg={onChooseImg as any}/>
            }
        </div>
    );
};

export default observer(UploadFile);
