import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "./styles/Login.css";
import { observer } from "mobx-react-lite";
import { loginStore } from "./LoginStore";
import Loading from "../../shared/components/loading/Loading";
import StorageService from "../../common/service/StorageService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toastUtils } from "../../common/utils/Toastutils";
const Login = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        loginStore.checkDomain();
    };

    const endpoint: any = localStorage.getItem("domainName");

    const handleSubmitLogin = (e: React.FormEvent) => {
        e.preventDefault();
        if (loginStore.username === "") {
            toastUtils.warning("Vui lòng nhập email");
        } else if (loginStore.username !== "") {
            if (!loginStore.valitedateEmail.test(loginStore.username)) {
                toastUtils.warning("Email không hợp lệ", "");
            }
            if (loginStore.valitedateEmail.test(loginStore.username)) {
                if (loginStore.password === "") {
                    toastUtils.warning("Vui lòng nhập password");
                }
            }
        }

        if (
            loginStore.valitedateEmail.test(loginStore.username) &&
            loginStore.password !== ""
        ) {
            loginStore.login(endpoint);
        }
    };

    return (
        <div className="login d-flex align-items-center justify-content-center">
            <div className="container">
                <div className="loginForm">
                    <div className="loginFormSignIn d-flex">
                        <div className="loginFormSigninLeft d-flex flex-column">
                            {localStorage.getItem("domainName") ? (
                                <p
                                    className="back"
                                    onClick={() => {
                                        localStorage.removeItem("domainName");
                                        window.location.reload();
                                    }}
                                    style={{
                                        color: "#0e72ed",
                                    }}
                                >
                                    Trở lại
                                </p>
                            ) : (
                                ""
                            )}

                            <h2 className="icon">
                                <img src="/assets/images/logo.svg" alt="logo" />
                            </h2>

                            <div className="heading">
                                {localStorage.getItem("domainName") ? (
                                    <>
                                        <p>Đăng Nhập</p>
                                        <p
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {localStorage.getItem("idDomain")}
                                        </p>
                                    </>
                                ) : loginStore.isForgetPassWord ? (
                                    <>
                                        <p>Lấy Lại Mật Khẩu</p>
                                    </>
                                ) : (
                                    <>
                                        <p>Nhập Domain</p>
                                        <p>để vào Đăng nhập</p>
                                    </>
                                )}
                            </div>

                            {!localStorage.getItem("domainName") ? (
                                <Form
                                    className={`idTenant d-flex flex-column`}
                                    // onSubmit={handleSubmit}
                                >
                                    <div className="formIdTenant d-flex align-items-center">
                                        <Form.Control
                                            type="text"
                                            id="inputUsername"
                                            aria-describedby="usernameHelpBlock"
                                            placeholder="Nhập Domain Của Bạn"
                                            style={{
                                                paddingLeft: "0px",
                                            }}
                                            onChange={(e) =>
                                                loginStore.setApiUrl(
                                                    e.target.value
                                                )
                                            }
                                            value={loginStore.domain_name}
                                        />
                                    </div>

                                    <button
                                        className="btnSubmit d-flex align-items-center justify-content-center"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            if (loginStore.domain_name === "") {
                                                toastUtils.warning(
                                                    "Vui lòng nhập Domain",
                                                    ""
                                                );
                                            } else {
                                                loginStore.checkDomain();
                                            }
                                        }}
                                        disabled={loginStore.isLoading}
                                    >
                                        {loginStore.isLoading ? (
                                            <Loading color="#fff" />
                                        ) : (
                                            `Tiếp Tục`
                                        )}
                                    </button>
                                </Form>
                            ) : (
                                <>
                                    {!loginStore.isForgetPassWord ? (
                                        <Form
                                            className={`account d-flex flex-column`}
                                            // onSubmit={handleSubmitLogin}
                                        >
                                            <div className="formUser d-flex align-items-center">
                                                <Form.Control
                                                    type="text"
                                                    id="inputUsername"
                                                    aria-describedby="usernameHelpBlock"
                                                    placeholder="Nhập email"
                                                    style={{
                                                        paddingLeft: "0px",
                                                    }}
                                                    onChange={(e) =>
                                                        loginStore.setUserName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="formPassword d-flex align-items-center">
                                                <Form.Control
                                                      type={`${loginStore.isOpenEyeLogin ? "text" : "password"}`}
                                                    id="inputPassword"
                                                    aria-describedby="passwordHelpBlock"
                                                    placeholder="Nhập password"
                                                    style={{
                                                        paddingLeft: "0px",
                                                    }}
                                                    onChange={(e) =>
                                                        loginStore.setPassWord(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                  {loginStore.isOpenEyeLogin ? 
                                                        <span style={{cursor: "pointer", color: "#333"}} onClick={()=>{loginStore.isOpenEyeLogin = false}}>
                                                        <i className="far fa-eye"></i>  
                                                        </span>:
                                                        <span style={{cursor: "pointer", color: "#333"}} onClick={()=>{loginStore.isOpenEyeLogin = true}}>
                                                        <i className="far fa-eye-slash"></i>
                                                        </span>
                                                    }
                                                {/* <Form.Item
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Vui lòng nhập Password của bạn",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="password"
                                                        onChange={(e) =>
                                                            loginStore.setPassWord(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Item> */}
                                            </div>

                                            <button
                                                type="submit"
                                                className="btnSubmit d-flex align-items-center justify-content-center"
                                                disabled={loginStore.isLoading}
                                                // onClick={(e: any) => {
                                                //     e.preventDefault();
                                                //     if (
                                                //         loginStore.username ===
                                                //         ""
                                                //     ) {
                                                //         toastUtils.warning(
                                                //             "Vui lòng nhập email"
                                                //         );
                                                //     } else if (
                                                //         loginStore.password ===
                                                //         ""
                                                //     ) {
                                                //         toastUtils.warning(
                                                //             "Vui lòng nhập password"
                                                //         );
                                                //     } else {
                                                //         loginStore.login(
                                                //             endpoint
                                                //         );
                                                //     }
                                                // }}
                                                onClick={handleSubmitLogin}
                                            >
                                                {loginStore.isLoading ? (
                                                    <Loading color="#fff" />
                                                ) : (
                                                    `Đăng Nhập`
                                                )}
                                            </button>

                                            <span
                                                className="forgetPassword"
                                                onClick={() => {
                                                    navigate("/quen-mat-khau")
                                                }}
                                            >
                                               
                                                    Quên mật khẩu?
                                            
                                            </span>
                                        </Form>
                                    ) : (
                                        <Form
                                            className={`formForgetPass ${
                                                loginStore.isForgetPassWord
                                                    ? "d-flex"
                                                    : "d-none"
                                            } flex-column`}
                                        >
                                            <div className="formUser d-flex align-items-center">
                                                <Form.Control
                                                    type="text"
                                                    id="inputUsername"
                                                    aria-describedby="usernameHelpBlock"
                                                    placeholder="Nhập email của bạn"
                                                    style={{
                                                        paddingLeft: "0px",
                                                    }}
                                                />
                                            </div>

                                            <button className="btnSubmit d-flex align-items-center justify-content-center">
                                                Đặt lại mật khẩu
                                            </button>

                                            <span
                                                onClick={() => {
                                                    loginStore.setisForgetPassWord(
                                                        false
                                                    );
                                                }}
                                            >
                                                Đăng nhập
                                            </span>
                                        </Form>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="loginFormSigninRight d-flex align-items-center justify-content-center flex-column">
                            <div
                                className="imgWrapper"
                                style={{
                                    maxWidth: "100%",
                                }}
                            >
                                <img
                                    src="/assets/images/Login_img.svg"
                                    alt="Login"
                                />
                            </div>

                            <div
                                className="imgHeading d-flex flex-column align-items-center"
                                style={{
                                    paddingTop: "14px",
                                    gap: "0",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    Tăng tốc doanh thu,
                                    <br /> quản lý đơn hàng dễ dàng
                                </p>

                                <button
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                        marginTop: "14px",
                                        fontSize: "16px",
                                        fontWeight: "700",
                                        padding: "4px 24px",
                                        height: "36px",
                                        width: "258px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <span>Hỗ trợ đăng ký 0916 509 299</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

//* Observer sẽ theo dõi sự thay đổi
export default observer(Login);
