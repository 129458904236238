import {getRequest} from "../../../../common/helpers/RequestHelper";

class PaymentSuccessService {
    apiTransactionDetail = "api/v1/pos/ecommerce/order/order_detail";

    public fetchTransactionDetail(transactionPosId:any): Promise<any>{
        return getRequest(`${this.apiTransactionDetail}/${transactionPosId}`);
    }

}

export const paymentSuccessService = new PaymentSuccessService();
