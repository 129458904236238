import React, { useEffect } from "react";
import { Modal, Input, Select, Button } from "antd";
import "./styles/TransferTable.css";
import { observer } from "mobx-react";
import { transferTableStore } from "./TransferTableStore";
import { useNavigate, useParams } from "react-router-dom";
import { showDelete } from "../../../../../../shared/components/showDelete";

const TransferTable = observer(() => {
    const { diningTableId, postId } = useParams<{ diningTableId: string; postId: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        if (diningTableId) {
            transferTableStore.setDiningTableId(diningTableId);
            transferTableStore.fetchInfotable();
        }
        if (postId) {
            transferTableStore.setPosId(postId);
        }
    }, [diningTableId]);

    const handleTransfer = () => {
        if (transferTableStore.selectedTableId) {
            showDelete.showDeleteConfirm(
                "Bạn chắc chắn muốn chuyển sang bàn này?",
                "Hành động này không thể hoàn tác.",
                async () => {
                    await transferTableStore.switchTable();
                    navigate(`/pos/${postId}/san-pham/${transferTableStore.selectedTableId}`);
                }
            );
        }
    };

    return (
        <Modal
            title={
                <div className="header d-flex align-items-center justify-content-between">
                    Chuyển bàn
                </div>
            }
            open={transferTableStore.isModalOpen}
            onCancel={() => transferTableStore.closeModal()}
            footer={[
                <Button key="cancel" onClick={() => transferTableStore.closeModal()} className="cancel-button">
                    Huỷ bỏ
                </Button>,
                <Button key="transfer" type="primary" onClick={handleTransfer} className="transfer-button">
                    Chuyển bàn
                </Button>
            ]}
            closeIcon={
                <img
                    src="/assets/icon/icon_closeWhite.svg"
                    width={25}
                    height={25}
                    alt="Close"
                    onClick={() => transferTableStore.closeModal()}
                />
            }
            className="transfer-table-modal"
            wrapClassName="TransferTable"
        >
            <div className="line"></div>
            <div className="transfer-table-content">
                <div className="transfer-table-content__input">
                    <div className="transfer-title">Bàn hiện tại</div>
                    <Input
                        value={transferTableStore.infoTable?.name || ""}
                        className="custom-input"
                        disabled
                    />
                    <div className="transfer-title">Chuyển bàn</div>
                    <Select
                        className="custom-select"
                        placeholder="Vui lòng chọn"
                        onChange={(value) => transferTableStore.setSelectedTableId(value)}
                    >
                        {transferTableStore.listAllDiningTables.map((table) => (
                            <Select.Option key={table.id} value={table.id}>
                                {table.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="line2"></div>
        </Modal>
    );
});

export default TransferTable;
