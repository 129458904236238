import { Modal, Form as AntdForm, Input } from "antd";
import { observer } from "mobx-react";
import UploadFile from "../../../../shared/components/form/UploadFile";
import { brandStore } from "../BrandStore";
import { handleFile } from "../../../../common/helpers/UploadFileHelper";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import Loading from "../../../../shared/components/loading/Loading";
import { createSearchParams, useNavigate } from "react-router-dom";
import { toastUtils } from "../../../../common/utils/Toastutils";
import { numberByDateNow } from "../../../../common/utils/Utils";

const BrandModal = () => {
    const { TextArea } = Input;
    const validBrandSchema = yup.object().shape({
        name: yup.string().required("Vui lòng nhập Tên thương hiệu"),
    });
    const isLoadingBtn = brandStore.isLoadingBtn;
    console.log("check", brandStore.isModal);
    return (
        <Modal
            className="modal-form"
            title={brandStore.handlerName}
            footer={null}
            open={brandStore.openModal}
            onCancel={() => {
                brandStore.openModal = false;
            }}
        >
            {brandStore.isLoadingModal === true ? (
                <div className="loading__container">
                    <Loading />
                </div>
            ) : (
                <>
                    <UploadFile
                        text={`Hình ảnh`}
                        description={`Hình ảnh thương hiệu`}
                        file={
                            brandStore.isModal
                                ? brandStore.staticDataBrand.image
                                : brandStore.initialValues.image
                        }
                        onIconRemove={brandStore.handleCloseIcon}
                        onFileChange={(e) =>
                            handleFile(e, brandStore.changeFileIcon)
                        }
                        onChooseImg={(url: string)=>{
                            brandStore.isModal
                            ? brandStore.staticDataBrand.image = url
                            : brandStore.initialValues.image = url
                            // brandStore.staticDataBrand.image = url
                        }}
                        code={numberByDateNow()}
                        // onResetState={()=>{ productStore.staticDataProduct.preview = ""}}
                    />
                    <Formik
                        initialValues={
                            brandStore.isModal
                                ? brandStore.staticDataBrand
                                : brandStore.initialValues
                        }
                        enableReinitialize={true}
                        validationSchema={validBrandSchema}
                        onSubmit={(values, { resetForm }) => {
                            brandStore.initialValues.name = values.name.trim();
                            brandStore.initialValues.description =
                                values.description;
                            brandStore.staticDataBrand.name =
                                values.name.trim();
                            brandStore.staticDataBrand.description =
                                values.description;
                            if (
                                brandStore.dataListBrand.some(
                                    (item: any) =>
                                        item.name === values.name.trim()
                                )
                            ) {
                                toastUtils.warning(
                                    "Tên thương hiệu đã tồn tại vui lòng nhập tên khác",
                                    ""
                                );
                                return;
                            }
                            brandStore.isModal
                                ? brandStore
                                      .fetchAddBrand()
                                      .then(() => {
                                          resetForm();
                                      })
                                      .catch((err) => console.log("error", err))
                                : brandStore.fetchUpdateBrand();
                            console.log(brandStore.isLoadingBtn);
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <div className="item-form">
                                    <label htmlFor="brand_name">
                                        Thương hiệu <span>*</span>
                                    </label>
                                    <Field
                                        as={Input}
                                        name="name"
                                        placeholder="Vui lòng nhập"
                                        maxLength={150}
                                        onInput={(e: any) => {
                                            if (e.target.value.length > 149) {
                                                toastUtils.warning(
                                                    "Tên thương hiệu quá dài, tối đa 150 ký tự",
                                                    ""
                                                );
                                            }
                                        }}
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="span"
                                    />
                                </div>
                                <div className="item-form">
                                    <label htmlFor="description">Mô tả</label>
                                    <Field
                                        as="textarea"
                                        id="description"
                                        name="description"
                                        placeholder="Vui lòng nhập"
                                    />
                                </div>

                                <div className="item-form item-selected">
                                    <label htmlFor="brand_status">
                                        Nổi bật
                                    </label>
                                    <SelectStatus
                                        selected={() =>
                                            brandStore.isModal
                                                ? brandStore.staticDataBrand
                                                      .featured == "1"
                                                    ? "Hoạt động"
                                                    : "Vô hiệu hoá"
                                                : brandStore.initialValues
                                                      .featured == "1"
                                                ? "Hoạt động"
                                                : "Vô hiệu hóa"
                                        }
                                        onChange={(value: any) => {
                                            brandStore.isModal
                                                ? (brandStore.staticDataBrand.featured =
                                                      value)
                                                : (brandStore.initialValues.featured =
                                                      value);
                                        }}
                                    />
                                </div>

                                <div className="item-form item-selected">
                                    <label htmlFor="brand_status">
                                        Trạng thái
                                    </label>
                                    <SelectStatus
                                        selected={() =>
                                            brandStore.isModal
                                                ? brandStore.staticDataBrand
                                                      .status == "1"
                                                    ? "Hoạt động"
                                                    : "Vô hiệu hoá"
                                                : brandStore.initialValues
                                                      .status == "1"
                                                ? "Hoạt động"
                                                : "Vô hiệu hóa"
                                        }
                                        onChange={(value: any) => {
                                            brandStore.isModal
                                                ? (brandStore.staticDataBrand.status =
                                                      value)
                                                : (brandStore.initialValues.status =
                                                      value);
                                        }}
                                    />
                                </div>

                                <div className="btnCreateModal">
                                    <button
                                        className="cancelModal"
                                        type="reset"
                                        onClick={() => {
                                            brandStore.openModal = false;
                                        }}
                                    >
                                        Đóng
                                    </button>
                                    <button
                                        className="submitForm"
                                        type="submit"
                                        disabled={brandStore.isLoadingBtn}
                                    >
                                        {brandStore.isLoadingBtn
                                            ? "Vui lòng đợi..."
                                            : "Lưu"}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Modal>
    );
};

export default observer(BrandModal);
