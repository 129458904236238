import {makeAutoObservable} from "mobx";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../common/utils/Toastutils";
import {filesService} from "./FilesService";
import {showDelete} from "../../../shared/components/showDelete";
import { uploadImageStore } from "../../../shared/components/uploadImage/UploadImageStore";


class FilesStore {
    isLoading: boolean = false
    isAllowLoad : boolean = false
    preview: string = ''
    listMedia: any[] = [];
    selectedFileInTable: any[] = [];
    selectedFiles: string[] = [];


    metadata: { total: number, page: number, total_page: number, size: number, rootPath: string } = {
        total: 0,
        page: 0,
        total_page: 0,
        size: 0,
        rootPath: ''
    }

    staticParamFiles: {
        path: string,
        page: number,
        search: string,
        per_page: number
    } = {
        path: '',
        page: 0,
        search: "",
        per_page: 20
    }

    staticDataFiles: {
        name: string,
        path: string,
        page: number,
        search: string|null,
        directory: string,
        per_page: number,
        file: any,
        oldName: string,
        newName: string,
        newFolderName: string
    } = {
        name: "",
        path: '',
        page: 0,
        search: "",
        directory: "",
        per_page: 20,
        file: [],
        oldName: "",
        newName: "",
        newFolderName: ""
    }

    initializeParams() {
        const query = new URLSearchParams(window.location.search);
        this.staticParamFiles.search = query.get("search")
            ? String(query.get("search"))
            : "";
    }

    constructor() {
        makeAutoObservable(this)
        this.initializeParams();
    }

    changeFile = async (pathFile: any) => {
        const path = pathFile;
    };


    async fetchMediaList(run?: boolean) {
        if (this.listMedia.length < 1 || run) {
            this.isLoading = true;
        }
        this.isAllowLoad = true
        let { path, page, search } = this.staticDataFiles;

        const paramFiles = {
            path: path,
            page: page + 1,
            search: search,
        }

        const result = await filesService.fetchMediaList(paramFiles);
        if (result.status === HttpStatusCode.Ok) {
            if (search && search.length > 0) {
                this.listMedia = Object.values(result.body);
            } else {
                this.listMedia = result.body.data;
            }
            this.metadata = result.body.metadata;
            uploadImageStore.highLightPath = result.body.metadata.rootPath.split("/").pop() as string
            this.isLoading = false;
            this.isAllowLoad = false

        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    async addFolder() {
        let { path, newFolderName } = this.staticDataFiles;
        let {rootPath} = this.metadata;
        const addFolderss = {
            directory: path !== '' ?  path + '/' + newFolderName : rootPath + '/' + newFolderName,
        }
        const result = await filesService.addFolder(addFolderss);
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Thêm thư mục thành công", "");
            this.fetchMediaList();
        }else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    async changeNameImage() {
        let {oldName, newName, directory} = this.staticDataFiles;
        const changeName = {
            oldName: oldName,
            newName: newName,
            directory: directory
        }
        let numberIndex:number = 0
        this.listMedia.filter((item:any,index:number) =>{
            if (item.name === directory +'/'+ oldName){
                numberIndex = index
            }
        })
        const result = await filesService.changeNameImage(changeName);
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Đổi tên file thành công", "");
            this.listMedia = this.listMedia.map((item:any,index:number) => {
                if(index === numberIndex){
                    item.name = result.body.path
                }
                return item
            })
            // this.fetchMediaList();
        }else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }



    async deleteFile() {
        let {directory} = this.staticDataFiles;
        let remove = {
            directory:  directory
        }
        const result = await filesService.deleteFile(remove);
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Xóa file thành công", "");
            this.listMedia = this.listMedia.filter((item) => item.name !== directory);
            if(this.listMedia.length === 0){
                this.fetchMediaList();
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    handleCheckboxChange(name: string, checked: boolean) {
        this.selectedFiles = checked
            ? [...this.selectedFiles, name]
            : this.selectedFiles.filter(file => file !== name);
    }

    async deleteFileImage() {
        if (this.selectedFiles.length === 0) {
            toastUtils.error("Vui lòng chọn trước khi xóa", "");
            return;
        }

        const removeFile = { files: this.selectedFiles };

        const result = await filesService.deleteFileImage(removeFile);
        if (result.status === 200) {
            toastUtils.success("Xóa ảnh thành công", "");
            this.listMedia = this.listMedia.filter(item => !this.selectedFiles.includes(item.name));
            this.selectedFiles = [];
            if (this.listMedia.length === 0) {
                this.fetchMediaList();
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }


    confirmAndDeleteSelectedFiles() {
        if (this.selectedFiles.length === 0) {
            toastUtils.error("Vui lòng chọn trước khi xóa", "");
            return;
        }
        showDelete.showDeleteConfirm(
            "Bạn chắc chắn muốn xoá các ảnh đã chọn?",
            "Hành động này không thể hoàn tác.",
            () => this.deleteFileImage()
        );
    }



}

export const filesStore = new FilesStore()