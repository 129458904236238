import { HttpStatusCode } from "axios";
import { makeAutoObservable, toJS } from "mobx";
import { toastUtils } from "../../../../../../common/utils/Toastutils";
import { transferTableService } from "./TransferTableService";
import StorageService from "../../../../../../common/service/StorageService";


class TransferTableStore {
    listAllDiningTables: { id: string; name: string }[] = [];
    diningTableId = "";
    posId = "";
    isModalOpen = false;
    infoTable: { name?: string } = {};
    selectedTableId: string | null = null;

    staticDatatable: {
        tableId: string;
        locationId: string;
        posId: string
        dining_table_session: string;
    } = {
        tableId: "",
        locationId:"",
        posId: "",
        dining_table_session: ""

    };

    constructor() {
        makeAutoObservable(this);
    }

    async fetchInfotable() {
        const result = await transferTableService.getInfoTable(this.staticDatatable.tableId);
        if (result.status === HttpStatusCode.Ok) {
            this.infoTable = result.body;
            const diningSession = result.body?.dining_table_session;
            if (diningSession) {
                this.staticDatatable.dining_table_session = diningSession.dining_tables_session || "";
                this.staticDatatable.locationId = result.body.location_id?.toString() || "";
            }
            await Promise.all([
                this.fetchAllDiningTablesList(true)
            ])
        }
    }

    async fetchAllDiningTablesList(forceUpdate = false) {
        if (this.listAllDiningTables.length > 0 && !forceUpdate) return;

        const location_id = this.staticDatatable.locationId;
        const pos_id = this.staticDatatable.posId;

        const result = await transferTableService.fetchListTable({
            current_table_id: this.diningTableId,
            location_id,
            pos_id
        });

        if (result.status === HttpStatusCode.Ok) {
            this.listAllDiningTables = result.body;
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }



    async switchTable() {
        if (!this.selectedTableId) return;

        const pos_session_id = this.staticDatatable.dining_table_session;

        const result = await transferTableService.switchTable({
            dining_table_session: pos_session_id,
            dining_new: this.selectedTableId
        });

        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Chuyển bàn thành công!", "");
            this.closeModal();
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }

    setDiningTableId(id: string) {
        this.diningTableId = id;
        this.staticDatatable.tableId = id;
    }

    setSelectedTableId(id: string) {
        this.selectedTableId = id;
    }

    setPosId(id: string) {
        this.posId = id;
        this.staticDatatable.posId = id;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    openModal() {
        const diningTableSession = StorageService.getLocalStore("dining_table_session");

        if (diningTableSession && diningTableSession !== "undefined") {
            this.isModalOpen = true;
        } else {
            toastUtils.error("Vui lòng thêm món hoặc đặt bàn!", "");
            this.isModalOpen = false;
        }
    }
}

export const transferTableStore = new TransferTableStore();


