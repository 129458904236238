import React, { useEffect, useState } from "react";
import "./App.css";
import Redirect from "./routing/routers";
import Header from "./shared/layout/header/Header";
import { ToastContainer } from "react-toastify";
import Login from "./pages/login/Login";
import StorageService from "./common/service/StorageService";
import { useLocation, useNavigate } from "react-router-dom";
import {checkPermission} from "./common/utils/Utils";
import Constants, {pathNotResetPro, permissionCode} from "./common/constants/Constants";
import {posStore} from "./pages/settings/pos/PosStore";
import DashBoardPage from "./pages/dashboard/DashBoardPage";
import ForgotPass from "./pages/forgotPass/ForgotPass";

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const accessToken = StorageService.getToken();

    useEffect(() => {
        if (!accessToken && location.pathname !== "/dang-nhap" && location.pathname !== "/quen-mat-khau") {
            navigate("/dang-nhap");
        }
        if (accessToken && location.pathname === "/dang-nhap") {
            navigate("/");
        }
    }, [accessToken, location.pathname,StorageService.getArrayFromLS(Constants.ROLE)]);


    useEffect(() => {
        const shouldNotReset =
        pathNotResetPro.some((pattern) => pattern.test(location.pathname));

        if (shouldNotReset) {
          return;
        }else{
            StorageService.saveArayLS("arrCatePro", [])
            StorageService.saveArayLS("arrBrandPro", [])
        }
      }, [location]);

    return (
        <>
            {accessToken ? (
                <div className="root">
                    <Header />
                    <div className="wrapper">
                        <Redirect />
                    </div>
                    {/*<Footer/>*/}
                </div>
            ) : (
                <div className="root">
                     {location.pathname === "/quen-mat-khau" ? (
                        <ForgotPass />
                    ) : (
                        // Nếu không, hiển thị trang đăng nhập
                        <Login />
                    )}
                </div>
            )}

            <ToastContainer />
        </>
    );
};
export default App;
