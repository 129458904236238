import {HttpStatusCode} from "axios";
import {salePointService} from "./SalePointService";
import {toastUtils} from "../../common/utils/Toastutils";
import {makeAutoObservable, toJS} from "mobx";


class SalePointStore {
    listPos: any[] = [];
    listLocation: any[] = [];
    loadingDataAll: boolean = true;
    isModalMoneyControl: boolean = false;
    isModalCurrencyType: boolean = false;
    isModalCash: boolean = false;
    isLoading = false;
    listMoney: { bank_amount: number; cash_amount: number; total: number } | null = null;

    constructor() {
        makeAutoObservable(this);
    }


    //Cash
    setIsModalCash = (isOpen: boolean) => {
        this.isModalCash = isOpen;
    }
    showModalCash = () => {
        this.setIsModalCash(true);
    }

    handleCashClose = () => {
        this.setIsModalCash(false);
    }

    // Money Control
    setIsModalOpen = (isOpen: boolean) => {
        this.isModalMoneyControl = isOpen;
    };
    showModal = () => {
        this.setIsModalOpen(true);
    };
    handleClose = () => {
        this.setIsModalOpen(false);
    };

    // Currency Type
    setIsModalCurrencyOpen = (isOpen: boolean) => {
        this.isModalCurrencyType = isOpen;
    };
    showModalCurrency = () => {
        this.setIsModalCurrencyOpen(true);
    };
    handleCurrencyOK = () => {
        this.setIsModalCurrencyOpen(false);

    };
    handleCurrencyClose = () => {
        this.setIsModalCurrencyOpen(false);
    };

    staticDataCloseMoney: {
        pos_id: string,
        price_pos_cash: string,
        price_pos_bank: string,
        note_pos_close: ({ name: string; quantity: string } | string)[];
    } = {
        pos_id: '',
        price_pos_cash: '',
        price_pos_bank: '',
        note_pos_close: [],
    }

    staticDataMoney: {
        pos_id: string;
        price_pos: string;
        meta_note_start: { name: string; quantity: string }[];
        note_start: string;
    } = {
        pos_id: '',
        price_pos: '',
        meta_note_start: [],
        note_start: '',
    }

    staticDataPos:{
        id: string,
        name: string,
        store_id: string,
        location_id: number,
        meta_start_amount: string
    } = {
        id: '',
        name: '',
        store_id: '',
        location_id: 1,
        meta_start_amount: ''
    }

    metadata: { total: number, page: number, total_page: number, size: number } = {
        total: 0,
        page: 0,
        total_page: 0,
        size: 0
    }

    async getLocation(run?:boolean){
        if (this.listLocation.length < 1 || run) {
            const result = await salePointService.fetchLocation();
            if (result.status === HttpStatusCode.Ok) {
                this.listLocation = result.body.data;
            } else {
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
        }
    }

    async getMachinePosByUser(run?: boolean) {
        if (this.listPos.length < 1 || run) {
            this.isLoading = true;
        }

        const { store_id } = this.staticDataPos;
        const result = await salePointService.fetchMachinePosByUser(store_id); // Truyền store_id vào request

        if (result.status === HttpStatusCode.Ok) {
            this.listPos = result.body.data;
            this.metadata = result.body.metadata;
            this.loadingDataAll = false;
        } else {
            // toastUtils.error('Đã có lỗi xảy ra!', '');
        }
        this.isLoading = false;
    }

    async getTotalMoneyPos() {
        const { pos_id } = this.staticDataCloseMoney;
        const result = await salePointService.fetchTotalMoneyPos(pos_id);

        if (result.status === HttpStatusCode.Ok) {
            this.listMoney = result.body;
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }


    async addCash(form:any) {
        let {pos_id, price_pos, meta_note_start, note_start} = this.staticDataMoney;
        const addCash = {
            pos_id: pos_id,
            price_pos: price_pos,
            meta_note_start: meta_note_start,
            note_start: note_start
        }
        const result = await salePointService.fetchCashAdding(addCash);

        if (result.status === HttpStatusCode.Ok) {
            const updateDataTagCash = this.listPos.map(
                (item: any) =>
                    item.id === result.body.id
                        ? {
                            ...item,
                            is_working: result.body.is_working,
                            meta_end_amount: result.body.meta_end_amount,
                            meta_start_amount: result.body.meta_start_amount,
                            pos_session_id: result.body.pos_session_id,
                            start_working: result.body.start_working,
                            user: result.body.user
                        }
                        : item
            );
            this.listPos = updateDataTagCash;
            toastUtils.success('Mở phiên thành công !', '');
            this.handleClose();
            this.staticDataMoney = {
                pos_id: '',
                price_pos: '',
                meta_note_start:[],
                note_start: ''
            };
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }
    async closeCash(form:any) {
        let{pos_id, price_pos_cash, price_pos_bank, note_pos_close} = this.staticDataCloseMoney;
        const closeCash = {
            pos_id: pos_id,
            price_pos_cash: price_pos_cash,
            price_pos_bank: price_pos_bank,
            note_pos_close: note_pos_close
        }
        const result = await salePointService.fetchCashClosing(closeCash);
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success('Đóng máy tính tiền thành công!', '');
            this.handleCashClose();
            this.getMachinePosByUser();
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }

}

export const salePointStore = new SalePointStore();