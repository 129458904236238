import { HttpStatusCode } from "axios";
import { makeAutoObservable } from "mobx";
import { toastUtils } from "../../../../../../common/utils/Toastutils";
import { combineTableService } from "./CombineTableService";
import StorageService from "../../../../../../common/service/StorageService";

class CombineTableStore {
    listAllDiningTables: { id: string; name: string }[] = [];
    diningTableId = "";
    selectedTableId: string | null = null;
    isModalOpen = false;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchAllDiningTablesList(run = false) {
        const location_id = StorageService.getLocalStore("storeID");
        const pos_id = StorageService.getLocalStore("posID");

        const paramListTable = {
            current_table_id: this.diningTableId,
            location_id,
            pos_id,
        };

        if (this.listAllDiningTables.length < 1 || run) {
            const result = await combineTableService.fetchListTable(paramListTable);
            if (result.status === HttpStatusCode.Ok) {
                this.listAllDiningTables = result.body;
            } else {
                toastUtils.error("Đã có lỗi xảy ra!", "");
            }
        }
    }

    setDiningTableId(id: string) {
        this.diningTableId = id;
    }

    setSelectedTableId(id: string) {
        this.selectedTableId = id;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    openModal() {
        this.isModalOpen = true;
    }
}

export const combineTableStore = new CombineTableStore();
