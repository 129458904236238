import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { productStore } from "../../ProductStore";
import { observer } from "mobx-react";
import { toastUtils } from "../../../../../common/utils/Toastutils";
import { Link } from "react-router-dom";

const ModalImportFile = () => {
    const acceptedType = ["xlsx", "xls", "xlsm", "xlsb", "csv", "xml"];

    return (
        <>
            <Modal
                className="modalImportFile"
                title="Nhập sản phẩm"
                open={productStore.isModalImportFileOpen}
                afterClose={productStore.closeModalImportFile}
                onCancel={productStore.closeModalImportFile}
                centered
                footer={null}
            >
                <div className="importFile-title">
                    <span>Tải file lên</span>
                </div>

                <div className="importFile-input">
                    {productStore.isLoadingProcessImportFile ? (
                        <>
                            <Spin size="large" />
                            <p className="title mb-0">
                                Đang import dữ liệu, vui lòng đợi...
                            </p>
                        </>
                    ) : (
                        <>
                            <img
                                src="/assets/icon/export-file.svg"
                                alt="sorting"
                            />
                            <span
                                style={{
                                    color: "#6c757d",
                                    fontSize: "14px",
                                }}
                            >
                                Chọn file excel tại đây
                            </span>
                            <span
                                style={{
                                    color: "#6c757d",
                                    fontSize: "14px",
                                }}
                            >
                                Kích cỡ tối đa: 10.0 MB - Định dạng: xlsx
                            </span>
                            <button>
                                Chọn tập tin
                                <input
                                    style={{
                                        cursor: "pointer",
                                        height: "100%",
                                        top: "0px",
                                        left: "0px",
                                        opacity: "0",
                                        position: "absolute",
                                        width: "100%",
                                    }}
                                    onChange={(e) => {
                                        if (e.target.files?.[0]) {
                                            const name =
                                                e.target.files?.[0].name.replace(
                                                    /^.*?\./,
                                                    ""
                                                );
                                            if (!acceptedType.includes(name)) {
                                                toastUtils.warning(
                                                    "Chỉ chọn được file định dạng excel",
                                                    ""
                                                );
                                                return;
                                            } else if (
                                                e.target.files[0].size >
                                                1 * 1000 * 1024
                                            ) {
                                                toastUtils.warning(
                                                    "Chỉ chọn được file tối đa 10mb trở xuống",
                                                    ""
                                                );
                                            }
                                        }
                                        productStore.fetchImportFile(e);
                                        productStore.setIsLoadingProcessImportFile(
                                            true
                                        );
                                        productStore.startFileImportProcess();
                                        e.target.value = "";
                                    }}
                                    type="file"
                                    accept="xlsx, xls, xlsm, xlsb, csv, xml"
                                />
                            </button>
                        </>
                    )}
                </div>

                {productStore.errorFileDataLog[0]?.log !== null ? (
                    <div
                        className="importFile-errorLog"
                        style={{
                            display:
                                productStore.errorFileDataLog.length === 0
                                    ? "none"
                                    : "block",
                        }}
                    >
                        <p className="name mb-0">
                            <strong>Tên file:</strong>{" "}
                            {productStore.errorFileDataLog[0]?.file_name}
                        </p>
                        <span>{productStore.errorFileDataLog[0]?.log}</span>
                    </div>
                ) : (
                    ""
                )}

                <div className="importFile-table">
                    <span className="table-header">Đã lưu</span>
                    <span className="table-title">
                        Danh sách file lỗi sau mỗi lần cập nhật dữ liệu. Vui
                        lòng tải file về và cập nhật lại
                    </span>
                    <div className="table-body">
                        <div className="content-table">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    maxWidth: "139px",
                                                }}
                                            >
                                                Ngày
                                            </th>
                                            <th
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                Tên file
                                            </th>
                                            {/* <th
                                                style={{
                                                    width: "10%"
                                                }}
                                            >
                                                Lỗi/ Tổng số dòng
                                            </th> */}
                                            <th
                                                style={{
                                                    width: "5%",
                                                }}
                                            >
                                                Lỗi/Tổng số dòng
                                            </th>
                                            <th
                                                style={{
                                                    width: "5%",
                                                }}
                                            >
                                                Hoàn thành
                                            </th>
                                            <th
                                                style={{
                                                    width: "25%",
                                                }}
                                            >
                                                Tải file
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productStore.dataListImportFile
                                            ?.filter(
                                                (item: any) => item.log === null
                                            )
                                            ?.map(
                                                (item: any, index: number) => {
                                                    return (
                                                        <>
                                                            <tr key={index}>
                                                                <td>
                                                                    {new Date(
                                                                        item.created_at
                                                                    ).toLocaleDateString(
                                                                        "en-GB"
                                                                    )}
                                                                    {" - "}
                                                                    {new Date(
                                                                        item.created_at
                                                                    ).toLocaleTimeString(
                                                                        "en-GB"
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.file_name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.error_row
                                                                    }
                                                                    /
                                                                    {
                                                                        item.total_row
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {new Date(
                                                                        item.end_time
                                                                    ).toLocaleTimeString(
                                                                        "vi-VN"
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {/* <a
                                                                        hreft={
                                                                            item.full_path
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        {
                                                                            item.full_path
                                                                        }
                                                                    </a> */}
                                                                    <Link
                                                                        to={
                                                                            item.full_path
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();
                                                                            window.open(
                                                                                `${item.full_path}`,
                                                                                "_blank"
                                                                            );
                                                                        }}
                                                                    >
                                                                        Tải về
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                                }
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default observer(ModalImportFile);
