import {getRequest, postRequest, putRequest} from "../../../../common/helpers/RequestHelper";

class SeparationService {
    apiGet = "api/v1/pos/fnb/get_cart_items";
    apiSub = "api/v1/pos/fnb/split_order";
    apiGenerateQr = "api/v1/payment/pos/generateQr"
    apiSaveOrder = "api/v1/pos/fnb/checkout"


    public fetchCartItems(dining_table_session: string): Promise<any> {
        const apiGetWithCart = `${this.apiGet}?dining_table_session=${dining_table_session}`;
        return getRequest(apiGetWithCart);
    }
    public splitOrder(data: any): Promise<any> {
        return putRequest(this.apiSub, data);
    }
    public fetchGenerateQr(params:any): Promise<any>{
        return getRequest(this.apiGenerateQr,params);
    }
    public fetchCheckoutPayment(params:any): Promise<any>{
        return postRequest(this.apiSaveOrder,params);
    }
}

export const separationService = new SeparationService();
