import React, { useEffect, useState } from "react";
import HeaderProductInPos from "./components/headerProductInPos/HeaderProductInPos";
import "./styles/ProductInPosPage.scss";
import ProductInPosControl from "./components/productInPosControl/ProductInPosControl";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { productInPostStore } from "./ProductInPostStore";
import { utils } from "../../../common/utils/Utils";
import Loading from "../../../shared/components/loading/Loading";
import ModalDetailProduct from "./components/modal/modalDetailProduct/ModalDetailProduct";
import { productInPosCartStore } from "./ProductInPosCartStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { categoryProductStore } from "../../products/categoryProduct/CategoryProductStore";
import ReactPaginate from "react-paginate";
import NoContent from "../../../shared/components/NoContent";
import StorageService from "../../../common/service/StorageService";
import TransferTable from "./components/modal/transferTable/TransferTable";
const ProductInPosPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const {diningTableId} = useParams();
  productInPostStore.idCurrentTable = diningTableId

  useEffect(() => {
    productInPostStore.getDataInfoTable();
  },[diningTableId])

  useEffect(() => {
    productInPostStore.dataFilterProduct.category_id = query.get("category_id")
      ? Number(query.get("category_id"))
      : "";
      query.get("type") === "parent" && (categoryProductStore.staticParamCategoryProduct.parent_id = query.get("category_id"));
      query.get("type") === "child" && (categoryProductStore.dataCategoryPro = StorageService.getArrayFromLS("arrProPos")) // chọn danh mục cha có những danh mục con nào thì lưu
  }, []);

  useEffect(() => {
    if (
      !productInPostStore.isLoadingDetailPos &&
      !productInPostStore.dataPosMachine?.is_working
    ) {
      navigate("/danh-sach-pos");
    }
  }, [
    productInPostStore.dataPosMachine,
    productInPostStore.isLoadingDetailPos,
  ]);

  useEffect(() => {
    query.get("type") !== "child" && categoryProductStore.fetchCategoryProductParent();
  }, []);

  useEffect(() => {
    if (categoryProductStore.staticParamCategoryProduct.parent_id &&  query.get("type") !== "child") {
      categoryProductStore.fetchListCategoryProduct(true);
    }
  }, [categoryProductStore.staticParamCategoryProduct.parent_id]);

  const updateUrlParams = (item: any) => {
    const searchParams = new URLSearchParams(location.search);

    if (productInPostStore.dataFilterProduct.category_id) {
      searchParams.set(
        "category_id",
        productInPostStore.dataFilterProduct.category_id
      );
      item?.category_id
        ? searchParams.set("type", "child")
        : searchParams.set("type", "parent");
    }

    searchParams.set("page", "1");
    productInPostStore.staticParam.page = 0;
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    productInPostStore.dataFilterProduct.category_id = query.get("category_id")
      ? Number(query.get("category_id"))
      : "";
    productInPostStore.staticParam.page = query.get("page")
      ? Number(query.get("page")) - 1
      : 0;
    productInPostStore.dataFilterProduct.search = query.get("search") || "";
    productInPostStore.getListProduct();
  }, [location.search]);

  const handleChangePage = (e: any) => {
    productInPostStore.staticParam.page = e.selected;
    const searchParams = new URLSearchParams(location.search);

    searchParams.set(
      "page",
      (productInPostStore.staticParam.page + 1).toString()
    );
    navigate({
      search: searchParams.toString(),
    });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="ProductInPosPage-container">
      <HeaderProductInPos />
      <div className="ProductInPosPage-body">
        <div className="ProductInPosPage-left">
          <div className={`list-category ${categoryProductStore.isLoading ? "disable" : ""}`}>
            <div className="category_body">
              <button
                className={`${
                  !productInPostStore.dataFilterProduct.category_id
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  navigate({ search: "" }, { replace: true });
                  categoryProductStore.fetchCategoryProductParent();
                }}
              >
                Tất Cả
              </button>
              {
             
              categoryProductStore.dataCategoryPro.length > 0 &&
                categoryProductStore.dataCategoryPro.map((item) => (
                  <div
                    key={item.id}
                    title={item.name}
                    className={`category_item ${
                      productInPostStore.dataFilterProduct.category_id ===
                      item.id
                        ? "active"
                        : ""
                    } `}
                    onClick={() => {
                      productInPostStore.dataFilterProduct.category_id =
                        item.id;
                      categoryProductStore.staticParamCategoryProduct.parent_id =
                        !item.category_id ? item.id : "";
                      updateUrlParams(item);
                    }}
                  >
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
          <>
            <div className="listProduct">
              {productInPostStore.isLoadingProduct ? (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <Loading />
                </div>
              ) : productInPostStore.dataProduct.length <= 0 ? (
                <NoContent />
              ) : (
                <div className="listProduct-box">
                  {productInPostStore.dataProduct.length > 0 &&
                    productInPostStore.dataProduct.map((item, index) => (
                      <div
                        key={index}
                        className="product-item"
                        onClick={() => {
                          productInPosCartStore.isOpenModalDetailProduct = true;
                          productInPosCartStore.dataDetailProduct = item;
                        }}
                      >
                        <div className="product-item__boxImg">
                          <img src={item.preview.value} alt="img" />
                        </div>
                        <div className="product-item__body">
                          <h2 className="multiline-ellipsis" title={item.title}>
                            {item.title}
                          </h2>
                          <h3>{utils.formatCurrency(item.price_origin)}</h3>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </>
          <div className="pagination-ProInPos">
            {productInPostStore.metadata.total_page > 1 && (
              <ReactPaginate
                previousLabel={
                  <img
                    src="/assets/icon/fi-rr-angle-small-left.svg"
                    alt="right"
                  />
                }
                nextLabel={
                  <img
                    src="/assets/icon/fi-rr-angle-small-right.svg"
                    alt="right"
                  />
                }
                breakLabel={"..."}
                pageCount={productInPostStore.metadata.total_page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                containerClassName={"pagination"}
                activeClassName={"active"}
                onPageChange={handleChangePage}
                forcePage={productInPostStore.staticParam.page}
              />
            )}
          </div>
        </div>
        <ProductInPosControl />
      </div>
      <ModalDetailProduct></ModalDetailProduct>
    </div>
  );
};

export default observer(ProductInPosPage);
