import React, { useState } from "react";
import "./HeaderSeparation.scss";
import { Popover } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import CloseMoneyControl from "../../../../productInPos/components/modal/closeMoneyControl/CloseMoneyControl";
import {salePointStore} from "../../../../SalePointStore";

const HeaderSeparation = ({search = true}: {search ?: boolean}) => {
    const [isCloseMoneyControlVisible, setIsCloseMoneyControlVisible] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const { postId } = useParams<{ postId: string }>();



    const content = (
        <div className="header-boxSeparation">
            <p>
                <Link to={`/pos/chi-tiet-don-hang/${postId}`} style={{ textDecoration: "none", color: "#061020" }}>
                    Đơn hàng
                </Link>
            </p>

            <p>
                <Link to={`/pos/${postId}/cong-no`} style={{ textDecoration: "none", color: "#061020" }}>
                    Công nợ
                </Link>
            </p>
            <p
                onClick={() => {
                    setIsCloseMoneyControlVisible(true);
                    salePointStore.showModalCash();
                }}
                style={{ cursor: "pointer", color: "#061020" }}
            >
                Đóng máy tính tiền
            </p>
        </div>
    );

    return (
        <div className="HeaderSeparation">
            <div className="header-container">
                <div className="header-boxLeft">
                    <h1>POS</h1>
                </div>
                <div className="header-boxRight">
                    <Popover
                        placement="bottomRight"
                        title={""}
                        arrow={false}
                        content={content}
                        trigger="click"
                    >
                        <button className="header-bars">
                            <i className="fas fa-bars"></i>
                        </button>
                    </Popover>
                </div>
            </div>
            {isCloseMoneyControlVisible && <CloseMoneyControl posId={postId ?? ""} />}
        </div>

    );
};

export default observer(HeaderSeparation);
