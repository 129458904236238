import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import HeaderSeparation from "./components/headerSeparation/HeaderSeparation";
import "./styles/separation.css";
import { Button } from "antd";
import { DoubleLeftOutlined, RightOutlined } from "@ant-design/icons";
import { separationStore } from "./SeparationStore";
import { toastUtils } from "../../../../common/utils/Toastutils";
import { useNavigate, useParams } from "react-router-dom";
import { utils } from "../../../../common/utils/Utils";

const SeparationPage = () => {
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [localPaymentPrice, setLocalPaymentPrice] = useState(0);
    const navigate = useNavigate();
    const { diningTableSession, postId} = useParams<{ diningTableSession: string, postId: string }>();

    useEffect(() => {
        if (diningTableSession) {
            separationStore.staticDataTableSession.dining_tables_session = diningTableSession;
            separationStore.fetchCartItems();
        }
        if (postId) {
            separationStore.staticDataTableSession.pos_id = postId;
        }
    }, [diningTableSession]);



    const toggleSelectItem = (index: number) => {
        setSelectedItems((prevSelected) => {
            const updatedSelected = prevSelected.includes(index)
                ? prevSelected.filter(i => i !== index)
                : [...prevSelected, index];
            const selectedRowIds = updatedSelected.map(i => separationStore.listCartItems[i].rowId);
            separationStore.staticDataTableSession.row_ids = selectedRowIds;
            const newTotalPrice = calculateTotalPrice(updatedSelected);
            separationStore.staticDataPayment.payment_price = newTotalPrice;
            separationStore.remainingPayment = newTotalPrice;
            separationStore.priceTotalPayment = newTotalPrice;
            setLocalPaymentPrice(newTotalPrice);
            return updatedSelected;
        });
    };

    const calculateTotalPrice = (items = selectedItems) => {
        const total = items.reduce((total, index) => {
            const item: { qty: number; price: number } = separationStore.listCartItems[index];
            return total + (item.qty * item.price);
        }, 0);

        return total;
    };

    const handlePayment = () => {
        if (selectedItems.length === 0) {
            toastUtils.error('Vui lòng chọn sản phẩm cần thanh toán!', '');
            return;
        }
        separationStore.splitOrder(separationStore.staticDataTableSession)
            .then((splitOrderSession: string) => {
                separationStore.staticDataTableSession.split_order_session = splitOrderSession;
                separationStore.savePaymentPriceToLocalStorage();
                setLocalPaymentPrice(0);
                setSelectedItems([]);
                navigate(`/pos/${postId}/thanh-toan/tach-don/${splitOrderSession}`);
            })
            .catch(error => {
                toastUtils.error('Thanh toán thất bại!', error.message || '');
            });
    };

    return (
        <div className="separation-container">
            <HeaderSeparation />
            <div className="separation-body">
                <div className="separation-title">
                    <div className="button-return">
                        <Button type="text">
                            <DoubleLeftOutlined />
                            <p>Quay lại</p>
                        </Button>
                    </div>
                    <div className="title-page">
                        Tách hoá đơn
                    </div>
                </div>
                <div className="separation-payment">
                    <div className="left-content">
                        {separationStore.listCartItems.map((item, index) => (
                            item.tmp_order_status !== 2 && (
                                <div
                                    className={`item ${selectedItems.includes(index) ? 'selected' : ''}`}
                                    key={index}
                                    onClick={() => toggleSelectItem(index)}
                                >
                                    <div className="item-details">
                                        <div className="item-name">
                                            {item.product_name}
                                            {item.options && JSON.parse(item.options).attribute_compare ?
                                                ` - ${JSON.parse(item.options).attribute_compare}` : ''}
                                        </div>
                                        <div className="item-desc">
                                            {item.qty} {JSON.parse(item.options).unit} x
                                            {item.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} đ/ {JSON.parse(item.options).unit}
                                        </div>
                                    </div>
                                    <div className="item-price">
                                        {(item.qty * item.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' đ'}
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                    <div className="right-content">
                        <div className="priceTotal">
                            {utils.formatCurrency(localPaymentPrice)}
                        </div>
                        <div className="pay">
                            <Button type="text" onClick={handlePayment}>
                                <RightOutlined />
                                <p>Thanh toán</p>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(SeparationPage);
