export default class Constants {
    public static TOKEN_EXPIRE_DAYS: number = 16;
    public static pageSize: number = 10;
    public static maxSize: number = 1000;
    public static TOKEN_NAME: string = "x-ompos-token";
    public static REFRESH_TOKEN: string = "x-ompos-refreshToken";
    public static ROLE: string = "x-ompos-role";
}

export class TransactionType {
    public static PUBLIC_MONEY_POS: number = 1; // Giao dịch pos không nợ tiền
    public static DEBT_POS: number = 2; // Giao dịch pos nợ tiền
    public static CUSTOMER_PAY_DEBT: number = 3; // Giao dịch công nợ
}
export const permissionCode = {
    dashboard: "dashboard",             //--------------Quyền dashboard----------------//
    products: "products",               //--------------Quyền quản lý sản phẩm----------------//
    features: "features",               //--------------Quyền quản lý nhãn----------------//
    attributes: "attributes",           //--------------Quyền quản lý thuộc tính----------------//
    tags: "tags",                       //--------------Quyền quản lý thẻ tags sản phẩm----------------//
    brands: "brands",                   //--------------Quyền quản lý thương hiệu----------------//
    categories: "categories",           //--------------Quyền quản lý danh mục----------------//
    sales: "sales",                     //--------------Quyền quản lý mã giảm giá----------------//
    users: "users",                     //--------------Quyền quản lý quản trị viên----------------//
    order: "order",                     //--------------Quyền quản lý đơn hàng----------------//
    payment: "payment",                 //--------------Quyền quản lý thanh toán----------------//
    permission: "permission",           //--------------Quản lý quyền----------------//
    pos: "pos",                         //--------------Quản lý modules Pos----------------//
    machine_pos: "machine_pos",         //--------------Quản lý máy Pos----------------//
    locations: "locations",             //--------------Quản lý chi nhánh----------------//
    admins: "admins",             //--------------Quản lý quản trị viên----------------//
    files: "files",             //--------------Quản lý quản trị viên----------------//
}


export const pathNotResetPro = [
    /^\/hang-hoa\/san-pham$/,
    /^\/hang-hoa\/san-pham\/\d+\/thong-tin-chung$/,
    /^\/hang-hoa\/san-pham\/\d+\/gia$/,
    /^\/hang-hoa\/san-pham\/\d+\/hinh-anh$/,
    /^\/hang-hoa\/san-pham\/\d+\/tao-ma-qrcode$/,
    /^\/hang-hoa\/san-pham\/\d+\/tao-ma-barcode$/,
];