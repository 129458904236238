import React, {useEffect, useRef} from "react";
import { observer } from "mobx-react";
import "./styles/success.css";
import {Link, useNavigate, useParams} from "react-router-dom";
import {paymentSuccessStore} from "./PaymentSuccessStore";
import {ThreeDot} from "react-loading-indicators";

import {useReactToPrint} from "react-to-print";
import { utils } from "../../../../common/utils/Utils";
import Loading from "../../../../shared/components/loading/Loading";
import StorageService from "../../../../common/service/StorageService";
import {separationStore} from "../separation/SeparationStore";
const PaymentSeparationSuccessPage = () => {
    const navigate = useNavigate();
    const { transactionPosId } = useParams<{ transactionPosId: string }>();
    useEffect(() => {
        paymentSuccessStore.transactionPosId = transactionPosId
        paymentSuccessStore.fetchTransactionDetail((route) => navigate(route))
    }, [transactionPosId]);
    const componentRef = useRef<any>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <div className="success-page">
                <div className="session1">
                    <h1>POS</h1>
                </div>
                <div className="session2">
                    {paymentSuccessStore.isLoading === true ? (
                        <p><ThreeDot
                            variant="bob"
                            color="#6D727C"
                            size="small"
                            textColor=""
                        /></p>
                    ) : (
                        paymentSuccessStore.dataTransaction?.refund_transaction_pos ? (
                            <>
                                <p>{utils.formatCurrency(Math.abs(paymentSuccessStore.dataTransaction?.amount_total ? paymentSuccessStore.dataTransaction.amount_total : 0))}</p>
                            </>
                        ) : (
                            <>
                                <p>{utils.formatCurrency(paymentSuccessStore.dataTransaction?.amount_total ? paymentSuccessStore.dataTransaction.amount_total : 0)}</p>
                            </>
                        )

                    )}
                </div>
                <div className="container-pos">
                <div className="session3">
                        <div className="leftSession3">
                            <div className="content-payment-print print-payment"  ref={componentRef}>
                                {paymentSuccessStore.isLoading === true ? (
                                        <>
                                            <div style={{display:"grid",alignItems:"center",height:"100%"}}>
                                                <Loading/>
                                            </div>
                                        </>
                                    ): (
                                    <>
                                        <p className="nameStore">{paymentSuccessStore.dataTransaction?.location_store_name}</p>
                                        {paymentSuccessStore.dataTransaction?.store && (
                                            <p className="addressStore">
                                                {paymentSuccessStore.dataTransaction?.store?.address}, {paymentSuccessStore.dataTransaction?.store?.ward.name}, {paymentSuccessStore.dataTransaction?.store?.district.name}, {paymentSuccessStore.dataTransaction?.store?.provice.name}
                                            </p>
                                        )}
                                        {paymentSuccessStore.dataTransaction?.store?.phone_number && (
                                            <p className="hotlineStore">{paymentSuccessStore.dataTransaction?.store?.phone_number}</p>
                                        )}
                                        <p className="nameStore2">
                                            Chào mừng quý khách đến với {paymentSuccessStore.dataTransaction?.location_store_name}
                                        </p>
                                        <div className="time-quay">
                                            <div className="leftTest">
                                                <p className="time">{new Date(
                                                    paymentSuccessStore.dataTransaction?.created_at
                                                ).toLocaleString(
                                                    "en-GB"
                                                )}</p>
                                                <p className="hd">Số HĐ: {paymentSuccessStore.dataTransaction?.pos_session_name}</p>
                                            </div>
                                            <div className="rightTest">
                                                <p>Quầy thu ngân: 00{paymentSuccessStore.dataTransaction?.pos_id}</p>
                                                <p>Mã nhân viên: {paymentSuccessStore.dataTransaction?.user_info?.id}</p>
                                            </div>
                                        </div>
                                        <p className="titleHd">
                                            {paymentSuccessStore.dataTransaction?.refund_transaction_pos !== null ? '** Hoá đơn hoàn tiền **' : '** Hoá đơn bán lẻ **'}
                                        </p>

                                        <div className="headerProduct">
                                            <p className="dongia">Đơn giá</p>
                                            <p className="sl">SL</p>
                                            <p className="ck">CK</p>
                                            <p className="priceTotal">T.Tiền</p>
                                        </div>
                                        <div className="listProduct">
                                            {paymentSuccessStore.dataTransaction?.transaction_pos_item && (
                                                paymentSuccessStore.dataTransaction?.transaction_pos_item.map((item:any) => (
                                                    <div className="item">
                                                        <p className="nameProduct">
                                                            {item.product_info?.name} {item.product_info?.attribute_compare ? ' - '+ item.product_info?.attribute_compare : ''}
                                                        </p>
                                                        <div className="inline">
                                                            <p className="text_dongia">
                                                                {item.amount_edit > 0 ? (
                                                                    utils.formatCurrency(item?.amount_edit ? item.amount_edit : 0)
                                                                ) : (
                                                                    utils.formatCurrency(item?.amount ? item.amount : 0)
                                                                )}

                                                            </p>
                                                            <p className="text_sl">{item.qty}</p>
                                                            <p className="text_ck">
                                                                {item.discount > 0 && item.discount + '%'}
                                                            </p>
                                                            <p className="text_priceTotal">
                                                                {item.amount_edit > 0 ? (
                                                                    utils.formatCurrency((item.amount_edit * parseInt(item.qty)) - (item.amount_edit * parseInt(item.qty) * item.discount/100))
                                                                ) : (
                                                                    utils.formatCurrency((item.amount * parseInt(item.qty)) - (item.amount * parseInt(item.qty) * item.discount/100))
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))
                                            )}

                                        </div>
                                        <div className="contentTotalPrice">
                                            <div className="inline inline1">
                                                <p className="textLeft">Tổng tiền</p>
                                                {paymentSuccessStore.dataTransaction?.refund_transaction_pos ? (
                                                    <>
                                                        <p className="textRight">{utils.formatCurrency(Math.abs(paymentSuccessStore.dataTransaction?.amount_total ? paymentSuccessStore.dataTransaction.amount_total : 0))}</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className="textRight">{utils.formatCurrency(paymentSuccessStore.dataTransaction?.amount_total ? paymentSuccessStore.dataTransaction.amount_total : 0)}</p>
                                                    </>
                                                )}

                                            </div>
                                            <div className="inline inline2">
                                                <p className="textLeft"> {paymentSuccessStore.dataTransaction?.refund_transaction_pos !== null ? 'TỔNG TIỀN TRẢ LẠI' : 'TỔNG TIỀN THANH TOÁN'}</p>
                                                {paymentSuccessStore.dataTransaction?.refund_transaction_pos ? (
                                                    <>
                                                        <p className="textRight">{utils.formatCurrency(Math.abs(paymentSuccessStore.dataTransaction?.cash_amount + paymentSuccessStore.dataTransaction?.bank_amount))}</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className="textRight">{utils.formatCurrency(paymentSuccessStore.dataTransaction?.cash_amount + paymentSuccessStore.dataTransaction?.bank_amount)}</p>
                                                    </>
                                                )}

                                            </div>
                                            {paymentSuccessStore.dataTransaction?.cash_amount > 0 && (
                                                <div className="inline inline3">
                                                    <p className="textLeft">TIỀN MẶT</p>
                                                    <p className="textRight">{utils.formatCurrency( paymentSuccessStore.dataTransaction?.cash_amount ? paymentSuccessStore.dataTransaction.cash_amount : 0)}</p>
                                                </div>
                                            )}
                                            {paymentSuccessStore.dataTransaction?.bank_amount > 0 && (
                                                <div className="inline inline3">
                                                    <p className="textLeft">CHUYỂN KHOẢN</p>
                                                    <p className="textRight">{utils.formatCurrency( paymentSuccessStore.dataTransaction?.bank_amount ? paymentSuccessStore.dataTransaction.bank_amount : 0)}</p>
                                                </div>
                                            )}
                                            {paymentSuccessStore.dataTransaction?.refund_transaction_pos !== null ? (
                                                <></>
                                            ) : (
                                                <div className="inline inline4">
                                                    <p className="textLeft">TIỀN TRẢ LẠI</p>
                                                    <p className="textRight">
                                                        {(paymentSuccessStore.dataTransaction?.cash_amount + paymentSuccessStore.dataTransaction?.bank_amount) > paymentSuccessStore.dataTransaction?.amount_total ? (
                                                            utils.formatCurrency((paymentSuccessStore.dataTransaction?.cash_amount + paymentSuccessStore.dataTransaction?.bank_amount) - paymentSuccessStore.dataTransaction?.amount_total)
                                                        ) : (
                                                            utils.formatCurrency(0)
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                            <p className="text">(Giá đã bao gồm thuế GTGT)</p>
                                        </div>
                                        <div className="contenFooterPrint1">
                                            <p className="text1">Chỉ xuất hoá đơn trong ngày</p>
                                            <p className="text2">Tax invoice will be issued within same day</p>
                                        </div>
                                        <div className="contenFooterPrint2">
                                            <p className="text1">CẢM ƠN QUÝ KHÁCH VÀ HẸN GẶP LẠI</p>
                                            <div className="inline">
                                                {paymentSuccessStore.dataTransaction?.store?.phone_number && (
                                                    <p className="textLeft">Hotline: {paymentSuccessStore.dataTransaction?.store?.phone_number}</p>
                                                )}
                                                <p className="textRight">
                                                    {StorageService.getLocalStore('domainName')}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                    )}

                            </div>
                        </div>
                        <div className="rightSession3">
                            <h2>Thanh toán thành công</h2>
                                {paymentSuccessStore.isLoading === false && (
                                    <div className="print">
                                        <button onClick={handlePrint}>
                                            <span>
                                                <img src="/assets/icon/print.svg" alt="print"/>
                                               In hóa đơn
                                            </span>
                                        </button>
                                    </div>
                                )}
                            <div className="footerRight">
                                <Link to={`/pos/${separationStore.staticDataTableSession.pos_id}/tach-don/${separationStore.staticDataTableSession.dining_tables_session}`}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40"
                                             fill="none">
                                            <path d="M15.5 30L25.5 20L15.5 10" stroke="white" stroke-width="5"
                                                  stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        Đơn hàng mới
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default observer(PaymentSeparationSuccessPage)