import { Form, Input, Modal } from "antd";
import { spacesFloorRoomTableStore } from "./SpacesFloorRoomTableStore";
import SelectCustom from "../../../shared/components/form/SelectCustom";
import { observer } from "mobx-react";
import { toastUtils } from "../../../common/utils/Toastutils";
import { useEffect } from "react";

const CreateFloorRoomModal = () => {
    const [form] = Form.useForm();
    useEffect(() => {
        spacesFloorRoomTableStore.getLocation();
    }, []);
    const onFinish = (value: any) => {
        spacesFloorRoomTableStore.staticParamsFloorRoomTable.store_id =
            spacesFloorRoomTableStore.storeID;
        spacesFloorRoomTableStore
            .createFloorRoom()
            .then(() => form.resetFields());
    };

    const storeID = spacesFloorRoomTableStore.listAllLocation.find(
        (item: any, index: number) =>
            item.id.toString() === spacesFloorRoomTableStore.storeID.toString()
    )?.name;

    return (
        <>
            <Modal
                className="modal-form"
                footer={null}
                title="Thêm mới tầng/phòng"
                open={spacesFloorRoomTableStore.isModalFloorRoomCreate}
                onOk={spacesFloorRoomTableStore.handleOK}
                onCancel={() => {
                    spacesFloorRoomTableStore.handleCancel();
                }}
                afterClose={() => {
                    spacesFloorRoomTableStore.handleClose();
                }}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={
                        spacesFloorRoomTableStore.staticParamsFloorRoomTable
                    }
                >
                    <div className="item-form">
                        <label className="label-form">
                            Tên tầng/phòng <span>*</span>
                        </label>

                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Không được để trống tầng/phòng",
                                },
                                {
                                    max: 150,
                                    message:
                                        "Tên tầng/phòng quá dài, tối đa 150 kí tự",
                                },
                                {
                                    validator: (_, value) => {
                                        if (value && !value.trim()) {
                                            return Promise.reject(
                                                new Error(
                                                    "Tên tầng/phòng không được chỉ có khoảng trắng"
                                                )
                                            );
                                        }
                                        const specialCharPattern = /[#!@$%^&*]/;
                                        if (specialCharPattern.test(value)) {
                                            return Promise.reject(
                                                new Error(
                                                    "Tên tầng/phòng không được chứa ký tự đặc biệt như: #!@$%^&*"
                                                )
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                placeholder="Nhập tên tầng/phòng"
                                type="text"
                                value={
                                    spacesFloorRoomTableStore
                                        .staticParamsFloorRoomTable.name
                                }
                                maxLength={150}
                                onChange={(e) => {
                                    if (e.target.value.length > 149) {
                                        toastUtils.warning(
                                            "Tên tầng/phòng quá dài, tối đa 150 ký tự",
                                            ""
                                        );
                                        return;
                                    }
                                    spacesFloorRoomTableStore.staticParamsFloorRoomTable.name =
                                        e.target.value.trim();
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label className="label-form">Thứ tự hiển thị</label>

                        <Form.Item
                            name="ordering"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Không được để trống thứ tự hiển thị",
                                },
                                {
                                    validator: (_, value) => {
                                        if (!value) {
                                            return Promise.resolve();
                                        }
                                        if (
                                            /^\d+$/.test(value) &&
                                            parseInt(value, 10) > 0
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "Chỉ được nhập số nguyên dương"
                                            )
                                        );
                                    },
                                },
                            ]}
                        >
                            <Input
                                placeholder="Nhập thứ tự"
                                type="number"
                                value={
                                    spacesFloorRoomTableStore
                                        .staticParamsFloorRoomTable.ordering
                                }
                                onChange={(e: any) =>
                                    (spacesFloorRoomTableStore.staticParamsFloorRoomTable.ordering =
                                        e.target.value)
                                }
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label className="label form">Địa điểm</label>

                        {/* <SelectCustom
                            list={spacesFloorRoomTableStore.listAllLocation}
                            placeholder={`Chọn địa điểm`}
                            onChange={(value: any) =>
                                console.log("check value", value)
                            }
                            selected={() => {
                                spacesFloorRoomTableStore.staticParamsFloorRoomTable.store_id =
                                    spacesFloorRoomTableStore.listAllFloors[0]?.location_id;
                                return spacesFloorRoomTableStore.listAllLocation.find(
                                    (item: any) =>
                                        item.id.toString() ===
                                        spacesFloorRoomTableStore
                                            .listAllFloors[0]?.id
                                )?.id;
                            }}
                            getLabel={(item: any) => item.name}
                        /> */}
                        {storeID}
                    </div>

                    <div className="item-form">
                        <label className="label-form">Danh mục cha</label>

                        <SelectCustom
                            list={spacesFloorRoomTableStore.listAllFloors}
                            placeholder="Trống"
                            labelFirst="Trống"
                            onChange={(e: any) => {
                                spacesFloorRoomTableStore.staticParamsFloorRoomTable.parent_id =
                                    e;
                            }}
                            selected={() => {}}
                            getLabel={(item: any) => item.name}
                        />
                    </div>

                    <div className="btnCreateModal">
                        <button className="cancelModal" type="button">
                            Đóng
                        </button>
                        <button
                            className="submitForm"
                            type="submit"
                            disabled={spacesFloorRoomTableStore.isLoadingBtn}
                        >
                            {spacesFloorRoomTableStore.isLoadingBtn
                                ? "Vui lòng đợi..."
                                : "Lưu"}
                        </button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default observer(CreateFloorRoomModal);
