import { makeAutoObservable, toJS } from "mobx";
import axios, { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import { productService } from "./ProductService";
import { dashboardService } from "../../dashboard/DashboardService";
import StorageService from "../../../common/service/StorageService";

class ProductStore {
    isSave: boolean = false;
    selectedItem: any = null;
    isModalImportFileOpen = false;
    isModalLoadingImportFileOpen = false;
    isModalOpen = false;
    isModalOpenCreateVariant = false;
    isLoadingListProduct: boolean = false;
    isLoadingStore: boolean = false;
    isLoadingProcessImportFile: boolean = false;
    isLoadingGenerateAttributes: boolean = false;
    errorFileDataLog: any[] = [];
    dataListImportFile: any[] = [];
    dataListStore: any[] = [];
    listFeature: any[] = [];
    listCategory: any[] = [];
    listBrand: any[] = [];
    listTags: any[] = [];
    listProduct: any[] = [];
    dataProductGeneral: any;
    dataProductImage: any;
    dataProductQrcode: any;
    dataProductBarcode: any[] = [];
    dataProductListVariant: any[] = [];
    variantIdBarcode: number|undefined;
    dataProduct: any = {
        data: [],
        product_type: 0,
    };
    lisAttribute: any[] = [];
    arrayQtyStore: any[] = []; // Tồn kho cửa hàng của sản phẩm đơn giản
    listGenerateAttributes: any[] = [];
    arrayAttributeProduct: any[] = []; // Data map đổ ra font-end
    fileImportInterval: NodeJS.Timeout | null = null;

    heightQrcode : number = 100;
    widthQrcode : number = 100;
    quantityQrcode : any = 1;
    quantityBarcode : any = 1;
    errCode: boolean = false
    metadata: any = {
        total: "",
        page: 0,
        total_page: "",
        size: "",
    };
    initialValues: {
        name: string;
        description: string;
        parent_id: string;
        allow_order: number;
        store: any;
    } = {
        name: "",
        description: "",
        parent_id: "",
        allow_order: 0,
        store: [],
    };

    staticDataAddVariant: {
        attribute_ids: any;
        height: number | undefined;
        length: number | undefined;
        weight: number | undefined;
        width: number | undefined;
        sku: string;
        unit: string;
        thumbnail: string;
        status: number | undefined;
        allow_inventory: number | undefined;
        locked: string;
        price: string ;
        price_original: string ;
        qty:any
    } = {
        attribute_ids: [],
        height: undefined,
        length: undefined,
        weight: undefined,
        width: undefined,
        sku: '',
        unit: '',
        thumbnail: '',
        status: undefined,
        allow_inventory: undefined,
        locked: '',
        price: '',
        price_original: '',
        qty:[]
    };

    staticUpdateImage: {
        preview: string;
        galleries: string[];

    } = {
        preview: '',
        galleries: [],
    };

    staticFileData: {
        file: any;
        objectName: string;
        last: string;
    } = {
        file: "",
        objectName: "product",
        last: "",
    };

    staticQrcode: {
        width: string;
        height: string;

    } = {
        width: '200',
        height: '200',
    };

    staticDataGenerate: {
        title: string;
        slug: string;
        brand_id: string;
        status: string;
        price_origin: string;
        price_compare_origin: string;
        sku_origin: string;
        weight_origin: number;
        unit: string;
        show_price: string;
    } = {
        title: "",
        slug: "",
        brand_id: "",
        status: "",
        price_origin: "",
        price_compare_origin: "",
        sku_origin: "",
        weight_origin: 0,
        unit: "",
        show_price: "",
    };

    constructor() {
        // this.initializeParams();
        makeAutoObservable(this);
    }
    staticDataGenerateAttributes: { attribute_ids: any } = {
        attribute_ids: [],
    };
    staticDataProduct: {
        preview: string;
        multi_images: any;
        name: string;
        short_description: string;
        status: string;
        show_price: string;
        featured: string;
        categories: [];
        brand: string;
        tags: string;
        price_origin: string;
        price_compare_origin: string;
        sku_origin: string;
        weight_origin: string | null;
        unit: string;
        height_origin: string | null;
        length_origin: string | null;
        width_origin: string | null;
        product_type: string;
        allow_inventory: string;
        product_attribute_sku: any;
        attributeParent: any;
        qty: any;
        long_description: string;
    } = {
        preview: "",
        multi_images: [],
        name: "",
        short_description: "",
        status: "1",
        show_price: "1",
        featured: "",
        categories: [],
        brand: "",
        tags: "",
        price_origin: "0",
        price_compare_origin: "0",
        sku_origin: "",
        weight_origin: '0',
        unit: "",
        height_origin: '0',
        length_origin: '0',
        width_origin: '0',
        product_type: "0",
        allow_inventory: "0",
        product_attribute_sku: [],
        attributeParent: [],
        qty: [],
        long_description: "",
    };
    staticDataListProduct: {
        brand_id: any[];
        category_id: any[];
        offset: number | null;
        order_column: string;
        order_value: string;
        page_number: number;
        per_page: number;
        search: string;
        status: string;
        tag_id: number | null;
    } = {
        brand_id: [],
        category_id: [],
        offset: null,
        order_column: "created_at",
        order_value: "DESC",
        page_number: 0,
        per_page: 10,
        search: "",
        status: "",
        tag_id: null,
    };

    staticDataDetailProduct: { id: string; request_type: string } = {
        id: "",
        request_type: "",
    };

     linkFile: string = ""

    setIsModalLoadingImportFileOpen = (isOpen: boolean) => {
        this.isModalLoadingImportFileOpen = isOpen;
    };
    setIsModalImportFileOpen = (isOpen: boolean) => {
        this.isModalImportFileOpen = isOpen;
    };
    setIsLoadingProcessImportFile = (isOpen: boolean) => {
        this.isLoadingProcessImportFile = isOpen;
    };
    showModalLoadingImportFile = () => {
        this.setIsModalLoadingImportFileOpen(true);
    };
    closeModalLoadingImportFile = () => {
        this.setIsModalLoadingImportFileOpen(false);
    };
    showModalImportFile = () => {
        this.setIsModalImportFileOpen(true);
    };
    closeModalImportFile = () => {
        this.setIsModalImportFileOpen(false);
        this.errorFileDataLog = [];
    };
    closeLoadingProcessImportFile = () => {
        this.setIsLoadingProcessImportFile(false);
    };
    changeFilePreview = (pathFile: string) => {
        this.staticDataProduct.preview = pathFile;
    };
    changeFilePreviewAddVariant = (pathFile: string) => {
        this.staticDataAddVariant.thumbnail = pathFile;
    };
    changeFileVariant = (pathFile: string, ordering: number) => {
        this.listGenerateAttributes[ordering].thumbnail = pathFile;
    };
    changeFileVariantEdit = (pathFile: string, ordering: number) => {
        this.dataProductListVariant[ordering].thumbnail = pathFile;
    };
    changeFileEditProductPreview = (pathFile: string) => {
        productStore.staticUpdateImage.preview = pathFile;
    };
    handleCloseEditProductPreview = () => {
        productStore.staticUpdateImage.preview = "";
    };

    handleClosePreviewAddVariant = () => {
        this.staticDataAddVariant.thumbnail = "";
    };
    handleClosePreview = () => {
        this.staticDataProduct.preview = "";
    };
    changeFileMultiImages = (pathFile: any) => {
        this.staticDataProduct.multi_images = this.staticDataProduct.multi_images.concat(pathFile);
    };
    handleCloseMultiImages = (index: number) => {
        this.staticDataProduct.multi_images.splice(index, 1);
    };
    changeFileMultiEditProductImages = (pathFile: any) => {
        this.staticUpdateImage.galleries = this.staticUpdateImage.galleries.concat(pathFile)

    };
    handleCloseMultiEditProductImages = (index: number) => {
        this.staticUpdateImage.galleries.splice(index, 1);
    };


    initializeParams() {
        const query = new URLSearchParams(window.location.search); // Using window.location instead of useLocation()
        this.staticDataListProduct.page_number = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
        this.staticDataListProduct.search = query.get("keyword")
            ? String(query.get("keyword"))
            : "";
    }
    async fetchImportFile(e: React.ChangeEvent<HTMLInputElement>) {
        this.isLoadingStore = true;
        // this.showModalLoadingImportFile();
        const params = e.target.files?.[0];
        const FormData = require("form-data");
        let data = new FormData();
        data.append("file", params);
        const uploadResult = await axios.post(
            localStorage.getItem("domainName") + "api/v1/import/product",
            data,
            {
                headers: {
                    Authorization: `Bearer ${StorageService.getToken()}`,
                },
            }
        );
        this.isLoadingStore = false;
        this.closeModalLoadingImportFile();
        if (uploadResult.status === 200) {
            // toastUtils.success("Nhập sản phẩm thành công", "");
            this.fetchListProduct(true);
            // this.fetchGetDataImportFile();
        } else {
            toastUtils.error("Nhập sản phẩm bị lỗi", "");
        }
    }
    async fetchGetFileError(callback?: any) {
        // this.isLoadingProcessImportFile = true;
        // this.isLoadingStore = true;
        const { last } = this.staticFileData;
        const params = {
            last: last,
        };

        const result = await productService.fetchProcessFile(
            this.staticFileData.objectName,
            params
        );

        // this.isLoadingStore = false;
        if (result.status === HttpStatusCode.Ok) {
            this.dataListImportFile = result.body;
            // if (result.body[0]?.log === null) {
            //     this.stopFileImportProcess();
            //     this.closeModalLoadingImportFile();
            // }
            // // Kiểm tra nếu có end_time, gọi callback và dừng tiến trình
            // if (
            //     result.body[0]?.end_time !== null ||
            //     result.body[0]?.log !== null
            // ) {
            //     callback?.();
            //     this.stopFileImportProcess();
            //     this.closeModalLoadingImportFile();
            //     this.setIsLoadingProcessImportFile(false);
            //     this.setIsProcessImportFileError(true);
            // }
        }
    }
    async reLoadGetFileError() {
        const { last } = this.staticFileData;
        const params = {
            last: last,
        };
        const result = await productService.fetchProcessFile(
            this.staticFileData.objectName,
            params
        );
        if (result.status === HttpStatusCode.Ok) {
            this.dataListImportFile = result.body.filter(
                (item: any) => item.log === null
            );
        }
    }
    // Hàm chính để bắt đầu quá trình import file
    startFileImportProcess() {
        // Khởi tạo và gọi API lần đầu tiên
        this.fetchGetFileError(() => {
            console.log("Call api first time");
        });
        // Gọi lại API sau mỗi 3 giây cho đến khi có end_time
        this.fileImportInterval = setInterval(async () => {
            const params = {
                last: "1",
            };

            const result = await productService.fetchProcessFile(
                this.staticFileData.objectName,
                params
            );

            if (result.status === HttpStatusCode.Ok) {
                this.errorFileDataLog = result.body;
                console.log("check log", toJS(result.body));
                const updateDataFileImportError = this.dataListImportFile.map(
                    (item: any) =>
                        item.id.toString() === result.body[0]?.id.toString()
                            ? {
                                  ...item,
                                  log: result.body[0]?.log,
                                  total_row: result.body[0]?.total_row,
                                  error_row: result.body[0]?.error_row,
                                  file_name: result.body[0]?.file_name,
                                  end_time: result.body[0]?.end_time,
                              }
                            : item
                );
                this.dataListImportFile = updateDataFileImportError;

                if (result.body[0]?.log !== null) {
                    this.stopFileImportProcess();
                    this.closeModalLoadingImportFile();
                    this.setIsLoadingProcessImportFile(false);
                } 
                if (result.body[0]?.end_time !== null) {
                    this.stopFileImportProcess();
                    this.closeModalLoadingImportFile();
                    this.setIsLoadingProcessImportFile(false);
                }
            }
        }, 3000);
    }
    stopFileImportProcess() {
        // Kiểm tra xem fileImportInterval có khác null không trước khi gọi clearInterval
        if (this.fileImportInterval) {
            clearInterval(this.fileImportInterval);
            this.fileImportInterval = null; // Reset biến sau khi dừng
            this.staticFileData.last = "0";
            this.reLoadGetFileError();
        }
    }

    async exportFilePro(){
        this.isLoadingStore = true;
        const result = await productService.fetchExportFile()
        this.isLoadingStore = false;
        if (result.status === HttpStatusCode.Ok) {
            this.linkFile = result.body
        }else{
            toastUtils.error("Đã có lỗi xảy ra !");
        }
    }
    async fetchListProduct(run?:boolean) {
        let {
            brand_id,
            category_id,
            offset,
            order_column,
            order_value,
            page_number,
            per_page,
            search,
            status,
            tag_id,
        } = this.staticDataListProduct;
        const params = {
            brand_id: brand_id,
            category_id: category_id,
            offset: offset,
            order_column: order_column,
            order_value: order_value,
            page: page_number + 1,
            per_page: per_page,
            search: search,
            status: status,
            tag_id: tag_id,
        };
        if (this.listProduct.length < 1 || run){
            this.isLoadingListProduct = true;
        }
        const result = await productService.fetchListProduct(params);
        this.isLoadingListProduct = false;
        if (result.status === HttpStatusCode.Ok) {
            if(this.listProduct !== result.body.data){
                this.listProduct = result.body.data;
                this.metadata = result.body.metadata;
            }
        } else {
            toastUtils.error(result.body.message, "");
        }

    }
    async fetchStoreProduct(form: any) {
        if(this.isSave === false){
            this.isSave = true
            let {
                preview,
                multi_images,
                name,
                short_description,
                status,
                show_price,
                featured,
                categories,
                brand,
                tags,
                price_origin,
                price_compare_origin,
                sku_origin,
                weight_origin,
                unit,
                height_origin,
                length_origin,
                width_origin,
                product_type,
                allow_inventory,
                product_attribute_sku,
                qty,
                long_description,
            } = this.staticDataProduct;
            // console.log(this.staticDataProduct)
            const params = {
                preview: preview,
                multi_images: multi_images,
                name: name,
                short_description: short_description,
                status: status ? status : "1",
                allow_order: "1",
                locked: "1",
                show_price: show_price ? show_price : "1",
                featured: featured,
                categories: categories,
                brand: brand,
                tags: tags,
                price_origin: price_origin,
                price_compare_origin: price_compare_origin,
                sku_origin: sku_origin,
                weight_origin: weight_origin,
                unit: unit,
                height_origin: height_origin,
                length_origin: length_origin,
                width_origin: width_origin,
                product_type: product_type,
                product_attribute_sku: product_attribute_sku,
                allow_inventory: allow_inventory,
                qty: qty,
                attributeParent: this.arrayAttributeProduct.map(
                    (item: any) => item.id
                ),
                long_description: long_description,
            };
            this.isLoadingStore = true;
            try {
                const result = await productService.fetchStoreProduct(params);
                this.isSave = false
                if (result.status === HttpStatusCode.Ok) {
                    toastUtils.success("Thêm mới thành công", "");
                    form.resetFields();
                    this.arrayAttributeProduct = [];
                    this.staticDataGenerateAttributes.attribute_ids = [];
                    this.listGenerateAttributes = [];
                    this.staticDataProduct.product_type = "0";
                    this.staticDataProduct.status = '1'
                    this.staticDataProduct.show_price = '1'
                    this.staticDataProduct.brand = ''
                    this.staticDataProduct.featured = ''
                    this.staticDataProduct.preview = '';
                    this.staticDataProduct.multi_images = [];
                    this.listProduct = []
                    this.arrayQtyStore.map((item:any,index:number) => {
                        productStore.arrayQtyStore[index].qty = 0;
                    })
                } else {
                    this.handleErrors(result.body.message);
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {
                this.isLoadingStore = false;
            }
        }

    }
    async fetchUpdateGeneralProduct(id:any,values:any) {
        if(this.isSave === false){
            this.isSave = true;
            const params = {
                request_type: 'general',
                title: values.title,
                slug: values.slug,
                short_description: values.excerpt,
                long_description: this.dataProductGeneral.long_description,
                categories: this.dataProductGeneral.categories_news,
                brand: this.dataProductGeneral.brand_id,
                tags: this.dataProductGeneral.tags_news,
                feature_id: this.dataProductGeneral.feature_id,
                status: this.dataProductGeneral.status,
                show_price: this.dataProductGeneral.show_price,
            };
            this.isLoadingStore = true;
            try {
                const result = await productService.fetchUpdateProduct(id,params);
                this.isSave = false;
                if (result.status === HttpStatusCode.Ok) {
                    toastUtils.success("Cập nhật thành công", "");
                    this.listProduct = []
                } else {
                    this.handleErrors(result.body.message);
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {
                this.isLoadingStore = false;
            }
        }
    }
    async fetchUpdateVariantProduct(id:any) {
        console.log(productStore?.listGenerateAttributes)
        if(this.isSave === false){
            this.isSave = true;
            let params: any;
            if(this.dataProductListVariant.length === 0) {
                if(this.listGenerateAttributes.length === 0){
                    toastUtils.warning('Vui lòng thêm biến thể')
                    return false
                }
                const checkThumbnail = this?.listGenerateAttributes.find((item:any) => item.thumbnail === '')
                if(checkThumbnail){
                    toastUtils.warning(`Ảnh thuộc tính ${checkThumbnail.attribute_name} không được trống`, ``);
                    return false;
                }
                params = {
                    request_type: 'variant',
                    product_type: this.dataProduct.product_type,
                    attribute_parent: this.arrayAttributeProduct.map(
                        (item: any) => item.id
                    ),
                    sku_product_attribute: this?.listGenerateAttributes.map((item:any,index:number) => {
                            // if(!item.thumbnail){
                            //     toastUtils.warning(`Ảnh thuộc tính ${item.attribute_name} không được trống`, `${index}`);
                            //     return false;
                            // }
                            return {
                                'thumbnail' : item.thumbnail,
                                "sku":  item.sku_original,
                                'attributeIds': item.attribute_ids,
                                'weight' : item.weight_original ? item.weight_original : 0,
                                'width' : item.width_original,
                                'height' : item.height_original,
                                'length' : item.length_original,
                                'price' : item.price_original,
                                'price_compare' : item.price_compare_original,
                                'status' : item.status_attribute,
                                'stock_status' : item.allow_inventory_attribute,
                                'allow_inventory' : item.allow_inventory_attribute,
                                'allow_order' : '1', //cho phéo đặt hàng
                                'locked': '1', // hết hàng
                                'is_variant' : 1,
                                'unit' : 'Gói',
                                'qty' : item.qty_attribute,
                            }
                        })

                };
            }else{
                params = {
                    request_type: 'variant',
                    product_type: this.dataProduct.product_type,
                    sku_product_attribute: [
                        ...this.dataProductListVariant.map((item:any) => {
                            return {
                                "id": item.id, //sản phẩm có bao nhiêu thuộc tính thì điền id thuộc tính tương ứng
                                "attributeIds": item.attribute_ids, //sản phẩm có bao nhiêu thuộc tính thì điền id thuộc tính tương ứng
                                "thumbnail": item.thumbnail,
                                "price": item.price,
                                "price_compare": item.price_compare,
                                "weight": item.weight,
                                "is_variant": item.is_variant,
                                "unit": item.unit,
                                "height": item.height,
                                "width": item.width,
                                "length": item.length,
                                "sku":  item.sku,
                                "status":  item.status,
                                "allow_inventory":  item.allow_inventory,
                                "allow_order":  1,
                                "locked":  1,
                                "qty":  item.quantity,
                            }
                        }),
                    ]
                };
            }
            this.isLoadingStore = true;
            try {
                const result = await productService.fetchUpdateProduct(id,params);
                this.isSave = false;
                this.isLoadingStore = false;
                if (result.status === HttpStatusCode.Ok) {
                    this.listProduct = []
                    toastUtils.success("Cập nhật thành công", "");
                    this.dataProduct = result.body;
                    this.dataProduct.data = result.body.data.map((item:any) => ({
                        ...item,
                        quantity: this.dataListStore.map((itemStore: any) => {
                            var detailQty = item.inventories.filter((itemInventories: any) => itemInventories.store_id === itemStore.id)
                            return {
                                store_id: itemStore.id,
                                qty: detailQty[0] ? detailQty[0].quantity : 0,
                                name: itemStore.name,
                            };
                        }),
                    }))

                    this.setdataProductListVariant(this.dataProduct.product_type)
                } else {

                    this.handleErrors(result.body.message);
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {

            }
        }
    }
    async fetchUpdateImageProduct() {
        if(this.isSave === false){
            this.isSave = true;
            const params = {
                request_type: 'image',
                preview: this.staticUpdateImage.preview,
                galleries: this.staticUpdateImage.galleries,
            };
            try {
                const result = await productService.fetchUpdateProduct(parseInt(this.staticDataDetailProduct.id),params);
                this.isSave = false;
                if (result.status === HttpStatusCode.Ok) {
                    toastUtils.success("Cập nhật thành công", "");
                } else {
                    this.handleErrors(result.body.message);
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {

            }
        }
    }
    async fetchUpdateBarcodeProduct() {
        if(this.isSave === false){
            this.isSave = true;
            const params = {
                request_type: 'barcode',
                variant_id: this.variantIdBarcode,
            };
            try {
                const result = await productService.fetchUpdateProduct(parseInt(this.staticDataDetailProduct.id),params);
                this.isSave = false;
                if (result.status === HttpStatusCode.Ok) {
                    this.dataProductBarcode = this.dataProductBarcode.map((item:any) => {
                        if(item.id == this.variantIdBarcode){
                            item.barcode_img = result.body.barcode
                            item.barcode_code = result.body.code
                        }
                        return item
                    })
                    toastUtils.success("Cập nhật thành công", "");
                } else {
                    this.handleErrors(result.body.message);
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {

            }
        }
    }

    async fetchUpdateQrcodeProduct() {
        if(this.isSave === false){
            this.isSave = true;
            const params = {
                request_type: 'qrcode',
                height: this.staticQrcode.height,
                width: this.staticQrcode.width,
            };
            try {
                const result = await productService.fetchUpdateProduct(parseInt(this.staticDataDetailProduct.id),params);
                this.isSave = false;
                if (result.status === HttpStatusCode.Ok) {
                    toastUtils.success("Cập nhật thành công", "");
                    this.dataProductQrcode = result.body.code
                } else {
                    this.handleErrors(result.body.message);
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {

            }
        }

    }

    handleErrors(message: any) {
        if (message === "variant_sku_codes_are_overlapping") {
            toastUtils.error("Mã SKU đã tồn tại", "1");
        }else if(message === "the_product_type_field_is_required"){
            toastUtils.error("Chưa chọn loại sản phẩm", "0");
        }else if(message.sku_origin === "the_sku_origin_has_already_been_taken"){
            toastUtils.error("Mã SKU đã tồn tại", "1");
        }else if(message.sku_origin === "this_product_already_exists"){
            toastUtils.error("Tên sản phẩm đã tồn tại", "2");
        }else if(message === "This SKU already exists in the sku_product_attributes table" || message === "This SKU already exists in the sku_product_attributes table."){
            toastUtils.error("Mã SKU đã tồn tại", "1");
        }else if(message.name === "this_product_already_exists"){
            toastUtils.error("Sản phẩm này đã tồn tại", "1");
        }else if(message.sku){
            console.log(this.listGenerateAttributes)
            const checkSku = this.staticDataProduct.product_attribute_sku.find((itemVariant: any) => 'sku_'+ itemVariant.sku.toLowerCase() +'_already_exists' === message.sku);
            const checkSkuUpdate = this?.dataProductListVariant.find((itemVariant: any) => 'sku_'+ itemVariant.sku.toLowerCase() +'_already_exists' === message.sku);
            const checkSkuAdd = this?.listGenerateAttributes.find((itemVariant: any) => 'sku_'+ itemVariant.sku_original.toLowerCase() +'_already_exists' === message.sku);
            if('sku_'+ productStore.staticDataProduct.sku_origin.toLowerCase() +'_already_exists' === message.sku){
                toastUtils.error(`Mã sku ${productStore.staticDataProduct.sku_origin} đã tồn tại`, "1");
            }else if(checkSku){
                toastUtils.error(`Mã sku ${checkSku.sku} đã tồn tại`, "1");
            }else if(checkSkuUpdate){
                toastUtils.error(`Mã sku ${checkSkuUpdate.sku} đã tồn tại`, "1");
            }else if(checkSkuAdd){
                toastUtils.error(`Mã sku ${checkSkuAdd.sku_original } đã tồn tại`, "1");
            }else{
                toastUtils.error("Mã SKU đã tồn tại", "1");
            }
        }else{
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchListAttribute() {
        this.arrayAttributeProduct = []
        this.listGenerateAttributes = []
        this.staticDataGenerateAttributes.attribute_ids = []
        const result = await productService.fetchListAttribute();
        if (result.status === HttpStatusCode.Ok) {
            this.lisAttribute = result.body.data;
            if (this.lisAttribute.length > 0) {
                this.lisAttribute[0].options = [
                    ...this.lisAttribute.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    }),
                ];
                this.arrayAttributeProduct = [
                    ...this.arrayAttributeProduct,
                    { ...this.lisAttribute[0] },
                ];
            }
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchGenerateAttributes() {
        this.staticDataGenerateAttributes.attribute_ids = this.staticDataGenerateAttributes.attribute_ids.map((item:any) => item ? item : [])
        let { attribute_ids } = this.staticDataGenerateAttributes;
        if(attribute_ids.length > 0){
            const params = {
                attribute_ids: attribute_ids,
                price_original:
                    this.staticDataProduct.price_origin !== "null"
                        ? this.staticDataProduct.price_origin
                        : "0",
                price_compare_original:
                    this.staticDataProduct.price_compare_origin !== "null"
                        ? this.staticDataProduct.price_compare_origin
                        : "0",
                sku_origin: this.staticDataProduct.sku_origin,
                width_original: this.staticDataProduct.width_origin,
                height_original: this.staticDataProduct.height_origin,
                length_original: this.staticDataProduct.length_origin,
                weight_original: this.staticDataProduct.weight_origin,
            };
            this.isLoadingGenerateAttributes = true;
            const result = await productService.fetchGenerateAttributes(params);
            this.isLoadingGenerateAttributes = false;
            if (result.status === HttpStatusCode.Ok) {
                this.listGenerateAttributes = result.body.map((item: any) => ({
                    ...item,
                    status_attribute: "1",
                    allow_inventory_attribute: "1",
                    allow_order_attribute: "1",
                    qty_attribute: this.dataListStore.map((itemStore: any) => {
                        return {
                            store_id: itemStore.id,
                            qty: 0,
                            name: itemStore.name,
                        };
                    }),
                }));
            } else {
                if(result.body?.message?.attribute_ids === 'the_attribute_ids_field_is_required'){
                    toastUtils.warning("Vui lòng chọn thuộc tính", "");
                }else{
                    toastUtils.error("Đã có lỗi xảy ra", "");
                }

            }
        }
    }
    async fetchListFeature() {
        const result = await productService.fetchListFeature();
        if (result.status === HttpStatusCode.Ok) {
            this.listFeature = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchListCategory() {
        const result = await productService.fetchListCategory();
        if (result.status === HttpStatusCode.Ok) {
            this.listCategory = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchListBrand() {
        const result = await productService.fetchListBrand();
        if (result.status === HttpStatusCode.Ok) {
            this.listBrand = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchListTags() {
        const result = await productService.fetchListTags();
        if (result.status === HttpStatusCode.Ok) {
            this.listTags = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }

    async fetchDataStore() {
        try {
            if(this.dataListStore.length==0){
                const result = await dashboardService.fetchDataStore();
                this.dataListStore = result.body.data ?? [];
            }
            this.arrayQtyStore = this.dataListStore.map((item: any) => {
                return { store_id: item.id, qty: 0, name: item.name };
            });
            this.staticDataAddVariant.qty = this.dataListStore.map((item: any) => {
                return { store_id: item.id, qty: 0, name: item.name };
            });
        } catch (error) {
            console.error("Error fetching revenue data:", error); // Xử lý lỗi nếu có
        } finally {

        }
    }
    async fetchGetGeneralProduct() {
        let { id, request_type } = this.staticDataDetailProduct;
        const param = {
            request_type: request_type,
        };
        const result = await productService.fetchGetProduct(id, param);
        if (result.status === HttpStatusCode.Ok) {
            this.dataProductGeneral = {
                ...result.body.data,
                categories_news: result.body.data?.categories?.map((item: any) => item.id) || [],
                tags_news: result.body.data?.tags?.map((item: any) => item.id) || [],
                long_description: result.body.data?.description?.value || '',
            };
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchGetImageProduct() {
        if (this.staticUpdateImage.preview == '' || this.staticUpdateImage.galleries.length == 0){
            let { id, request_type } = this.staticDataDetailProduct;
            const param = {
                request_type: request_type,
            };
            const result = await productService.fetchGetProduct(id, param);
            if (result.status === HttpStatusCode.Ok) {
                this.dataProductImage = result.body;
                this.staticUpdateImage.preview = this.dataProductImage?.preview?.value;
                this.staticUpdateImage.galleries = this.dataProductImage.gallery?.value ? this.dataProductImage.gallery?.value : [];
            } else {
                toastUtils.error("Đã có lỗi xảy ra", "");
            }
        }
    }
    async fetchGetBarcodeProduct() {
        // if (this.staticUpdateImage.preview == '' || this.staticUpdateImage.galleries.length == 0){
        let { id, request_type } = this.staticDataDetailProduct;
        const param = {
            request_type: request_type,
        };
        const result = await productService.fetchGetProduct(id, param);
        if (result.status === HttpStatusCode.Ok) {
            this.dataProductBarcode = result.body.data.filter((item:any) => item.is_variant == result.body.product_type)
            this.variantIdBarcode = this.dataProductBarcode[0]?.id
            // console.log(this.dataProductBarcode)
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
        // }
    }
    async fetchGetQrcodeProduct() {
        let { id, request_type } = this.staticDataDetailProduct;
        const param = {
            request_type: request_type,
        };
        const result = await productService.fetchGetProduct(id, param);
        if (result.status === HttpStatusCode.Ok) {
            this.dataProductQrcode = result.body.data?.url ? result.body.data.url : '';
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchGetProduct() {
        let { id, request_type } = this.staticDataDetailProduct;
        const param = {
            request_type: request_type,
        };
        const result = await productService.fetchGetProduct(id, param);
        if (result.status === HttpStatusCode.Ok) {
            this.dataProduct = result.body;
            this.dataProduct.data = result.body.data.map((item:any) => ({
                ...item,
                quantity: this.dataListStore.map((itemStore: any) => {
                    var detailQty = item.inventories.filter((itemInventories: any) => itemInventories.store_id === itemStore.id)
                    return {
                        store_id: itemStore.id,
                        qty: detailQty[0] ? detailQty[0].quantity : 0,
                        name: itemStore.name,
                    };
                }),
            }))

            this.setdataProductListVariant(this.dataProduct.product_type)
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchCreateVariant(formCreateVariant:any) {
        if(this.isSave === false){
            this.isSave = true
            let { attribute_ids, height, length, weight, width, sku, unit, thumbnail, status, allow_inventory, locked, price, price_original, qty} =
                this.staticDataAddVariant;
            const params = {
                attribute_ids: attribute_ids,
                height: height,
                length: length,
                weight: weight,
                width: width,
                sku: sku,
                unit: unit,
                thumbnail: thumbnail,
                status: status,
                allow_inventory: allow_inventory,
                locked: locked,
                price: price,
                price_original: price_original,
                qty: qty,
            };

            try {
                const result = await productService.fetchCreateVariant(this.staticDataDetailProduct.id,params);
                this.isSave = false
                if (result.status === HttpStatusCode.Ok) {
                    toastUtils.success("Thêm mới thành công", "");
                    result.body = {
                        ...result.body,
                        quantity: this.dataListStore.map((itemStore: any) => {
                            var detailQty = result.body.inventories.filter((itemInventories: any) => itemInventories.store_id === itemStore.id)
                            return {
                                store_id: itemStore.id,
                                qty: detailQty[0] ? detailQty[0].quantity : 0,
                                name: itemStore.name,
                            };
                        }),
                    }
                    this.dataProductListVariant = [...this.dataProductListVariant, result.body]
                    this.handleModalCloseCreateVariant();
                    formCreateVariant.resetFields()
                    this.staticDataAddVariant.status = undefined
                    this.staticDataAddVariant.allow_inventory = undefined
                    this.dataProduct.attributes.map((item:any,index:number) => {
                        this.dataProduct.attributes[index].attribute_id = undefined
                    })
                    this.staticDataAddVariant.thumbnail = ''
                } else {
                    this.handleErrorsCreateVariant(result.body.message);
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {
                this.isLoadingStore = false;
            }
        }

    }
    handleErrorsCreateVariant(message: any) {
        if (message.sku === "the_sku_has_already_been_taken") {
            toastUtils.error("Mã SKU đã tồn tại", "1");
        }else if (message.attribute === "variant_is_exists") {
            toastUtils.error("Biến thể đã tồn tại", "2");
        }else{
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchDeleteVariant(id:any) {
        if(this.isSave === false){
            this.isSave = true;
            const params = {
                variant_id: id,
            };
            try {
                const result = await productService.fetchDeleteVariant(this.staticDataDetailProduct.id,params);
                this.isSave = false;
                if (result.status === HttpStatusCode.Ok) {
                    toastUtils.success("Xóa biến thể thành công", "");
                    this.dataProductListVariant = this.dataProductListVariant.filter((item:any) => item.id != id)
                } else {
                    this.handleErrorsRemoveVariant(result.body.message);
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {

            }
        }

    }
    async fetchDeleteProduct(id:any) {
        if(this.isSave === false){
            this.isSave = true;
            try {
                const result = await productService.fetchDeleteProduct(id);
                this.isSave = false;
                if (result.status === HttpStatusCode.Ok) {
                    toastUtils.success("Xóa thành công", "");
                    this.listProduct = this.listProduct.filter((item:any) => item.id != id)
                    if(this.listProduct.length === 0){
                        this.fetchListProduct();
                    }
                } else {
                    this.handleErrorsRemoveVariant(result.body.message);
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {

            }
        }
    }
    handleErrorsRemoveVariant(message: any) {
        if (message.variant === "the_last_variant_cannot_be_deleted") {
            toastUtils.error("Không được xóa biến thể cuối cùng", "1");
        }else{
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    setdataProductListVariant = (productType:number) => {
        this.dataProductListVariant = this.dataProduct.data.filter((item: any) => {
            return item.is_variant === productType;
        });
    };


    setIsModalOpen = (isOpen: boolean) => {
        this.isModalOpen = isOpen;
    };

    setIsModalOpenCreateVariant = (isOpen: boolean) => {
        this.isModalOpenCreateVariant = isOpen;
    };
    handleClose = () => {};

    handleCancel = () => {
        this.setIsModalOpen(false);
    };
    handleEditClick = (item: any) => {
        this.selectedItem = item;
        this.setIsModalOpen(true);
    };
    handleOpendModel = () => {
        this.setIsModalOpen(true);
    };
    handleModalClose = () => {
        this.setIsModalOpen(false);
        this.selectedItem = null;
    };

    handleOpendModelCreateVariant = () => {
        this.setIsModalOpenCreateVariant(true);
    };
    handleModalCloseCreateVariant = () => {
        this.setIsModalOpenCreateVariant(false);
        this.selectedItem = null;
    };
}

export const productStore = new ProductStore();
