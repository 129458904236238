import React, { ReactNode, useEffect, useState } from "react";
import "./HeaderProductInPos.scss";
import { Popover } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { productInPostStore } from "../../ProductInPostStore";
import CloseMoneyControl from "../modal/closeMoneyControl/CloseMoneyControl";
import { salePointStore } from "../../../SalePointStore";
import { handleKeyPressSpecialCharacters } from "../../../../../common/utils/Utils";
import { categoryProductStore } from "../../../../products/categoryProduct/CategoryProductStore";


interface HeaderProductInPosProps {
    search?: boolean;
    children?: ReactNode;
}

const HeaderProductInPos: React.FC<HeaderProductInPosProps> = ({
    search = true,
    children,
}) => {
    const [isCloseMoneyControlVisible, setIsCloseMoneyControlVisible] =
        useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const { postId } = useParams<{ postId: string }>();
    const updateUrlParams = () => {
        const searchParams = new URLSearchParams(location.search);
        if (productInPostStore.dataFilterProduct.search) {
            searchParams.set(
                "search",
                productInPostStore.dataFilterProduct.search
            );
        }
        searchParams.set("page", "1");
        productInPostStore.staticParam.page = 0
        navigate({ search: searchParams.toString() }, { replace: true });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        productInPostStore.dataFilterProduct.search =
            searchParams.get("search") || "";
    }, [location.search]);

    const handleResetFilter = () => {
        productInPostStore.dataFilterProduct.search = "";
        productInPostStore.dataProduct = [];
        categoryProductStore.fetchCategoryProductParent();
        navigate({ search: "" }, { replace: true });
    };

    const content = (
        <div className="header-boxPopoverProductInPos">
            <p>
                <Link
                    to={`/pos/chi-tiet-don-hang/${postId}`}
                    style={{ textDecoration: "none", color: "#061020" }}
                >
                    Đơn hàng
                </Link>
            </p>

            <p>
                <Link
                    to={`/pos/${postId}/cong-no`}
                    style={{ textDecoration: "none", color: "#061020" }}
                >
                    Công nợ
                </Link>
            </p>
            <p
                onClick={() => {
                    setIsCloseMoneyControlVisible(true);
                    salePointStore.showModalCash();
                }}
                style={{ cursor: "pointer", color: "#061020" }}
            >
                Đóng máy tính tiền
            </p>
        </div>
    );

    return (
        <div className="HeaderProductInPos">
            <div className="header-container">
                <div className="header-boxLeft">
                    <h1>POS</h1>
                    {search && (
                        <form
                            className="filter-search filter-search-hidden"
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateUrlParams();
                            }}
                        >
                            <input
                                type="text"
                                name="keyword"
                                value={
                                    productInPostStore.dataFilterProduct.search
                                }
                                onKeyPress={handleKeyPressSpecialCharacters}
                                id="keyword"
                                required
                                onChange={(e: any) => {
                                    productInPostStore.dataFilterProduct.search =
                                        e.currentTarget.value;
                                }}
                                placeholder="Tìm kiếm sản phẩm"
                            />
                            <button type="submit">
                                <img
                                    src="/assets/icon/search.svg"
                                    alt="search"
                                />
                            </button>
                        </form>
                    )}
                    {productInPostStore.dataFilterProduct.search && search && (
                        <div
                            className="header-boxLeft__reset"
                            onClick={handleResetFilter}
                        >
                            Đặt Lại
                        </div>
                    )}
                    {children}
                </div>
                <div className="header-boxRight">
                    <Popover
                        placement="bottomRight"
                        title={""}
                        arrow={false}
                        content={content}
                        trigger="click"
                    >
                        <button className="header-bars">
                            <i className="fas fa-bars"></i>
                        </button>
                    </Popover>
                </div>
            </div>
            {isCloseMoneyControlVisible && (
                <CloseMoneyControl posId={postId ?? ""} />
            )}
        </div>
    );
};

export default observer(HeaderProductInPos);
