import React, { useEffect, useRef } from "react";
import "./styles/UploadImage.scss";
import { Breadcrumb, Button, Form, Input, Modal } from "antd";
import { uploadImageStore } from "./UploadImageStore";
import { observer } from "mobx-react";
import { filesStore } from "../../../pages/settings/files/FilesStore";
import { toJS } from "mobx";
import Loading from "../loading/Loading";
import { handleMediaFileMultiple } from "../../../common/helpers/UploadFileHelper";
import { toastUtils } from "../../../common/utils/Toastutils";
import ReactPaginate from "react-paginate";
const UploadImage = ({
  multipleChoice,
  onChooseImg,
  onChooseMutipleImage,
  code,
}: {
  multipleChoice?: boolean;
  onChooseImg?: (url: string) => void;
  onChooseMutipleImage?: (arrUrl: string[]) => void;
  code?: number | null
}) => {
  const [form] = Form.useForm();

  const generateBreadcrumb = () => {
    const paths = filesStore.metadata.rootPath.split("/").filter(Boolean);
    const arrPath = paths.map((path, index) => {
      const fullPath = paths.slice(0, index + 1).join("/");
      return {
        title: (
          <p
            className={`${
              uploadImageStore.highLightPath === path ? "active" : ""
            } `}
            onClick={() => {
              uploadImageStore.highLightPath = path;
              filesStore.staticDataFiles.path = fullPath;
              filesStore.fetchMediaList();
            }}
          >
            {path}
          </p>
        ),
      };
    });
    const homePath = {
      title: (
        <i
          className="fa fa-th-large"
          aria-hidden="true"
          style={{
            color: "#0E72ED",
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
          }}
        ></i>
      ),
    };
    arrPath.shift(); // xóa cái root ban đầu : 3
    arrPath.unshift(homePath);
    return arrPath;
  };

  useEffect(() => {
    form.setFieldsValue({
      name: uploadImageStore.nameFolder,
    });
  }, [form]);

  useEffect(() => {
    uploadImageStore.arrImgTemp = [...uploadImageStore.arrMutipleImg];
  }, [filesStore.listMedia]);

  const handleCheckboxChange = (url: string) => {
    const isChecked = uploadImageStore.arrImgTemp.includes(url);

    if (isChecked) {
      // Nếu đã tồn tại thì xóa khỏi arrImgTemp
      uploadImageStore.arrImgTemp = uploadImageStore.arrImgTemp.filter(
        (item) => item !== url
      );
    } else {
      // Nếu chưa tồn tại thì thêm vào arrImgTemp
      uploadImageStore.arrImgTemp.push(url);
    }
  };

  const handleChangePage = (e: any) => {
    filesStore.staticDataFiles.page = e.selected;
    filesStore.fetchMediaList();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Modal
        title={`Quản lý tệp tin`}
        // open={uploadImageStore.isOpenModal}
        open={Number(uploadImageStore.codeClick) === Number(code)}
        onCancel={() => {
          uploadImageStore.codeClick = null
          uploadImageStore.arrImgTemp = [];
        }}
        footer={null}
      >
        <div
          className={`uploadImage-preview ${
            filesStore.isAllowLoad ? "disable" : ""
          }`}
        >
          <div className="uploadImage-header">
            <div className="action">
              <div className="action-left">
                <button className="upload">
                  <i className="fa fa-upload" style={{ color: "#fff" }}></i>
                  <span>Tải lên</span>
                  <input
                    accept="image/jpeg, image/jpg, image/png, image/svg+xml, image/webp"
                    type="file"
                    multiple
                    onChange={(e) => {
                      filesStore.isLoading = true;
                      handleMediaFileMultiple(
                        e,
                        filesStore.changeFile,
                        filesStore.staticDataFiles.path
                      );
                    }}
                  />
                </button>
                <button
                  className="create-folders"
                  onClick={() => {
                    uploadImageStore.isOpenModalCreateFolder = true;
                  }}
                >
                  <i className="fa fa-folder" style={{ color: "#fff" }}></i>
                  <span>Tạo thư mục</span>
                </button>
                {multipleChoice && (
                  <button
                    className="choose-mutiple"
                    onClick={() => {
                      uploadImageStore.arrMutipleImg = [
                        ...uploadImageStore.arrImgTemp,
                      ];

                      if (uploadImageStore.arrMutipleImg.length > 0) {
                        uploadImageStore.codeClick = null;
                        onChooseMutipleImage && onChooseMutipleImage(uploadImageStore.arrMutipleImg);
                      } else {
                        // Keep modal open and show warning toast if no images are selected
                        uploadImageStore.codeClick = Number(code); // giữ modal
                        toastUtils.warning("Vui lòng chọn ảnh !");
                      }
                    }}
                  >
                    <i className="fas fa-check" style={{ color: "#fff" }}></i>
                    <span>Chọn</span>
                  </button>
                )}
              </div>
              <h1 className="action-right"></h1>
            </div>
            <div className="uploadImage-path">
              <Breadcrumb items={generateBreadcrumb() as any} />
            </div>
          </div>
          <div className="uploadImage-body">
            {filesStore.isLoading ? (
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{ height: "450px" }}
              >
                <Loading />
              </div>
            ) : (
              <>
                <div className="listFile">
                  {filesStore.listMedia.length > 0 &&
                    filesStore.listMedia.map((item, index) => (
                      <div
                        className={`file-item
                        `}
                        key={index}
                        onClick={() => {
                          if (item.isDir) {
                            // Xử lý cho thư mục
                            filesStore.staticDataFiles.path = `${
                              filesStore.metadata.rootPath
                            }/${item.name.split("/").pop()}`;
                            filesStore.fetchMediaList();
                          } else {
                            // Xử lý cho file
                            if (!multipleChoice && onChooseImg) {
                              // Trường hợp chọn một ảnh
                              onChooseImg(item?.url);
                              uploadImageStore.codeClick = null
                            }else{
                              handleCheckboxChange(item.url);
                            }
                          }
                        }}
                      >
                        <div className="file-boximg">
                          {item.isDir ? (
                            <i
                              className="fa fa-folder"
                              style={{ fontSize: "100px", color: "#ccc" }}
                            ></i>
                          ) : (
                            <img src={item?.url} alt="img" />
                          )}
                        </div>
                        <div className="file-body">
                          <span
                            className="file-name"
                            title={item.name.split("/").pop()}
                          >
                            {item.name.split("/").pop()}
                          </span>
                          <span className="file-size">{item?.size}</span>
                          <p className="file-date">{item?.time}</p>
                        </div>
                        {!item.isDir && multipleChoice && (
                          <div className="uploadImage-check">
                            <input
                              type="checkbox"
                              checked={uploadImageStore.arrImgTemp.includes(
                                item?.url
                              )}
                              className="input-check"
                              //  onChange={() => uploadImageStore.toggleUrlSelection(item.url)}
                              onChange={() => handleCheckboxChange(item.url)}
                              onClick={(e: any)=>{e.stopPropagation()}}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                {filesStore.metadata.total_page > 1 && (
          <ReactPaginate
            previousLabel={
              <img src="/assets/icon/fi-rr-angle-small-left.svg" alt="right" />
            }
            nextLabel={
              <img src="/assets/icon/fi-rr-angle-small-right.svg" alt="right" />
            }
            breakLabel={"..."}
            pageCount={filesStore.metadata.total_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            containerClassName={"pagination"}
            activeClassName={"active"}
            onPageChange={handleChangePage}
            forcePage={filesStore.staticDataFiles.page}
          />
        )}
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        title="Tạo thư mục"
        open={uploadImageStore.isOpenModalCreateFolder}
        onCancel={() => {
          uploadImageStore.isOpenModalCreateFolder = false;
        }}
        footer={[
          <Button
            key="close"
            onClick={() => {
              uploadImageStore.isOpenModalCreateFolder =
                uploadImageStore.isLoadingBtn;
            }}
          >
            Đóng
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={uploadImageStore.isLoadingBtn}
            onClick={() => {
              form
                .validateFields()
                .then(() => {
                  filesStore.staticDataFiles.newFolderName =
                    uploadImageStore.nameFolder;
                  uploadImageStore.addFolder().then(() => {
                    uploadImageStore.isOpenModalCreateFolder = false;
                    uploadImageStore.nameFolder = "";
                    form.resetFields();
                  });
                })
                .catch(() => {
                  // Validation failed, handle if needed
                });
            }}
          >
            {uploadImageStore.isLoadingBtn ? "Vui lòng chờ..." : "Tạo"}
          </Button>,
        ]}
        wrapClassName={`modalCreateFolder ${
          uploadImageStore.isLoadingBtn ? "disable" : ""
        }`}
      >
        <Form form={form}>
          <div>
            <Form.Item
              className="item-form"
              label="Tên thư mục"
              name="name"
              rules={[
                { required: true, message: "Vui lòng nhập vào trường này" },
                { max: 255, message: "Tối đa 255 ký tự" },
              ]}
            >
              <Input
                value={uploadImageStore.nameFolder}
                onChange={(e) => {
                  uploadImageStore.nameFolder = e.target.value;
                  form.setFieldsValue({ name: e.target.value });
                }}
                placeholder="Nhập tên thư mục"
                required
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default observer(UploadImage);
