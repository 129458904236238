import { useEffect } from "react";
import { spacesFloorRoomTableStore } from "./SpacesFloorRoomTableStore";
import { Form, Input, Modal } from "antd";
import { toastUtils } from "../../../common/utils/Toastutils";
import SelectCustom from "../../../shared/components/form/SelectCustom";
import { observer } from "mobx-react";
import StorageService from "../../../common/service/StorageService";

const CreateTableModal = () => {
    const [form] = Form.useForm();
    useEffect(() => {
        spacesFloorRoomTableStore.getLocation();
    }, []);

    const locationID = spacesFloorRoomTableStore.listAllLocation.find(
        (item: any, index: number) =>
            item.id === spacesFloorRoomTableStore.storeID
    )?.name;

    const floorRooms = spacesFloorRoomTableStore.listAllRoom.find(
        (item: any, index: number) =>
            item.id === spacesFloorRoomTableStore.roomID
    );

    let nameFloorRooms: any;
    if (floorRooms) {
        nameFloorRooms = floorRooms;
    } else if (!floorRooms) {
        nameFloorRooms = spacesFloorRoomTableStore.listAllFloors.find(
            (item: any, index: number) =>
                item.id.toString() ===
                spacesFloorRoomTableStore.floorID.toString()
        );
    }

    const onFinish = (value: any) => {
        spacesFloorRoomTableStore.staticParamsFloorRoomTable.store_id =
            spacesFloorRoomTableStore.storeID;
        spacesFloorRoomTableStore.staticParamsFloorRoomTable.floor_room_id =
            parseFloat(nameFloorRooms?.id);
        spacesFloorRoomTableStore.createTable().then(() => form.resetFields());
        if (spacesFloorRoomTableStore.listTableOfFloor.length === 0) {
            spacesFloorRoomTableStore.getTableOfFloors();
        }
    };

    return (
        <>
            <Modal
                className="modal-form"
                footer={null}
                title="Thêm mới bàn ăn"
                open={spacesFloorRoomTableStore.isModalTableCreate}
                onOk={spacesFloorRoomTableStore.handleOK}
                onCancel={spacesFloorRoomTableStore.handleClose}
                afterClose={spacesFloorRoomTableStore.handleClose}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={
                        spacesFloorRoomTableStore.staticParamsFloorRoomTable
                    }
                >
                    <div className="item-form">
                        <label className="label-form">
                            Tên bàn <span>*</span>
                        </label>

                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Không được để trống tên bàn",
                                },
                                {
                                    max: 150,
                                    message:
                                        "Tên bàn quá dài, tối đa 150 kí tự",
                                },
                                {
                                    validator: (_, value) => {
                                        if (value && !value.trim()) {
                                            return Promise.reject(
                                                new Error(
                                                    "Tên bàn không được chỉ có khoảng trắng"
                                                )
                                            );
                                        }
                                        const specialCharPattern = /[#!@$%^&*]/;
                                        if (specialCharPattern.test(value)) {
                                            return Promise.reject(
                                                new Error(
                                                    "Tên bàn không được chứa ký tự đặc biệt như: #!@$%^&*"
                                                )
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                placeholder="Nhập tên tên bàn"
                                type="text"
                                value={
                                    spacesFloorRoomTableStore
                                        .staticParamsFloorRoomTable.name
                                }
                                maxLength={150}
                                onChange={(e) => {
                                    if (e.target.value.length > 149) {
                                        toastUtils.warning(
                                            "Tên tên bàn quá dài, tối đa 150 ký tự",
                                            ""
                                        );
                                        return;
                                    }
                                    spacesFloorRoomTableStore.staticParamsFloorRoomTable.name =
                                        e.target.value.trim();
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label className="label-form">
                            Số lượng người/ bàn <span>*</span>
                        </label>

                        <Form.Item
                            name="quantity_people"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Không được để trống số lượng người/ bàn",
                                },
                                {
                                    validator: (_, value) => {
                                        if (!value) {
                                            return Promise.resolve();
                                        }
                                        if (
                                            /^\d+$/.test(value) &&
                                            parseInt(value, 10) > 0
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "Tối thiểu bàn phải có 1 người trở lên"
                                            )
                                        );
                                    },
                                },
                            ]}
                        >
                            <Input
                                placeholder="Nhập tên số lượng người/ bàn"
                                type="number"
                                value={
                                    spacesFloorRoomTableStore
                                        .staticParamsFloorRoomTable
                                        .quantity_people
                                }
                                onChange={(e) => {
                                    spacesFloorRoomTableStore.staticParamsFloorRoomTable.quantity_people =
                                        e.target.value;
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label className="label-form">Thứ tự hiển thị</label>

                        <Form.Item
                            name="ordering"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Không được để trống thứ tự hiển thị",
                                },
                                {
                                    validator: (_, value) => {
                                        if (!value) {
                                            return Promise.resolve();
                                        }
                                        if (
                                            /^\d+$/.test(value) &&
                                            parseInt(value, 10) > 0
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "Chỉ được nhập số nguyên dương"
                                            )
                                        );
                                    },
                                },
                            ]}
                        >
                            <Input
                                placeholder="Nhập thứ tự"
                                type="number"
                                value={
                                    spacesFloorRoomTableStore
                                        .staticParamsFloorRoomTable.ordering
                                }
                                onChange={(e: any) =>
                                    (spacesFloorRoomTableStore.staticParamsFloorRoomTable.ordering =
                                        e.target.value)
                                }
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label className="label form">Địa điểm</label>

                        {/* <SelectCustom
                            list={spacesFloorRoomTableStore.listAllLocation}
                            placeholder={`Chọn địa điểm`}
                            onChange={(value: any) =>
                                console.log("check value", value)
                            }
                            selected={() => {
                                spacesFloorRoomTableStore.staticParamsFloorRoomTable.store_id =
                                    spacesFloorRoomTableStore.listAllFloors[0]?.location_id;
                                return spacesFloorRoomTableStore.listAllLocation.find(
                                    (item: any) =>
                                        item.id.toString() ===
                                        spacesFloorRoomTableStore
                                            .listAllFloors[0]?.id
                                )?.id;
                            }}
                            getLabel={(item: any) => item.name}
                        /> */}
                        {locationID}
                    </div>

                    <div className="item-form">
                        <label className="label-form">Thuộc tầng/phòng</label>

                        {/* <SelectCustom
                            list={spacesFloorRoomTableStore.listAllFloors}
                            placeholder="Trống"
                            labelFirst="Trống"
                            onChange={(e: any) => {
                                spacesFloorRoomTableStore.staticParamsFloorRoomTable.floor_room_id =
                                    e;
                            }}
                            selected={() => {}}
                            getLabel={(item: any) => item.name}
                        /> */}
                        {nameFloorRooms?.name}
                    </div>

                    <div className="btnCreateModal">
                        <button className="cancelModal" type="button">
                            Đóng
                        </button>
                        <button
                            className="submitForm"
                            type="submit"
                            disabled={spacesFloorRoomTableStore.isLoadingBtn}
                        >
                            {spacesFloorRoomTableStore.isLoadingBtn
                                ? "Vui lòng đợi..."
                                : "Lưu"}
                        </button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default observer(CreateTableModal);
