import {makeAutoObservable} from "mobx";
import {toastUtils} from "../../../common/utils/Toastutils";
import {productInPosCartStore} from "../productInPos/ProductInPosCartStore";
import {paymentService} from "./PaymentService";
import StorageService from "../../../common/service/StorageService";
import {HttpStatusCode} from "axios";
import {productInPostStore} from "../productInPos/ProductInPostStore";


class PaymentPosStore{
    isSave: boolean = false
    isLoading: boolean = false
    posid: string | undefined
    isModalNoteOpen: boolean = false
    isModalImageQrOpen: boolean = false
    priceTotal: number = 0 // Tổng tiền thanh toán
    priceTotalAfterPayment: number = 0 // Tổng tiền còn lại sau khi thanh toán
    pricePayment: number = 0 // Tổng tiền còn lại sau khi thanh toán
    imageQr: string = '' // Tổng tiền còn lại sau khi thanh toán
    idPayTypeActive:number = 0;
    listPayType: any = []
    notePos: string | null | undefined;
    imageBank: string | null | undefined ;
    constructor() {
        makeAutoObservable(this)
    }
    showModalNote = () => {
        this.isModalNoteOpen = true;
    };
    handleCancelNote = () => {
        this.isModalNoteOpen = false;
    };
    showModalImageQr = () => {
        this.isModalImageQrOpen = true;
    };
    handleCancelImageQr = () => {
        this.isModalImageQrOpen = false;
    };

    handleSetFileImageBank = (pathFile: string) => {
        this.imageBank = pathFile;
    };
    handleRemoveImageBank = () => {
        this.imageBank = '';
    };

    /**
     *
     * @param type // 1: là tiền mặt. 2: là chuyển khoản
     * @description Thêm kiểu thanh toán
     */
    handlePushPayType = (type:number) => { // 1: là tiền mặt. 2: là chuyển khoản
        let idPayType = Date.now();
        const data = {
            id : idPayType,
            price: this.priceTotalAfterPayment,
            type: type,
            close: 1, // 1 là xóa hết 1 lần, 2 là xóa lần lượt
        }
        this.idPayTypeActive = idPayType;
        this.listPayType.unshift(data)
        this.handlePricePayment()
    };
    /**
     *
     * @param id
     * @description Xóa kiểu thanh toán
     */
    handleRemovePayType = (id:number) => {
        this.listPayType = this.listPayType.filter((item:any) => item.id != id)
        this.handlePricePayment();
    };

    /**
     * @description Tổng tiền thanh toán
     */
    handlePricePayment = () => {
        this.pricePayment = 0
        this.listPayType.forEach((item:any) =>{
            this.pricePayment += item.price
        })
        if(this.priceTotal - this.pricePayment > 0){
            this.priceTotalAfterPayment = this.priceTotal - this.pricePayment;
        }else{
            this.priceTotalAfterPayment = 0;
        }
    }
    /**
     *
     * @param idPayType
     * @param numberPay
     * @description Thao tác bàn phím
     */
    handleNumber = (idPayType:number,numberPay:number) => {
        this.listPayType = this.listPayType.map((item:any) => {
            if(item.id === idPayType){
                if(item.close === 1){
                    item.price = numberPay;
                    item.close = 2;
                }else{
                    item.price = parseInt(String(item.price) + String(numberPay));
                }
            }
            return item;
        })
        this.handlePricePayment();
    }
    handleRemove = (idPayType:number) => {
        this.listPayType = this.listPayType.map((item:any) => {
            if(item.id === idPayType){
                item.price = 0;
                item.close = 2;
            }
            return item;
        })
        this.handlePricePayment();
    }

    async generateQr(){
        if(this.isSave === false){
            this.isSave = true
            var bank_amount = 0;
            this.listPayType.filter((item:any) => item.type === 2).map((item:any) => bank_amount += item.price)
            const params = {
                description: 'Thanh toán tiền',
                money: bank_amount
            }
            const result = await paymentService.fetchGenerateQr(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                this.imageQr = result.body?.qr ? result.body.qr : ''
                this.showModalImageQr()
            } else{
                this.showModalImageQr()
            }
        }
    }

    async fetchSaveOrderPosEcommerce(navigate: (route: string) => void){
        if(this.isSave === false){
            this.isSave = true
            var bank_amount = 0;
            var cash_amount = 0;
            var customer_id =  productInPosCartStore?.dataCustomer?.id ? productInPosCartStore.dataCustomer.id : null

            this.listPayType.filter((item:any) => item.type === 1).map((item:any) => cash_amount += item.price)
            this.listPayType.filter((item:any) => item.type === 2).map((item:any) => bank_amount += item.price)

            const params = {
                note: this.notePos,
                pos_id: this.posid,
                transaction_pos_id: productInPostStore.dataPosMachine.pos_session_id,
                image_bank: this.imageBank,
                session_data: {
                    customer_id:customer_id,
                    bank_amount: bank_amount,
                    cash_amount: cash_amount,
                    wallet_amount: 0,
                },
                cart_items:productInPosCartStore.dataListProInCart.map((item:any) =>
                    {
                        return {
                            product_id: item.productId,
                            qty: item.quantity,
                            variant_id: item.variant.id,
                            discount: item.discount,
                            price: item.price
                        }
                    }
                )
            }
            const result = await paymentService.fetchSaveOrderPosEcommerce(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                StorageService.removeLocalStore(`cartPos${this.posid}`)
                StorageService.removeLocalStore(`notePos${this.posid}`)
                StorageService.removeLocalStore(`customerPos${this.posid}`)
                this.listPayType = []
                this.handleCancelImageQr()
                productInPosCartStore.dataCustomer = null
                this.notePos = ''
                productInPosCartStore.dataListProInCart = []
                productInPostStore.dataProduct = []
                navigate(`/pos/thanh-cong/${result.body.id}`);
            }
            else{
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
        }

        // this.openModal = false;
    }
}

export const paymentPosStore = new PaymentPosStore()