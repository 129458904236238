import React from "react";
import { observer } from "mobx-react";
import { Modal } from "antd";
import { UsergroupAddOutlined, UnorderedListOutlined, UserOutlined, ScissorOutlined, SwapOutlined, CompressOutlined, UndoOutlined } from "@ant-design/icons";
import { productInPostStore } from "../../../ProductInPostStore";
import "./styles/modalOption.css";
import {transferTableStore} from "../transferTable/TransferTableStore";
import {combineTableStore} from "../combineTable/CombineTableStore";
import CombineTable from "../combineTable/CombineTable";
import TransferTable from "../transferTable/TransferTable";

const ModalOption = () => {
    const handleClose = () => {
        productInPostStore.closeModal();
    };
    return (
        <Modal
            open={productInPostStore.isModalVisible}
            footer={null}
            className="custom-modal"
            closeIcon={
                <img
                    src="/assets/icon/icon_closeWhite.svg"
                    width={25}
                    height={25}
                    alt="Close"
                    onClick={handleClose}
                />
            }
            wrapClassName="ModalOption"
        >
            <div className="button-container">
                <div className="modal-button">
                    <UsergroupAddOutlined className="icon" /> Khách hàng
                </div>
                <div className="modal-button">
                    <UnorderedListOutlined className="icon" /> Ghi chú món
                </div>
                <div className="modal-button">
                    <UserOutlined className="icon" /> Khách
                </div>
                <div className="modal-button">
                    <ScissorOutlined className="icon" /> Tách đơn
                </div>
                <div className="modal-button"
                     onClick={() => transferTableStore.openModal()}
                >
                    <SwapOutlined className="icon" /> Chuyển bàn
                </div>
                {transferTableStore.isModalOpen && <TransferTable />}
                <div
                    className="modal-button"
                    onClick={() => combineTableStore.openModal()}
                >
                    <CompressOutlined className="icon" /> Gộp bàn
                </div>
                {combineTableStore.isModalOpen && <CombineTable />}
                <div className="modal-button">
                    <UndoOutlined className="icon" /> Hoàn tiền
                </div>
            </div>
        </Modal>
    );
};

export default observer(ModalOption);
