import { HttpStatusCode } from "axios";
import { separationService } from "./SeparationService";
import { toastUtils } from "../../../../common/utils/Toastutils";
import { makeAutoObservable, toJS } from "mobx";
import StorageService from "../../../../common/service/StorageService";
import {productInPosCartStore} from "../../productInPos/ProductInPosCartStore";


class SeparationStore {
    listCartItems: any[] = [];
    listSplitOrder: any[] = [];
    isModalNoteOpen: boolean = false;
    isModalImageQrOpen: boolean = false;
    priceTotalPayment: number = 0; // Tổng tiền thanh toán
    remainingPayment: number = 0; // Tổng tiền còn lại sau khi thanh toán
    priceTotal: number = 0; // Tổng tiền đã thanh toán
    imageQr: string = ''; // QR code image
    idPayTypeActive:number = 0;
    listPayType: any = []
    notePos: string | null | undefined;
    imageBank: string | null | undefined;
    isSave: boolean = false;
    posid: string | undefined
    type: string | null | undefined


    staticDataTableSession: {
        dining_tables_session: string;
        row_ids: any;
        split_order_session: string;
        pos_id: string;
        preview: string;

    } = {
        dining_tables_session: '',
        row_ids: [],
        split_order_session: '',
        pos_id: '',
        preview: "",
    };

    staticDataPayment: {
        payment_price: number;
    } = {
        payment_price: 0
    };

    constructor() {
        makeAutoObservable(this);
        this.loadPaymentPriceFromLocalStorage();
    }

    savePaymentPriceToLocalStorage = () => {
        localStorage.setItem('payment_price', JSON.stringify(this.staticDataPayment.payment_price));
        localStorage.setItem('dining_tables_session', this.staticDataTableSession.dining_tables_session);
        localStorage.setItem('split_order_session', this.staticDataTableSession.split_order_session);
        localStorage.setItem('pos_id', this.staticDataTableSession.pos_id);
        localStorage.setItem('remaining_payment', JSON.stringify(this.remainingPayment));
        // localStorage.setItem('price_total_payment', JSON.stringify(this.priceTotalPayment));
    };

    loadPaymentPriceFromLocalStorage = () => {
        const storedPaymentPrice = localStorage.getItem('payment_price');
        if (storedPaymentPrice) {
            this.staticDataPayment.payment_price = JSON.parse(storedPaymentPrice);
        }
        const storedDiningTableSession = localStorage.getItem('dining_tables_session');
        if (storedDiningTableSession) {
            this.staticDataTableSession.dining_tables_session = storedDiningTableSession;
        }

        const storedSplitOrderSession = localStorage.getItem('split_order_session');
        if (storedSplitOrderSession) {
            this.staticDataTableSession.split_order_session = storedSplitOrderSession;
        }

        const storedPosId = localStorage.getItem('pos_id');
        if (storedPosId) {
            this.staticDataTableSession.pos_id = storedPosId;
        }

        const storedRemainingPayment = localStorage.getItem('remaining_payment');
        if (storedRemainingPayment) {
            this.remainingPayment = JSON.parse(storedRemainingPayment);
        }

        // const storedPriceTotalPayment = localStorage.getItem('price_total_payment');
        // if (storedPriceTotalPayment) {
        //     this.priceTotalPayment = JSON.parse(storedPriceTotalPayment);
        // }
    };


    showModalNote = () => {
        this.isModalNoteOpen = true;
    };

    handleCancelNote = () => {
        this.isModalNoteOpen = false;
    };

    showModalImageQr = () => {
        this.isModalImageQrOpen = true;
    };

    handleCancelImageQr = () => {
        this.isModalImageQrOpen = false;
    };

    handleSetFileImageBank = (pathFile: string) => {
        this.imageBank = pathFile;
    };

    handleRemoveImageBank = () => {
        this.imageBank = '';
    };

    resetPaymentData = () => {
        this.listPayType = [];
        this.priceTotalPayment = this.staticDataPayment.payment_price;
        this.remainingPayment = this.priceTotalPayment;
        this.priceTotal = 0;
    };


    handlePushPayType = (type: number) => {
        const id = Date.now(); // `id` is now a number, compatible with `idPayTypeActive`
        const price = this.remainingPayment || 0;
        const data = {
            id: id,
            price: price,
            type: type,
            close: 1,
        }
        this.priceTotalPayment = this.staticDataPayment.payment_price
        this.listPayType.unshift(data);
        this.idPayTypeActive = id;
        this.handleMathPrice()
    };

    handleRemovePayType = (id: number) => {
        this.listPayType = this.listPayType.filter((item: any) => item.id !== id);
        this.handleMathPrice()
    };

    handleNumber = (idPayType:number, numberPay:number) => {
        this.listPayType = this.listPayType.map((item:any) => {
            if(item.id === idPayType) {
                if (item.close === 1) {
                    item.price = numberPay
                    item.close = 2
                } else
                    item.price =  parseInt( item.price + String(numberPay))
            }
            return item
        })
        this.handleMathPrice()
    }

    // Error chua xoa theo tung ki tu
    handleRemove = (idPayType: number) => {
        this.listPayType = this.listPayType.map((item: any) => {
            if(item.id === idPayType) {
                // Xoa theo tung ki tu tu duoi len tren
                if (item.price.length > 1) {
                    item.price = item.price.slice(0, -1);
                } else {
                    item.price = '0';
                }
            }
            return item;
        });
        this.handleMathPrice()
    }

    handleMathPrice = () => {
        const totalPaid = this.listPayType.reduce((total: number, item: any) => {
            return total + (item.price ? parseFloat(item.price) : 0); // Đảm bảo giá trị price là số
        }, 0);

        this.priceTotal = totalPaid;

        if (totalPaid > this.priceTotalPayment) {
            this.remainingPayment = 0;
        } else {
            this.remainingPayment = this.priceTotalPayment - totalPaid;
        }

        if (this.remainingPayment < 0) {
            this.remainingPayment = 0;
        }

    };

    async generateQr(){
        if(this.isSave === false){
            this.isSave = true
            var bank_amount = this.listPayType
                .filter((item: any) => item.type === 2)
                .reduce((total: number, item: any) => total + (item.price || 0), 0);
            const params = {
                description: 'Thanh toán tiền',
                money: bank_amount
            }
            const result = await separationService.fetchGenerateQr(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                this.imageQr = result.body?.qr ? result.body.qr : ''
                this.showModalImageQr()
            } else{
                this.showModalImageQr()
            }
        }
    }

    async fetchSaveOrderPosFnb (navigate: (route: string) => void) {

        const customer_id =  productInPosCartStore?.dataCustomer?.id ? productInPosCartStore.dataCustomer.id : null
        const bank_amount = this.listPayType
            .filter((item: any) => item.type === 2)
            .reduce((total: number, item: any) => total + (item.price || 0), 0);

        const cash_amount = this.listPayType
            .filter((item: any) => item.type === 1)
            .reduce((total: number, item: any) => total + (item.price || 0), 0);

        const params = {
            type: "split_order",
            dining_table_session: this.staticDataTableSession.dining_tables_session,
            dining_table_data: this.staticDataTableSession.split_order_session,
            pos_id: this.staticDataTableSession.pos_id,
            session_data: {
                customer_id: customer_id,
                bank_amount: bank_amount,
                cash_amount: cash_amount,
                image_bank: this.imageBank,
                note: this.notePos,
                wallet_amount: 0,
            }
        }

        const result = await separationService.fetchCheckoutPayment(params);
        this.isSave = false
        if (result.status === HttpStatusCode.Ok) {
            StorageService.removeLocalStore(`cartPos${this.posid}`)
            StorageService.removeLocalStore(`notePos${this.posid}`)
            StorageService.removeLocalStore(`customerPos${this.posid}`)
            this.listPayType = []
            this.handleCancelImageQr()
            navigate(`/pos/tach-don/thanh-cong/${result.body.id}`);
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '');

        }
    }

    async fetchCartItems() {
        const { dining_tables_session } = this.staticDataTableSession;
        const result = await separationService.fetchCartItems(dining_tables_session);

        if (result.status === HttpStatusCode.Ok) {
            this.listCartItems = result.body;
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }

    async splitOrder(data: any) {
        const { dining_tables_session, row_ids } = this.staticDataTableSession;
        const splitOrderProduct = {
            dining_tables_session,
            row_ids
        };
        const result = await separationService.splitOrder(splitOrderProduct);
        if (result.status === HttpStatusCode.Ok) {
            this.listSplitOrder = result.body;
            this.staticDataTableSession.split_order_session = result.body.split_order_session
            return result.body.split_order_session;
            toastUtils.success('Tách đơn hàng thành công!', '');
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }


}

export const separationStore = new SeparationStore();
