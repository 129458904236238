import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {Link, useLocation, useParams} from "react-router-dom";
import {Button, Form, Input, Space} from "antd";
import {productStore} from "../ProductStore";
import {toastUtils} from "../../../../common/utils/Toastutils";
import EditProductMenu from "./components/EditProductMenu";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import {marginBottom} from "html2canvas/dist/types/css/property-descriptors/margin";
import { useReactToPrint } from "react-to-print";
import "./styles/product_variant.scss"

const EditProductBarcodePage = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    productStore.staticDataDetailProduct.id = `${id}`;
    productStore.staticDataDetailProduct.request_type = "barcode";
    useEffect(() => {
        productStore.fetchGetBarcodeProduct().then();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            variant_id: productStore.variantIdBarcode,
        });
    }, [productStore.variantIdBarcode, form]);

    const componentRef = useRef<any>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


      const location = useLocation()

      useEffect(() => {
        // Đặt giá trị cho trường quantity từ productStore
        form.setFieldsValue({
            quantity: Number(productStore.quantityBarcode),
        });
    
        // Kiểm tra lỗi dựa trên giá trị mới của quantity
        if (productStore.quantityBarcode < 1 || productStore.quantityBarcode > 100) {
            productStore.errCode = true;
            form.setFields([
                {
                    name: "quantity",
                    errors: ["Số lượng phải > 0 và <= 100"],
                },
            ]);
        } else {
            productStore.errCode = false;
            form.setFields([
                {
                    name: "quantity",
                    errors: [],
                },
            ]);
        }
    }, [form, productStore.quantityBarcode, location.pathname]);
    
    return (
        <>
            <div className="container">
                <div className="mainContentCreateProduct mainContentEditProduct">
                    <div className="title-create-product">
                        <Link to="/hang-hoa/san-pham">
                            <span>
                                <img
                                    src="/assets/icon/arrow-left.svg"
                                    alt="fi-sr-caret-down"
                                />
                                Sửa sản phẩm
                            </span>
                        </Link>
                    </div>
                    <Form
                        className="form-create-product"
                        form={form}
                        name="control-hooks"
                        onFinish={(values) => {
                            productStore.quantityBarcode = Number(values.quantity)
                            productStore.fetchUpdateBarcodeProduct()
                        }}
                        style={{maxWidth: 600}}
                    >
                        <div className="session-create">
                            <div className="session-create-left session-create-left-edit">
                                <div className="session-create-left-content">
                                    <EditProductMenu
                                        idProduct={`${id}`}
                                        nameEdit="barcode"
                                    />
                                </div>
                            </div>
                            <div className="session-create-right">
                                <div className="session-create-content">
                                    <div className="grid-3-1 gap-20">
                                        <div>
                                            <div className="item-form" style={{marginBottom: '20px'}}>
                                                <Form.Item name="variant_id" label="Chọn biến thể của sản phẩm"
                                                           rules={[{required: true, message: 'Không được để trống!'}]}>
                                                    <SelectCustom
                                                        list={productStore.dataProductBarcode}
                                                        placeholder={`-- Chọn --`}
                                                        onChange={(value: any) => productStore.variantIdBarcode = value}
                                                        selected={() => {
                                                            const variantIdBarcode = productStore.variantIdBarcode;
                                                            return variantIdBarcode || undefined;
                                                        }}
                                                        getLabel={(item: any) => item.sku}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <Form.Item>
                                                <Space>
                                                    <Button type="primary" htmlType="submit">
                                                        Tạo mới
                                                    </Button>
                                                </Space>
                                            </Form.Item>
                                        </div>
                                        <div className="d-flex flex-column gap-3">
                                            <div className="item-form">
                                                <div className="image-barcode">
                                                    <img src={productStore.dataProductBarcode?.find((item: any) => item.id === productStore.variantIdBarcode)?.barcode_img ? productStore.dataProductBarcode?.find((item: any) => item.id === productStore.variantIdBarcode)?.barcode_img : '/assets/images/empty_img.png'}
                                                        alt=""/>

                                                </div>
                                                <p>{productStore.dataProductBarcode?.find((item: any) => item.id === productStore.variantIdBarcode)?.barcode_code ? 'Barcode: ' + productStore.dataProductBarcode?.find((item: any) => item.id === productStore.variantIdBarcode)?.barcode_code : ''}</p>
                                            </div>
                                        
                                         <div className="d-flex flex-column align-center justify-content-center">

                                         <div className="item-form">
                                                <Form.Item
                                                    name="quantity"
                                                    label="In số lượng"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Đây là trường bắt buộc"
                                                        },
                                                        {
                                                            validator: (_, value) => {
                                                                if (value > 0 && value <= 100 ) {
                                                                    return Promise.resolve();
                                                                }else{
                                                                    return Promise.reject(new Error("Số lượng phải > 0 và <= 100"));
                                                                }
                                                            }
                                                        },
                                                       
                                                    ]}
                                                >
                                                    <Input
                                                        type="number"
                                                        value={productStore.quantityBarcode} 
                                                        
                                                        onChange={(e: any)=> {
                                                            let value = Number(e.target.value); 
                                                            productStore.quantityBarcode = value
                                                         }}
                                                    />
                                                </Form.Item>
                                            </div>
                                                <Button className={`btn-printPro ${ productStore.errCode || !productStore.dataProductBarcode?.find((item: any) => item.id === productStore.variantIdBarcode)?.barcode_img ? "deActive" : ""}`} style={{marginTop: "10px"}}  onClick={()=> {handlePrint()}} >
                                                    <span className="fa fa-print"></span>
                                                    In
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>

            <div className="print-componentPro" ref={componentRef} >
                 <div className={`print-container nonePrint`}>
                     <div className={`barcode-container`} >
                    {productStore.quantityBarcode > 0 && productStore.quantityBarcode <=100 &&  Array(productStore.quantityBarcode).fill(null).map((item, index: number)=>(
                        <div key={index}>
                            <div className="Boximage-barcode" >
                                <img src={productStore.dataProductBarcode?.find((item: any) => item.id === productStore.variantIdBarcode)?.barcode_img ? productStore.dataProductBarcode?.find((item: any) => item.id === productStore.variantIdBarcode)?.barcode_img : '/assets/images/empty_img.png'}
                                alt=""/>
                            </div>

                            <p style={{letterSpacing: "11px"}}>{productStore.dataProductBarcode?.find((item: any) => item.id === productStore.variantIdBarcode)?.barcode_code ?  productStore.dataProductBarcode?.find((item: any) => item.id === productStore.variantIdBarcode)?.barcode_code : ''}</p>
                        </div>
                    ))}
                    </div>
                </div>
                </div>

            </div>
        </>
    )
}
export default observer(EditProductBarcodePage)