import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import Collapse from 'react-bootstrap/Collapse';
import {Button} from "react-bootstrap";
import {productStore} from "../../ProductStore";
import {brandStore} from "../../../brand/BrandStore";
import {createSearchParams, useNavigate} from "react-router-dom";
import StorageService from "../../../../../common/service/StorageService";

const FilterCategoryProduct = () => {
    const navigate = useNavigate();
    const [openFilterCategory, setOpenFilterCategoryProduct] = useState(true);
    const [selectedCategory, setSelected] = useState<string[]>(StorageService.getArrayFromLS("arrCatePro") || []);

    useEffect(()=>{
        productStore.staticDataListProduct.category_id = StorageService.getArrayFromLS("arrCatePro") || []
    },[])

    function handleSelect(value: boolean, name: string) {
        if (value) {
            setSelected([...selectedCategory, name]);
            productStore.staticDataListProduct.category_id = [...selectedCategory, name];
            StorageService.saveArayLS("arrCatePro",[...selectedCategory, name])
        } else {
            setSelected(selectedCategory.filter((item) => item !== name));
            productStore.staticDataListProduct.category_id = selectedCategory.filter((item) => item !== name);
            StorageService.saveArayLS("arrCatePro", selectedCategory.filter((item) => item !== name))
        }
        const params: any = {};
        if (productStore.staticDataListProduct.search !== "")
            params.keyword = productStore.staticDataListProduct.search;
        params.page = 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        productStore.staticDataListProduct.page_number = 0;
        productStore.fetchListProduct(true)
    }

    function selectAll(value: boolean) {
        if (value) {
            setSelected(productStore.listCategory.map((item: any) => item.id));
            const categoryIdArray:any[] = []
             productStore.listCategory.map((item: any) => {
                 categoryIdArray.push(item.id)
            });
            productStore.staticDataListProduct.category_id = categoryIdArray
            StorageService.saveArayLS("arrCatePro", categoryIdArray)
        } else {
            setSelected([]);
            productStore.staticDataListProduct.category_id = [];
            StorageService.saveArayLS("arrCatePro", [])
        }
        const params: any = {};
        if (productStore.staticDataListProduct.search !== "")
            params.keyword = productStore.staticDataListProduct.search;
        params.page = 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        productStore.staticDataListProduct.page_number = 0;
        productStore.fetchListProduct(true)
    }
    const allSelected = selectedCategory.length === productStore.listCategory.length;
    return (
        <div className="filter-category-product filter-goods-manager">
            <Button onClick={() => setOpenFilterCategoryProduct(!openFilterCategory)}
                    aria-controls="example-collapse-text" aria-expanded={openFilterCategory}>
                Danh mục
            </Button>
            <Collapse in={openFilterCategory}>
                <div className="listCategoryProduct">
                    <label className="radio-button">
                        <input type="checkbox" name="category_product" value="all"  checked={allSelected}
                               onChange={(e) => selectAll(e.target.checked)}/>
                        <span className="radio-tick"></span>
                        Tất cả
                    </label>
                    {productStore.listCategory.map((item:any,index:number) => (
                        <label className="radio-button">
                            <input type="checkbox"
                                   name="category_product[]"
                                   value={item.id}
                                   checked={selectedCategory.includes(item.id)}
                                   onChange={(e) => handleSelect(e.target.checked, item.id)}
                            />
                            <span className="radio-tick"></span>
                            <p>{item.name}</p>
                        </label>
                    ))}
                </div>
            </Collapse>
        </div>
    )
}
export default observer(FilterCategoryProduct);