import { Button } from "antd";
import HeaderProductInPos from "../productInPos/components/headerProductInPos/HeaderProductInPos";
import "./styles/SpacesFloorRoomTable.scss";
import { observer } from "mobx-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { spacesFloorRoomTableStore } from "./SpacesFloorRoomTableStore";
import CreateFloorRoomModal from "./CreateFloorRoomModal";
import CreateTableModal from "./CreateTableModal";
import Loading from "../../../shared/components/loading/Loading";
import StorageService from "../../../common/service/StorageService";
import { dataStatusTable } from "../productInPos/components/productInPosControl/ProductInPosControl";

const SpacesFloorRoomTable = () => {
    const { posId } = useParams<{ posId: string }>();
    const { floorId } = useParams<{ floorId: any }>();
    const { roomId } = useParams<{ roomId: any }>();
    const navigate = useNavigate();
    const location = useLocation();
    spacesFloorRoomTableStore.queryParams.pos_id = `${posId}`;

    useEffect(() => {
        const wasRefreshed = sessionStorage.getItem("isRefreshed");
        if (!wasRefreshed) {
            const handlePopState = () => {
                if (location.pathname.includes("/tang-phong")) {
                    navigate("/danh-sach-pos", { replace: true });
                }
            };

            window.addEventListener("popstate", handlePopState);
            return () => {
                window.removeEventListener("popstate", handlePopState);
            };
        }

        sessionStorage.removeItem("isRefreshed");
    }, [location, navigate]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.setItem("isRefreshed", "true");
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        spacesFloorRoomTableStore.loadFloorIDFromURL(() => {
            spacesFloorRoomTableStore.floorID = !floorId
                ? StorageService.getLocalStore("floorID")
                : floorId;
            spacesFloorRoomTableStore.getTableOfFloors();
        });
        spacesFloorRoomTableStore.loadRoomIDFromURL(() => {
            spacesFloorRoomTableStore.roomID = !roomId
                ? StorageService.getLocalStore("roomID")
                : roomId;
            if (roomId) {
                spacesFloorRoomTableStore.getTableOfRoom();
            }
        });
        spacesFloorRoomTableStore.loadFloorIdFromLocal();
        spacesFloorRoomTableStore.loadRoomIdFromLocal();
        spacesFloorRoomTableStore.loadPosIdFromLocal();
        spacesFloorRoomTableStore.loadStoreIDFromLocal();

        spacesFloorRoomTableStore.getListFloors();
    }, [spacesFloorRoomTableStore.floorID]);

    return (
        <>
            <div className="SpacesFloromtab-container">
                <HeaderProductInPos search={false}>
                    <Button
                        className="custom-btn btn-floorroom"
                        type="primary"
                        onClick={() =>
                            spacesFloorRoomTableStore.showModalFloorRoom()
                        }
                    >
                        Thêm mới tầng/phòng
                    </Button>
                    {spacesFloorRoomTableStore.listAllFloors.length === 0 ? (
                        ""
                    ) : (
                        <Button
                            className="custom-btn btn-table"
                            type="primary"
                            onClick={() =>
                                spacesFloorRoomTableStore.showModalTable()
                            }
                        >
                            Thêm mới bàn ăn
                        </Button>
                    )}
                </HeaderProductInPos>
                <div
                    className="SpacesFloromtab-body d-flex align-items-center flex-column"
                    style={{
                        backgroundColor:
                            spacesFloorRoomTableStore.listAllFloors.length === 0
                                ? "#F4F6F9"
                                : "white",
                        justifyContent:
                            spacesFloorRoomTableStore.listAllFloors.length === 0
                                ? "center"
                                : "initial",
                    }}
                >
                    {spacesFloorRoomTableStore.isLoadingFloor ? (
                        <div className="loading">
                            <Loading />
                        </div>
                    ) : spacesFloorRoomTableStore.listAllFloors.length === 0 ? (
                        <>
                            <p className="textNoFloRomTab">
                                Oops!
                                <br /> Không có tầng nào. Không có bàn nào. Thêm
                                một bàn mới để bắt đầu.
                            </p>
                            <img
                                className="noFloRomTab"
                                src="/assets/images/dining_null.webp"
                                alt=""
                            />
                        </>
                    ) : (
                        <>
                            <div className="listFloors-container">
                                {spacesFloorRoomTableStore.listAllFloors?.map(
                                    (item: any, index: any) => {
                                        return (
                                            <>
                                                <div
                                                    key={index}
                                                    className="itemFloors"
                                                    style={{
                                                        flex: "1",
                                                        backgroundColor:
                                                            spacesFloorRoomTableStore.floorID ===
                                                            item.id
                                                                ? "#0E72ED"
                                                                : "",
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            spacesFloorRoomTableStore.floorID ===
                                                            item.id
                                                        ) {
                                                            return; // Không thực hiện gì nếu floorID đã bằng với item.id
                                                        }
                                                        spacesFloorRoomTableStore.floorID =
                                                            item.id;
                                                        spacesFloorRoomTableStore.saveFloorID();
                                                        spacesFloorRoomTableStore.queryParams.floor_id =
                                                            item.id;
                                                        spacesFloorRoomTableStore.listTableOfFloor =
                                                            [];
                                                        spacesFloorRoomTableStore.listTableOfRoom =
                                                            [];
                                                        spacesFloorRoomTableStore.isLoading =
                                                            true;
                                                        spacesFloorRoomTableStore.isLoadingRoom =
                                                            true;
                                                        spacesFloorRoomTableStore.isLoadingTable =
                                                            true;
                                                        navigate(
                                                            `/pos/${spacesFloorRoomTableStore.posID}/tang-phong/${item.id}`
                                                        );
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color:
                                                                spacesFloorRoomTableStore.floorID ===
                                                                item.id
                                                                    ? "white"
                                                                    : "#061020",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </>
                                        );
                                    }
                                )}
                            </div>

                            <div
                                className="listRoom-container"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                }}
                            >
                                {spacesFloorRoomTableStore.isLoading
                                    ? ""
                                    : spacesFloorRoomTableStore.listTableOfFloor
                                          .length > 0
                                    ? spacesFloorRoomTableStore.listTableOfFloor
                                          .slice(
                                              0,
                                              spacesFloorRoomTableStore
                                                  .listTableOfFloor.length > 1
                                                  ? 1
                                                  : undefined
                                          ) // Chỉ hiển thị phần tử đầu tiên nếu length > 1
                                          .map((item: any, index: number) => {
                                              return (
                                                  <div
                                                      className="itemRooms"
                                                      key={index}
                                                      onClick={() => {
                                                          if (
                                                              spacesFloorRoomTableStore.roomID ===
                                                              item.id
                                                          ) {
                                                              return; // Không thực hiện gì nếu roomID đã bằng với item.id
                                                          }
                                                          spacesFloorRoomTableStore.getTableOfFloors();
                                                          spacesFloorRoomTableStore.roomID =
                                                              item.id;
                                                          spacesFloorRoomTableStore.saveRoomID();
                                                          navigate(
                                                              `/pos/${spacesFloorRoomTableStore.posID}/tang-phong/${spacesFloorRoomTableStore.floorID}/${item.id}`
                                                          );
                                                      }}
                                                  >
                                                      <span
                                                          style={{
                                                              background:
                                                                  StorageService.getLocalStore(
                                                                      "roomID"
                                                                  ) ===
                                                                  item.id.toString()
                                                                      ? "rgb(14, 114, 237)"
                                                                      : "#e6e9e8",
                                                              color:
                                                                  StorageService.getLocalStore(
                                                                      "roomID"
                                                                  ) ===
                                                                  item.id.toString()
                                                                      ? "white"
                                                                      : "#061020",
                                                          }}
                                                      >
                                                          Bàn lẻ
                                                      </span>
                                                  </div>
                                              );
                                          })
                                    : ""}
                                {spacesFloorRoomTableStore.isLoadingRoom
                                    ? "Đang tải..."
                                    : spacesFloorRoomTableStore.listAllRoom.map(
                                          (item: any, index: number) => {
                                              return (
                                                  <>
                                                      <div
                                                          className="itemRooms"
                                                          key={index}
                                                          onClick={() => {
                                                              spacesFloorRoomTableStore.roomID =
                                                                  item.id;
                                                              navigate(
                                                                  `/pos/${posId}/tang-phong/${spacesFloorRoomTableStore.floorID}/${item.id}`
                                                              );
                                                              spacesFloorRoomTableStore.saveRoomID();
                                                              spacesFloorRoomTableStore.getTableOfRoom();
                                                          }}
                                                      >
                                                          <span
                                                              style={{
                                                                  background:
                                                                      spacesFloorRoomTableStore.roomID ===
                                                                      item.id
                                                                          ? "rgb(14, 114, 237)"
                                                                          : "#e6e9e8",
                                                                  color:
                                                                      spacesFloorRoomTableStore.roomID ===
                                                                      item.id
                                                                          ? "white"
                                                                          : "#061020",
                                                              }}
                                                          >
                                                              {item.name}
                                                          </span>
                                                      </div>
                                                  </>
                                              );
                                          }
                                      )}
                            </div>

                            <div
                                className="listTable-container"
                                style={{
                                    backgroundColor: "#F4F6F9",
                                    justifyContent:
                                        spacesFloorRoomTableStore
                                            .listTableOfRoom.length === 0
                                            ? "center"
                                            : "normal",
                                    alignItems:
                                        spacesFloorRoomTableStore
                                            .listTableOfRoom.length === 0
                                            ? "center"
                                            : "normal",
                                }}
                            >
                                {spacesFloorRoomTableStore.isLoading ? (
                                    ""
                                ) : spacesFloorRoomTableStore.listTableOfRoom
                                      .length === 0 ? (
                                    <div className="noTable">
                                        <p className="textNoFloRomTab">
                                            Oops!
                                            <br />
                                            Không có bàn nào. Thêm một bàn mới
                                            để bắt đầu.
                                        </p>
                                        <img
                                            className="noFloRomTab"
                                            src="/assets/images/dining_null.webp"
                                            alt=""
                                        />
                                    </div>
                                ) : (
                                    spacesFloorRoomTableStore.listTableOfRoom.map(
                                        (item: any, index: number) => {
                                            return (
                                                <>
                                                    <div
                                                        key={index}
                                                        className="itemTable"
                                                        style={{
                                                            backgroundColor:
                                                                item.dining_tables_session ===
                                                                    null ||
                                                                item
                                                                    .dining_tables_session
                                                                    ?.dining_tables_status ===
                                                                    3
                                                                    ? "#BABABA"
                                                                    : item
                                                                          .dining_tables_session
                                                                          ?.dining_tables_status ===
                                                                      2
                                                                    ? "#F97414"
                                                                    : "#32C564",
                                                        }}
                                                        onClick={() => {
                                                            console.log(
                                                                "check onclick item.id",
                                                                item.id
                                                            );
                                                            navigate(
                                                                `/pos/${posId}/san-pham/${item.id}`
                                                            );
                                                            StorageService.setLocalStore("dining_table_session", item.dining_tables_session?.dining_tables_session)
                                                            const dataCartFNB = StorageService.getObjectStore(`cartPos${posId}/cartRoom${roomId}/cartTable${item.id}`)
                                                            // Nếu chưa có trong local thì khởi tạo mặc định
                                                            if (!dataCartFNB) {
                                                                StorageService.setObjectStore(`cartPos${posId}/cartRoom${roomId}/cartTable${item.id}`,  {
                                                                    dining_table_status: `${dataStatusTable.notBooked}`,
                                                                    dining_table_session: "",
                                                                    items: []
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        {!item
                                                            .dining_tables_session
                                                            ?.tmp_order_item
                                                            ?.length ? (
                                                            ""
                                                        ) : (
                                                            <p className="orderTable mb-0">
                                                                {
                                                                    item
                                                                        .dining_tables_session
                                                                        ?.tmp_order_item
                                                                        ?.length
                                                                }
                                                            </p>
                                                        )}
                                                        {!item.dining_tables_session ? (
                                                            ""
                                                        ) : item
                                                              .dining_tables_session
                                                              ?.start_time_working ? (
                                                            <span className="timeOpenTable d-flex align-items-center justify-content-center">
                                                                <img
                                                                    src="/assets/icon/clock_dining.svg"
                                                                    alt=""
                                                                />
                                                                {Math.floor(
                                                                    (Date.now() -
                                                                        new Date(
                                                                            item.dining_tables_session?.start_time_working
                                                                        ).getTime()) /
                                                                        (1000 *
                                                                            60)
                                                                )}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span className="title">
                                                            {item.name}
                                                        </span>
                                                        {!item.dining_tables_session ? (
                                                            ""
                                                        ) : !item
                                                              .dining_tables_session
                                                              ?.merge_dining_tables_name ? (
                                                            ""
                                                        ) : (
                                                            <span className="mergeTables d-flex align-items-center justify-content-center">
                                                                <img
                                                                    src="/assets/icon/merge_tables_left.svg"
                                                                    alt=""
                                                                />
                                                                {
                                                                    item
                                                                        .dining_tables_session
                                                                        ?.merge_dining_tables_name
                                                                }
                                                                <img
                                                                    src="/assets/icon/merge_tables_right.svg"
                                                                    alt=""
                                                                />
                                                            </span>
                                                        )}
                                                        <div className="quantity_people">
                                                            <span
                                                                style={{
                                                                    color: "white",
                                                                    fontWeight:
                                                                        "600",
                                                                    padding:
                                                                        "4px 12px",
                                                                    borderRadius:
                                                                        "4px",
                                                                    backgroundColor:
                                                                        "rgba(109, 114, 124, 0.2)",
                                                                }}
                                                            >
                                                                {item.dining_tables_session !==
                                                                null
                                                                    ? item
                                                                          .dining_tables_session
                                                                          ?.quantity_people_reality
                                                                    : "0"}
                                                                /
                                                                {
                                                                    item.quantity_people
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }
                                    )
                                )}
                            </div>
                        </>
                    )}
                </div>
                <CreateFloorRoomModal />
                <CreateTableModal />
            </div>
        </>
    );
};

export default observer(SpacesFloorRoomTable);
