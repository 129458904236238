import { makeAutoObservable, reaction, toJS } from "mobx";
import { spacesFloorRoomTableService } from "./SpacesFloorRoomTableService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import StorageService from "../../../common/service/StorageService";

class SpacesFloorRoomTableStore {
    isLoadingFloor = false;
    isLoading = false;
    isLoadingRoom = false;
    isLoadingTable = false;
    isLoadingBtn = false;
    indexFloors: number = 0;
    indexRoom: number = 0;
    floorID: string = "";
    roomID: string = "";
    parent_Id: number = 0;
    storeID: string = "";
    isModalFloorRoomCreate = false;
    isModalTableCreate = false;
    listAllFloors: any[] = [];
    listAllLocation: any[] = [];
    listLocation: any = [];
    listAllRoom: any = [];
    listRoomOfFloor: any[] = [];
    listTableOfRoom: any = [];
    listTableOfFloor: any = [];
    posID: string = "";

    queryParams: {
        floor_id: string;
        pos_id: string;
        store_id: string;
        room_id: string;
        page: string;
        per_page: string;
    } = {
        floor_id: "",
        pos_id: "",
        store_id: "",
        room_id: "",
        page: "",
        per_page: "",
    };
    staticParamsFloorRoomTable: {
        name: string;
        store_id: string;
        parent_id: number | null;
        status: number;
        ordering: number;
        quantity_people: string;
        floor_room_id: number;
    } = {
        name: "",
        store_id: "",
        parent_id: 0,
        status: 1,
        ordering: 1,
        quantity_people: "",
        floor_room_id: 0,
    };

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => [this.floorID],
            () => {
                this.saveFloorID();
            }
        );
    }

    saveFloorID() {
        if (this.floorID != null) {
            localStorage.setItem("floorID", this.floorID.toString());
        } else {
            console.warn("floorID is undefined. Cannot save to localStorage.");
        }
    }
    saveRoomID() {
        if (!this.roomID) {
            console.log("rooomID is underfined");
        } else {
            localStorage.setItem("roomID", this.roomID.toString());
        }
    }
    savePosID() {
        localStorage.setItem("posID", this.posID.toString());
    }
    saveStoreID() {
        localStorage.setItem("storeID", this.storeID.toString());
    }
    loadFloorIdFromLocal() {
        const data = StorageService.getLocalStore("floorID");
        if (data) {
            this.floorID = JSON.parse(data);
        }
    }
    loadRoomIdFromLocal() {
        const data = StorageService.getLocalStore("roomID");
        if (data) {
            this.roomID = JSON.parse(data);
        }
    }
    loadPosIdFromLocal() {
        const data = StorageService.getLocalStore("posID");
        if (data) {
            this.posID = JSON.parse(data);
        }
    }
    loadStoreIDFromLocal() {
        const data = StorageService.getLocalStore("storeID");
        if (data) {
            this.storeID = JSON.parse(data);
        }
    }
    loadFloorIDFromURL(callback?: any) {
        if (callback) {
            callback();
        }
    }
    loadRoomIDFromURL(callback?: any) {
        if (callback) {
            callback();
        }
    }
    showModalFloorRoom = () => {
        this.isModalFloorRoomCreate = true;
    };
    showModalTable = () => {
        this.isModalTableCreate = true;
    };
    handleOK = () => {
        this.isModalFloorRoomCreate = false;
        this.isModalTableCreate = false;
    };
    handleCancel = () => {
        this.isModalFloorRoomCreate = false;
        this.isModalTableCreate = false;
    };
    handleClose = () => {
        this.isModalFloorRoomCreate = false;
        this.isModalTableCreate = false;
        this.staticParamsFloorRoomTable.name = "";
        this.staticParamsFloorRoomTable.store_id = "";
        this.staticParamsFloorRoomTable.ordering = 1;
        this.staticParamsFloorRoomTable.quantity_people = "";
    };

    async getLocation(run?: boolean) {
        const result = await spacesFloorRoomTableService.fetchListLocation();
        if (result.status === HttpStatusCode.Ok) {
            if (this.listAllLocation.length !== result.body.data.length) {
                this.listAllLocation = result.body.data;
                this.listLocation = this.listAllLocation.filter(
                    (item: any) =>
                        item.id.toString() ===
                        this.listAllFloors[0]?.location_id.toString()
                );
            }
        }
    }

    async getListFloors(run?: boolean): Promise<void> {
        const { pos_id, page, per_page } = this.queryParams;
        const params = {
            pos_id: pos_id,
            page: page,
            per_page: per_page,
        };
        if (this.listAllFloors.length < 1 || run) {
            this.isLoadingFloor = true;
        }
        const result = await spacesFloorRoomTableService.fetchListFloors(
            params
        );
        this.isLoadingFloor = false;
        if (result.status === HttpStatusCode.Ok) {
            if (this.listAllFloors.length !== result.body.data.length) {
                this.listAllFloors = result.body.data;
                if (
                    localStorage.getItem("floorID") === null ||
                    localStorage.getItem("floorID") === undefined ||
                    localStorage.getItem("floorID") === ""
                ) {
                    this.floorID = result.body.data[0]?.id;
                    this.saveFloorID();
                }
            }
            await Promise.all([
                // this.getTableOfFloors(),
                this.getListRoom(),
            ]);
        }
    }

    async refreshFloorsData(run?: boolean): Promise<void> {
        const { page, per_page } = this.queryParams;
        const params = { pos_id: this.posID, page: page, per_page: per_page };
        if (this.listAllFloors.length < 1 || run) {
            this.isLoadingFloor = true;
        }
        const result = await spacesFloorRoomTableService.fetchListFloors(
            params
        );
        this.isLoadingFloor = false;
        if (result.status === HttpStatusCode.Ok) {
            if (this.listAllFloors.length !== result.body.data.length) {
                this.listAllFloors = result.body.data;
                if (!localStorage.getItem("floorID")) {
                    this.floorID = result.body.data[0]?.id;
                    this.saveFloorID();
                }
            }
        }
    }

    async getListRoom(run?: boolean) {
        const params = {
            parent_id: this.floorID,
        };
        if (this.listAllRoom.length < 1 || run) {
            this.isLoadingRoom = true;
        }
        const result = await spacesFloorRoomTableService.fetchGetListRoom(
            params
        );
        if (result.status === HttpStatusCode.Ok) {
            if (
                result.body.length !== this.listAllRoom.length ||
                JSON.stringify(result.body) !== JSON.stringify(this.listAllRoom)
            ) {
                this.listAllRoom = result.body.data.reverse();
            }
            this.listAllRoom = result.body.data;
            if (this.listTableOfFloor.length === 0) {
                if (this.listAllRoom.length > 0) {
                    this.roomID = this.listAllRoom[0].id;
                } else {
                    this.roomID = ""; // Đặt roomID là 0 nếu listAllRoom không có dữ liệu
                }
                this.saveRoomID();
            }
        }
        this.isLoadingRoom = false;
    }
    async getTableOfFloors(run?: boolean) {
        const { pos_id } = this.queryParams;
        const params = {
            pos_id: pos_id,
            floor_id: this.floorID,
        };
        if (this.listTableOfFloor.length < 1 || run) {
            this.isLoading = true;
        }
        const result = await spacesFloorRoomTableService.fetchGetTableOfFloors(
            params
        );
        if (result.status === HttpStatusCode.Ok) {
            if (
                result.body.length !== this.listTableOfRoom.length ||
                JSON.stringify(result.body) !==
                    JSON.stringify(this.listTableOfRoom)
            ) {
                if (
                    result.body[0]?.floor_room_parent_id === 0 ||
                    result[0]?.floor_room_parent_id === "0"
                ) {
                    if (this.listTableOfFloor.length < 1) {
                        this.roomID = result.body[0]?.id;
                        this.saveRoomID();
                    }
                    if (result.body.length > 0) {
                        this.roomID = result.body[result.body - 1];
                        this.saveRoomID();
                    }
                    this.listTableOfRoom = result.body;
                    this.listTableOfFloor = result.body;
                    // if (result.body.length > 0) {
                    //     this.roomID = this.listTableOfFloor[0]?.id;
                    //     this.saveRoomID();
                    // }
                    // else if (result.body.length === 0) {
                    //     console.log("check");
                    //     this.getTableOfRoom();
                    // }
                } else if (
                    result.body[0]?.floor_room_parent_id !== 0 ||
                    result[0]?.floor_room_parent_id !== "0"
                ) {
                    this.getTableOfRoom();
                }
            }
        }
        this.isLoading = false;
    }

    async getTableOfRoom() {
        const { pos_id } = this.queryParams;
        this.isLoadingTable = true;
        const params = {
            floor_id: this.floorID,
            room_id: this.roomID,
            pos_id: pos_id,
        };
        const result = await spacesFloorRoomTableService.fetchGetTableOfRooms(
            params
        );
        if (result.status === HttpStatusCode.Ok) {
            if (
                result.body.length !== this.listTableOfRoom ||
                JSON.stringify(result.body) !==
                    JSON.stringify(this.listTableOfRoom)
            ) {
                this.listTableOfRoom = result.body;
            }
        }
        this.isLoadingTable = false;
    }

    async createFloorRoom(callback?: () => void): Promise<void> {
        this.isLoadingBtn = true;
        const { name, store_id, parent_id, status, ordering } =
            this.staticParamsFloorRoomTable;
        const params = {
            name: name,
            store_id: store_id,
            parent_id: parent_id,
            status: status,
            ordering: ordering,
        };

        const result = await spacesFloorRoomTableService.fetchCreateFloorRoom(
            params
        );
        this.isLoadingBtn = false;

        if (result.status === HttpStatusCode.Ok) {
            if (
                result.body.data.parent_id === 0 ||
                result.body.data.parent_id === null
            ) {
                if (this.listAllFloors.length < 1) {
                    this.floorID = result.body.data.id;
                    this.saveFloorID();
                }
                this.listAllFloors.push(result.body.data);
            }
            if (callback) {
                callback();
            }
            toastUtils.success("Thêm thành công", "");
            this.handleClose();
            this.refreshFloorsData();
            this.getListRoom();
        }
    }

    async createTable(run?: boolean): Promise<void> {
        const { name, store_id, quantity_people, floor_room_id, ordering } =
            this.staticParamsFloorRoomTable;
        const params = {
            name: name,
            store_id: store_id,
            quantity_people: quantity_people,
            floor_room_id: floor_room_id,
            ordering: ordering,
        };

        this.isLoadingBtn = true;

        const result = await spacesFloorRoomTableService.fetchCreateTable(
            params
        );

        this.isLoadingBtn = false;

        if (result.status === HttpStatusCode.Ok) {
            if (result.body.data.floor_room_parent_id === 0) {
                this.listTableOfFloor.unshift(result.body.data);
            }
            toastUtils.success("Thêm thành công", "");
            this.getTableOfRoom();
            this.handleClose();
        }
    }
}

export const spacesFloorRoomTableStore = new SpacesFloorRoomTableStore();
