import {deleteRequest, getRequest, postRequest, putRequest} from '../../../common/helpers/RequestHelper';
class CategoryProductService {
    apiSubdirectory = 'api/v1/product_categories' ;
    public fetchCateogoriesProduct(paramCategoryProduct:any): Promise<any> {
        const queryParameters = [
            paramCategoryProduct.per_page ? `per_page=${paramCategoryProduct.per_page}` : '',
            paramCategoryProduct.page ? `page=${paramCategoryProduct.page}` : '',
            paramCategoryProduct.ids ? `ids=${paramCategoryProduct.ids}` : '',
            paramCategoryProduct.offset ? `offset=${paramCategoryProduct.offset}` : '',
            paramCategoryProduct.parent_id ? `parent_id=${paramCategoryProduct.parent_id}` : '',
            paramCategoryProduct.search ? `search=${paramCategoryProduct.search}` : '',
            paramCategoryProduct.sort ? `sort=${paramCategoryProduct.sort}` : '',
            paramCategoryProduct.status ? `status=${paramCategoryProduct.status}` : ''
        ].filter(Boolean).join('&');
        return getRequest(`${this.apiSubdirectory}?${queryParameters}`);
    }
    public fetchCateogoriesParent(): Promise<any> {
        return getRequest(`${this.apiSubdirectory}/product_categories_parent`);
    }
    public fetchCateogoriesProductAll(): Promise<any> {
        return getRequest(`${this.apiSubdirectory}`);
    }
    public fetchCreateNewCategory(paramsCreate:any): Promise<any> {
        return postRequest(`${this.apiSubdirectory}`,paramsCreate);
    }
    public fetchGetCategoryProduct(params:any): Promise<any> {
        return getRequest(`${this.apiSubdirectory}/${params.id}?${params.category_id ? `category_id=${params.category_id}` : ''}`);
    }
    public fetchUpadateCategoryProduct(params:any,id:number|null): Promise<any> {
        return putRequest(`${this.apiSubdirectory}/${id}`,params);
    }
    public fetchDeleteCategoryProduct(params:any): Promise<any> {
        return deleteRequest(`${this.apiSubdirectory}/${params.id}`);
    }
}
export const categoryProductService = new CategoryProductService();